import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  connectEmailAccountRequest,
  verifyEmailAccountRequest,
  verifyWhitelabelEmailAccountRequest,
  setDefaultEmailAccountRequest,
  disconnectEmailAccountRequest,
  getEmailAccountsRequest,
  getEmailAccountsListRequest,
  deleteEmailAccountRequest,
  updateEmailAccountRequest,
  getEmailAccountRequest,
  getEmailAccountSettingsRequest,
  updateEmailAccountRampUpSettingsRequest,
  getEmailAccountSetupScoreRequest,
  testSMTPConnectionRequest,
  testIMAPConnectionRequest,
  connectSmtpImapAccountRequest,
  getSmtpImapAccountDetailsRequest,
  updateSmtpImapAccountDetailsRequest,
  emailTimeIntervalSettingsRequest,
  updateEmailAgeRequest,
  importEmailAccountCsvRequest,
  bulkEmailUpdateRequest,
  emailAccountTagsAssignRequest,
  emailAccountTagsUnassignRequest,
  emailAccountTagsBulkAssignRequest,
  emailAccountTagsBulkUnassignRequest,
  getEmailAccountTagsRequest,
  trulyinboxEmailAccountVerifyShApiKeyRequest,
  getTrulyInboxTokenRequest,
  updateEmailAccountStatusRequest,
} from './extra-actions';
import { EmailAccount } from '../../types/email-account';
import { EmailAccountSettings } from '../../types/email-account-settings';
import {
  ConnectSmtpImapAccountResponse,
  GetSMTPIMAPEmailDetailsResponse,
  TestConnectionResponse,
} from '../../types/smtp-imap';
import { EmailAccountSetup } from '../../../../shared/components/email-account-setup-score/types';
import { BulkSelectedEmailAccounts, EmailAccountsFilters } from './types';
import { getInitialFiltersForEmailAccounts } from './utils/helpers';
import { EmailAccountTag } from '../../types/request-payload';

export const emailAccountsFiltersInitialState: EmailAccountsFilters = {
  tagIds: [],
  owners: [],
  sequenceIds: [],
  emailServiceProvider: [],
  status: [],
  clientIds: [],
  ...getInitialFiltersForEmailAccounts(),
};

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  connectEmailAccountRequest: RequestState;
  verifyEmailAccountRequest: RequestState;
  verifyWhitelabelEmailAccountRequest: RequestState;
  setDefaultEmailAccountRequest: RequestState;
  disconnectEmailAccountRequest: RequestState;
  updateEmailAccountStatusRequest: RequestState;
  getEmailAccountsRequest: RequestState;
  getEmailAccountsListRequest: RequestState;
  deleteEmailAccountRequest: RequestState;
  updateEmailAccountRequest: RequestState;
  getEmailAccountRequest: RequestState;
  getEmailAccountSettingsRequest: RequestState;
  updateEmailAccountRampUpSettingsRequest: RequestState;
  deleteMultipleEmailAccountRequest: RequestState;
  getEmailAccountSetupScoreRequest: RequestState;
  testSMTPConnectionRequest: RequestState;
  testIMAPConnectionRequest: RequestState;
  connectSmtpImapAccountRequest: RequestState;
  getSmtpImapAccountDetailsRequest: RequestState;
  updateSmtpImapAccountDetailsRequest: RequestState;
  emailTimeIntervalSettingsRequest: RequestState;
  updateEmailAgeRequest: RequestState;
  importEmailAccountCsvRequest: RequestState;
  bulkEmailUpdateRequest: RequestState;
  trulyinboxEmailAccountVerifyShApiKeyRequest: RequestState;
  getTrulyInboxTokenRequest: RequestState;
  authUrl: string;
  emailAccounts: EmailAccount[];
  isTiIntegrated: boolean;
  emailAccountsPagination: {
    totalItems: number;
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
    itemCount: number;
  };
  emailsCount: {
    connectedEmailsCount: number;
    disconnectedEmailsCount: number;
    pausedEmailsCount: number;
  };
  emailAccount: EmailAccount;
  emailAccountsList: EmailAccount[];
  emailAccountSettings: EmailAccountSettings[];
  isLoading: boolean;
  emailAccountSetup: EmailAccountSetup;
  testSMTPConnectionResponse: TestConnectionResponse;
  testIMAPConnectionResponse: TestConnectionResponse;
  connectSmtpImapAccountResponse: ConnectSmtpImapAccountResponse;
  sendGetSmtpImapAccountDetailsResponse: GetSMTPIMAPEmailDetailsResponse;
  sendUpdateSmtpImapAccountDetailsResponse: any;
  sendTrulyinboxEmailAccountVerifyShApiKeyResponse: any;
  bulkSelectedEmailAccounts: BulkSelectedEmailAccounts;
  selectedEmailAccounts: EmailAccount[];
  whitelabelEmailAccountResponse: {
    emailAccount: string;
    domain: string;
  };
  emailAccountsFilters: EmailAccountsFilters;
  emailAccountTagsAssignRequest: RequestState;
  emailAccountTagsUnassignRequest: RequestState;
  emailAccountTagsBulkAssignRequest: RequestState;
  emailAccountTagsBulkUnassignRequest: RequestState;
  emailAccountTagsList: EmailAccountTag[];
  getEmailAccountTagsRequest: RequestState;
  trulyInboxToken: string;
}

const initialState: State = {
  connectEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyWhitelabelEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  setDefaultEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  disconnectEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateEmailAccountStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateEmailAccountRampUpSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteMultipleEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountSetupScoreRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  testSMTPConnectionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  testIMAPConnectionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  connectSmtpImapAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSmtpImapAccountDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSmtpImapAccountDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailTimeIntervalSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateEmailAgeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  importEmailAccountCsvRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  bulkEmailUpdateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountTagsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsAssignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsUnassignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsBulkAssignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsBulkUnassignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  trulyinboxEmailAccountVerifyShApiKeyRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTrulyInboxTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  authUrl: '',
  emailAccounts: [],
  isTiIntegrated: false,
  emailAccountsPagination: null,
  emailsCount: {
    connectedEmailsCount: 0,
    disconnectedEmailsCount: 0,
    pausedEmailsCount: 0,
  },
  emailAccount: null,
  emailAccountsList: [],
  emailAccountSettings: [],
  isLoading: false,
  emailAccountSetup: null,
  testSMTPConnectionResponse: null,
  testIMAPConnectionResponse: null,
  connectSmtpImapAccountResponse: null,
  sendGetSmtpImapAccountDetailsResponse: null,
  sendUpdateSmtpImapAccountDetailsResponse: null,
  sendTrulyinboxEmailAccountVerifyShApiKeyResponse: null,
  bulkSelectedEmailAccounts: null,
  selectedEmailAccounts: [],
  whitelabelEmailAccountResponse: null,
  emailAccountsFilters: emailAccountsFiltersInitialState,
  emailAccountTagsList: [],
  trulyInboxToken: '',
};

const emailAccountSlice = createSlice({
  name: 'emailAccount',
  initialState,
  reducers: {
    changeEmailAccountStatus: (state, action) => {
      state.emailAccount.status = action.payload;
    },
    resetEmailAccountSetupState: (state) => {
      state.getEmailAccountSetupScoreRequest =
        initialState.getEmailAccountSetupScoreRequest;
      state.emailAccountSetup = initialState.emailAccountSetup;
    },
    resetEmailAccountRampUpSettingsRequestState: (state) => {
      state.updateEmailAccountRampUpSettingsRequest =
        initialState.updateEmailAccountRampUpSettingsRequest;
    },
    resetEmailAccountAndSettings: (state) => {
      state.emailAccount = initialState.emailAccount;
      state.emailAccountSettings = initialState.emailAccountSettings;
      state.emailTimeIntervalSettingsRequest =
        initialState.emailTimeIntervalSettingsRequest;
    },
    resetUpdateSmtpImapAccountDetails: (state) => {
      state.getSmtpImapAccountDetailsRequest =
        initialState.getSmtpImapAccountDetailsRequest;
      state.updateSmtpImapAccountDetailsRequest =
        initialState.updateSmtpImapAccountDetailsRequest;
      state.sendGetSmtpImapAccountDetailsResponse =
        initialState.sendGetSmtpImapAccountDetailsResponse;
      state.sendUpdateSmtpImapAccountDetailsResponse =
        initialState.sendUpdateSmtpImapAccountDetailsResponse;
    },
    resetSmtpImapTestConnection: (state) => {
      state.testSMTPConnectionRequest = initialState.testSMTPConnectionRequest;
      state.testIMAPConnectionRequest = initialState.testIMAPConnectionRequest;
      state.testSMTPConnectionResponse =
        initialState.testSMTPConnectionResponse;
      state.testIMAPConnectionResponse =
        initialState.testIMAPConnectionResponse;
    },
    resetSmtpIMapConnectResponse: (state) => {
      state.connectSmtpImapAccountRequest =
        initialState.connectSmtpImapAccountRequest;
      state.connectSmtpImapAccountResponse =
        initialState.connectSmtpImapAccountResponse;
    },
    resetUpdateEmailAgeRequest: (state) => {
      state.updateEmailAgeRequest = initialState.updateEmailAgeRequest;
    },

    // Email Accounts Bulk Action Handlers
    onBulkSelectEmailAccounts: (state, action) => {
      state.bulkSelectedEmailAccounts = action.payload;
    },
    clearEmailAccountsSelection: (state) => {
      state.bulkSelectedEmailAccounts = initialState.bulkSelectedEmailAccounts;
      state.selectedEmailAccounts = initialState.selectedEmailAccounts;
    },
    selectAllEmailAccounts: (state, action) => {
      if (action.payload.status === true) {
        if (state.bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
          action.payload.rows.forEach(({ id: contactId }) => {
            state.bulkSelectedEmailAccounts.deSelectedEmailAccountIds = state.bulkSelectedEmailAccounts.deSelectedEmailAccountIds.filter(
              (id) => contactId !== id,
            );
          });
        }
        state.selectedEmailAccounts = state.selectedEmailAccounts.concat(
          action.payload.rows,
        );
      } else {
        if (state.bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
          action.payload.rows.forEach(({ id }) => {
            state.bulkSelectedEmailAccounts.deSelectedEmailAccountIds = state.bulkSelectedEmailAccounts?.deSelectedEmailAccountIds.concat(
              [id],
            );
          });
        }
        state.selectedEmailAccounts = state.selectedEmailAccounts.reduce(
          (acc, row) => {
            let isPresent = false;
            action.payload.rows.forEach(({ id }) => {
              if (id === row.id) {
                isPresent = true;
              }
            });
            if (isPresent) {
              return acc;
            }
            return [...acc, row];
          },
          [],
        );
      }
    },
    selectSingleEmailAccount: (state, action) => {
      if (action.payload.status === true) {
        if (state.bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
          state.bulkSelectedEmailAccounts.deSelectedEmailAccountIds = state.bulkSelectedEmailAccounts.deSelectedEmailAccountIds.filter(
            (id) => id !== action.payload.row.id,
          );
        }
        state.selectedEmailAccounts = state.selectedEmailAccounts.concat([
          action.payload.row,
        ]);
      } else {
        if (state.bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
          state.bulkSelectedEmailAccounts.deSelectedEmailAccountIds = state.bulkSelectedEmailAccounts?.deSelectedEmailAccountIds.concat(
            [action.payload.row.id],
          );
        }
        state.selectedEmailAccounts = state.selectedEmailAccounts.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    resetSelectedEmailAccount: (state) => {
      state.selectedEmailAccounts = initialState.selectedEmailAccounts;
    },
    updateEmailAccountsFilters: (state, action) => {
      state.emailAccountsFilters = {
        ...state.emailAccountsFilters,
        ...action.payload,
      };
    },
    resetEmailAccountTagsRequest: (state) => {
      state.emailAccountTagsAssignRequest =
        initialState.emailAccountTagsAssignRequest;
      state.emailAccountTagsUnassignRequest =
        initialState.emailAccountTagsUnassignRequest;
      state.emailAccountTagsBulkAssignRequest =
        initialState.emailAccountTagsBulkAssignRequest;
      state.emailAccountTagsBulkUnassignRequest =
        initialState.emailAccountTagsBulkUnassignRequest;
    },
    resetEmailAccountTagsList: (state) => {
      state.emailAccountTagsList = [];
    },
  },
  extraReducers: (builder) => {
    // Get Authorization urlverifyWhitelabelEmailAccount
    builder.addCase(connectEmailAccountRequest.pending, (state) => {
      state.connectEmailAccountRequest.status = RequestStatus.Pending;
      state.connectEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(connectEmailAccountRequest.fulfilled, (state, action) => {
      state.connectEmailAccountRequest.status = RequestStatus.Succeeded;
      state.connectEmailAccountRequest.message = action.payload.message;
      state.authUrl = action.payload.payload.authUrl;
      state.isLoading = false;
    });
    builder.addCase(connectEmailAccountRequest.rejected, (state, action) => {
      state.connectEmailAccountRequest.status = RequestStatus.Failed;
      state.connectEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Add and reconnect email account
    builder.addCase(verifyEmailAccountRequest.pending, (state) => {
      state.verifyEmailAccountRequest.status = RequestStatus.Pending;
      state.verifyEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(verifyEmailAccountRequest.fulfilled, (state, action) => {
      state.verifyEmailAccountRequest.status = RequestStatus.Succeeded;
      state.verifyEmailAccountRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(verifyEmailAccountRequest.rejected, (state, action) => {
      state.verifyEmailAccountRequest.status = RequestStatus.Failed;
      state.verifyEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Add and reconnect email account
    builder.addCase(verifyWhitelabelEmailAccountRequest.pending, (state) => {
      state.verifyWhitelabelEmailAccountRequest.status = RequestStatus.Pending;
      state.verifyWhitelabelEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      verifyWhitelabelEmailAccountRequest.fulfilled,
      (state, action) => {
        state.verifyWhitelabelEmailAccountRequest.status =
          RequestStatus.Succeeded;
        state.whitelabelEmailAccountResponse = action.payload.payload;
        state.verifyWhitelabelEmailAccountRequest.message =
          action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      verifyWhitelabelEmailAccountRequest.rejected,
      (state, action) => {
        state.verifyWhitelabelEmailAccountRequest.status = RequestStatus.Failed;
        state.verifyWhitelabelEmailAccountRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Make a default email account
    builder.addCase(setDefaultEmailAccountRequest.pending, (state) => {
      state.setDefaultEmailAccountRequest.status = RequestStatus.Pending;
      state.setDefaultEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      setDefaultEmailAccountRequest.fulfilled,
      (state, action) => {
        state.setDefaultEmailAccountRequest.status = RequestStatus.Succeeded;
        state.setDefaultEmailAccountRequest.message = action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(setDefaultEmailAccountRequest.rejected, (state, action) => {
      state.setDefaultEmailAccountRequest.status = RequestStatus.Failed;
      state.setDefaultEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Revoke email account
    builder.addCase(disconnectEmailAccountRequest.pending, (state) => {
      state.disconnectEmailAccountRequest.status = RequestStatus.Pending;
      state.disconnectEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      disconnectEmailAccountRequest.fulfilled,
      (state, action) => {
        state.disconnectEmailAccountRequest.status = RequestStatus.Succeeded;
        state.disconnectEmailAccountRequest.message = action.payload.message;
        state.isLoading = false;
        if (state.emailAccount) {
          state.emailAccount = {
            ...state.emailAccount,
            status: action.payload.payload.status,
          };
        }
      },
    );
    builder.addCase(disconnectEmailAccountRequest.rejected, (state, action) => {
      state.disconnectEmailAccountRequest.status = RequestStatus.Failed;
      state.disconnectEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Pause email account
    builder.addCase(updateEmailAccountStatusRequest.pending, (state) => {
      state.updateEmailAccountStatusRequest.status = RequestStatus.Pending;
      state.updateEmailAccountStatusRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      updateEmailAccountStatusRequest.fulfilled,
      (state, action) => {
        state.updateEmailAccountStatusRequest.status = RequestStatus.Succeeded;
        state.updateEmailAccountStatusRequest.message = action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      updateEmailAccountStatusRequest.rejected,
      (state, action) => {
        state.updateEmailAccountStatusRequest.status = RequestStatus.Failed;
        state.updateEmailAccountStatusRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Get Email Accounts
    builder.addCase(getEmailAccountsRequest.pending, (state) => {
      state.getEmailAccountsRequest.status = RequestStatus.Pending;
      state.getEmailAccountsRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getEmailAccountsRequest.fulfilled, (state, action) => {
      state.getEmailAccountsRequest.status = RequestStatus.Succeeded;
      state.getEmailAccountsRequest.message = action.payload.message;
      state.emailAccounts = action.payload.payload.emails;
      state.isTiIntegrated = action.payload.payload.isTiIntegrated;
      state.emailAccountsPagination = action.payload.payload.meta;
      state.emailsCount = {
        connectedEmailsCount: action.payload.payload.connectedEmailsCount,
        disconnectedEmailsCount: action.payload.payload.disconnectedEmailsCount,
        pausedEmailsCount: action.payload.payload.pausedEmailsCount,
      };
      state.isLoading = false;
    });
    builder.addCase(getEmailAccountsRequest.rejected, (state, action) => {
      state.getEmailAccountsRequest.status = RequestStatus.Failed;
      state.getEmailAccountsRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get Email Accounts List
    builder.addCase(getEmailAccountsListRequest.pending, (state) => {
      state.getEmailAccountsListRequest.status = RequestStatus.Pending;
      state.getEmailAccountsListRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getEmailAccountsListRequest.fulfilled, (state, action) => {
      state.getEmailAccountsListRequest.status = RequestStatus.Succeeded;
      state.getEmailAccountsListRequest.message = action.payload.message;
      state.emailAccountsList = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getEmailAccountsListRequest.rejected, (state, action) => {
      state.getEmailAccountsListRequest.status = RequestStatus.Failed;
      state.getEmailAccountsListRequest.error = action.payload;
      state.isLoading = false;
    });

    // Delete email account
    builder.addCase(deleteEmailAccountRequest.pending, (state) => {
      state.deleteEmailAccountRequest.status = RequestStatus.Pending;
      state.deleteEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(deleteEmailAccountRequest.fulfilled, (state, action) => {
      state.deleteEmailAccountRequest.status = RequestStatus.Succeeded;
      state.deleteEmailAccountRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(deleteEmailAccountRequest.rejected, (state, action) => {
      state.deleteEmailAccountRequest.status = RequestStatus.Failed;
      state.deleteEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update email account
    builder.addCase(updateEmailAccountRequest.pending, (state) => {
      state.updateEmailAccountRequest.status = RequestStatus.Pending;
      state.updateEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(updateEmailAccountRequest.fulfilled, (state, action) => {
      state.updateEmailAccountRequest.status = RequestStatus.Succeeded;
      state.updateEmailAccountRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(updateEmailAccountRequest.rejected, (state, action) => {
      state.updateEmailAccountRequest.status = RequestStatus.Failed;
      state.updateEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update email account
    builder.addCase(
      updateEmailAccountRampUpSettingsRequest.pending,
      (state) => {
        state.updateEmailAccountRampUpSettingsRequest.status =
          RequestStatus.Pending;
        state.updateEmailAccountRampUpSettingsRequest.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      updateEmailAccountRampUpSettingsRequest.fulfilled,
      (state, action) => {
        state.updateEmailAccountRampUpSettingsRequest.status =
          RequestStatus.Succeeded;
        state.updateEmailAccountRampUpSettingsRequest.message =
          action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      updateEmailAccountRampUpSettingsRequest.rejected,
      (state, action) => {
        state.updateEmailAccountRampUpSettingsRequest.status =
          RequestStatus.Failed;
        state.updateEmailAccountRampUpSettingsRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Email Time Interval
    builder.addCase(emailTimeIntervalSettingsRequest.pending, (state) => {
      state.emailTimeIntervalSettingsRequest.status = RequestStatus.Pending;
      state.emailTimeIntervalSettingsRequest.error = null;
    });
    builder.addCase(
      emailTimeIntervalSettingsRequest.fulfilled,
      (state, action) => {
        state.emailTimeIntervalSettingsRequest.status = RequestStatus.Succeeded;
        state.emailTimeIntervalSettingsRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      emailTimeIntervalSettingsRequest.rejected,
      (state, action) => {
        state.emailTimeIntervalSettingsRequest.status = RequestStatus.Failed;
        state.emailTimeIntervalSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    //   Get Email Account
    builder.addCase(getEmailAccountRequest.pending, (state) => {
      state.getEmailAccountRequest.status = RequestStatus.Pending;
      state.getEmailAccountRequest.error = null;
      state.isLoading = true;
    });

    builder.addCase(getEmailAccountRequest.fulfilled, (state, action) => {
      state.getEmailAccountRequest.status = RequestStatus.Succeeded;
      state.getEmailAccountRequest.error = null;
      state.emailAccount = action.payload.payload;
      state.isLoading = false;
    });

    builder.addCase(getEmailAccountRequest.rejected, (state, action) => {
      state.getEmailAccountRequest.status = RequestStatus.Failed;
      state.getEmailAccountRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get email account settings
    builder.addCase(getEmailAccountSettingsRequest.pending, (state) => {
      state.getEmailAccountSettingsRequest.status = RequestStatus.Pending;
      state.getEmailAccountSettingsRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      getEmailAccountSettingsRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountSettingsRequest.status = RequestStatus.Succeeded;
        state.emailAccountSettings = action.payload.payload;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getEmailAccountSettingsRequest.rejected,
      (state, action) => {
        state.getEmailAccountSettingsRequest.status = RequestStatus.Failed;
        state.getEmailAccountSettingsRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Fetch Email Account Health Score
    builder.addCase(getEmailAccountSetupScoreRequest.pending, (state) => {
      state.getEmailAccountSetupScoreRequest.status = RequestStatus.Pending;
      state.getEmailAccountSetupScoreRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      getEmailAccountSetupScoreRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountSetupScoreRequest.status = RequestStatus.Succeeded;
        state.emailAccountSetup = action.payload.payload;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getEmailAccountSetupScoreRequest.rejected,
      (state, action) => {
        state.getEmailAccountSetupScoreRequest.status = RequestStatus.Failed;
        state.getEmailAccountSetupScoreRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Test SMTP  Connection
    builder.addCase(testSMTPConnectionRequest.pending, (state) => {
      state.testSMTPConnectionRequest.status = RequestStatus.Pending;
      state.testSMTPConnectionRequest.message = null;
      state.testSMTPConnectionRequest.error = null;
    });
    builder.addCase(testSMTPConnectionRequest.fulfilled, (state, action) => {
      state.testSMTPConnectionRequest.status = RequestStatus.Succeeded;
      state.testSMTPConnectionResponse = action.payload.payload;
      state.testSMTPConnectionRequest.message = action.payload.message;
      state.testSMTPConnectionRequest.error = null;
    });
    builder.addCase(testSMTPConnectionRequest.rejected, (state, action) => {
      state.testSMTPConnectionRequest.status = RequestStatus.Failed;
      state.testSMTPConnectionRequest.message = action.payload.message;
      state.testSMTPConnectionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Test IMAP  Connection
    builder.addCase(testIMAPConnectionRequest.pending, (state) => {
      state.testIMAPConnectionRequest.status = RequestStatus.Pending;
      state.testIMAPConnectionRequest.message = null;
      state.testIMAPConnectionRequest.error = null;
    });
    builder.addCase(testIMAPConnectionRequest.fulfilled, (state, action) => {
      state.testIMAPConnectionRequest.status = RequestStatus.Succeeded;
      state.testIMAPConnectionResponse = action.payload.payload;
      state.testIMAPConnectionRequest.message = action.payload.message;
      state.testIMAPConnectionRequest.error = null;
    });
    builder.addCase(testIMAPConnectionRequest.rejected, (state, action) => {
      state.testIMAPConnectionRequest.status = RequestStatus.Failed;
      state.testIMAPConnectionRequest.message = action.payload.message;
      state.testIMAPConnectionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Connect SMTP IMAP Email Account
    builder.addCase(connectSmtpImapAccountRequest.pending, (state) => {
      state.connectSmtpImapAccountRequest.status = RequestStatus.Pending;
      state.connectSmtpImapAccountRequest.message = null;
      state.connectSmtpImapAccountRequest.error = null;
    });
    builder.addCase(
      connectSmtpImapAccountRequest.fulfilled,
      (state, action) => {
        state.connectSmtpImapAccountRequest.status = RequestStatus.Succeeded;
        state.connectSmtpImapAccountResponse = action.payload.payload;
        state.connectSmtpImapAccountRequest.message =
          action.payload.payload?.message;
        state.connectSmtpImapAccountRequest.error = null;
      },
    );
    builder.addCase(connectSmtpImapAccountRequest.rejected, (state, action) => {
      state.connectSmtpImapAccountRequest.status = RequestStatus.Failed;
      state.connectSmtpImapAccountRequest.message = action.payload.message;
      state.connectSmtpImapAccountRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get SMTP IMAP  Account Details
    builder.addCase(getSmtpImapAccountDetailsRequest.pending, (state) => {
      state.getSmtpImapAccountDetailsRequest.status = RequestStatus.Pending;
      state.getSmtpImapAccountDetailsRequest.message = null;
      state.getSmtpImapAccountDetailsRequest.error = null;
    });
    builder.addCase(
      getSmtpImapAccountDetailsRequest.fulfilled,
      (state, action) => {
        state.getSmtpImapAccountDetailsRequest.status = RequestStatus.Succeeded;
        state.sendGetSmtpImapAccountDetailsResponse = action.payload.payload;
        state.getSmtpImapAccountDetailsRequest.message = action.payload.message;
        state.getSmtpImapAccountDetailsRequest.error = null;
      },
    );
    builder.addCase(
      getSmtpImapAccountDetailsRequest.rejected,
      (state, action) => {
        state.getSmtpImapAccountDetailsRequest.status = RequestStatus.Failed;
        state.getSmtpImapAccountDetailsRequest.message = action.payload.message;
        state.getSmtpImapAccountDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update SMTP IMAP  Account Details
    builder.addCase(updateSmtpImapAccountDetailsRequest.pending, (state) => {
      state.updateSmtpImapAccountDetailsRequest.status = RequestStatus.Pending;
      state.updateSmtpImapAccountDetailsRequest.message = null;
      state.updateSmtpImapAccountDetailsRequest.error = null;
    });
    builder.addCase(
      updateSmtpImapAccountDetailsRequest.fulfilled,
      (state, action) => {
        state.updateSmtpImapAccountDetailsRequest.status =
          RequestStatus.Succeeded;
        state.sendUpdateSmtpImapAccountDetailsResponse = action.payload.payload;
        state.updateSmtpImapAccountDetailsRequest.message =
          action.payload.payload.message;
        state.updateSmtpImapAccountDetailsRequest.error = null;
      },
    );
    builder.addCase(
      updateSmtpImapAccountDetailsRequest.rejected,
      (state, action) => {
        state.updateSmtpImapAccountDetailsRequest.status = RequestStatus.Failed;
        state.updateSmtpImapAccountDetailsRequest.message =
          action.payload.message;
        state.updateSmtpImapAccountDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update Email Age Request
    builder.addCase(updateEmailAgeRequest.pending, (state) => {
      state.updateEmailAgeRequest.status = RequestStatus.Pending;
      state.updateEmailAgeRequest.message = null;
      state.updateEmailAgeRequest.error = null;
    });
    builder.addCase(updateEmailAgeRequest.fulfilled, (state, action) => {
      state.updateEmailAgeRequest.status = RequestStatus.Succeeded;
      state.updateEmailAgeRequest.message = action.payload.message;
      state.updateEmailAgeRequest.error = null;
    });
    builder.addCase(updateEmailAgeRequest.rejected, (state, action) => {
      state.updateEmailAgeRequest.status = RequestStatus.Failed;
      state.updateEmailAgeRequest.message = action.payload.message;
      state.updateEmailAgeRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Import Email Accounts Via CSV
    builder.addCase(importEmailAccountCsvRequest.pending, (state) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Pending;
      state.importEmailAccountCsvRequest.message = null;
      state.importEmailAccountCsvRequest.error = null;
    });
    builder.addCase(importEmailAccountCsvRequest.fulfilled, (state, action) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Succeeded;
      state.importEmailAccountCsvRequest.message = action.payload.message;
      state.importEmailAccountCsvRequest.error = null;
    });
    builder.addCase(importEmailAccountCsvRequest.rejected, (state, action) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Failed;
      state.importEmailAccountCsvRequest.message = action.payload.message;
      state.importEmailAccountCsvRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Bulk Email Update
    builder.addCase(bulkEmailUpdateRequest.pending, (state) => {
      state.bulkEmailUpdateRequest.status = RequestStatus.Pending;
      state.bulkEmailUpdateRequest.message = null;
      state.bulkEmailUpdateRequest.error = null;
    });
    builder.addCase(bulkEmailUpdateRequest.fulfilled, (state, action) => {
      state.bulkEmailUpdateRequest.status = RequestStatus.Succeeded;
      state.bulkEmailUpdateRequest.message =
        action.payload.payload.successMessage;
      state.bulkEmailUpdateRequest.error = null;
    });
    builder.addCase(bulkEmailUpdateRequest.rejected, (state, action) => {
      state.bulkEmailUpdateRequest.status = RequestStatus.Failed;
      state.bulkEmailUpdateRequest.message = action.payload.message;
      state.bulkEmailUpdateRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Trulyinbox email account api key with consent request
    builder.addCase(
      trulyinboxEmailAccountVerifyShApiKeyRequest.pending,
      (state) => {
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.status =
          RequestStatus.Pending;
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.message = null;
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.error = null;
      },
    );
    builder.addCase(
      trulyinboxEmailAccountVerifyShApiKeyRequest.fulfilled,
      (state, action) => {
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.status =
          RequestStatus.Succeeded;
        state.sendTrulyinboxEmailAccountVerifyShApiKeyResponse =
          action.payload.payload?.payload || {};
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.message =
          action.payload.payload?.message || '';
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.error = null;
      },
    );
    builder.addCase(
      trulyinboxEmailAccountVerifyShApiKeyRequest.rejected,
      (state, action) => {
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.status =
          RequestStatus.Failed;
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.message =
          action.payload.message;
        state.trulyinboxEmailAccountVerifyShApiKeyRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Email Account Tags
    builder.addCase(getEmailAccountTagsRequest.pending, (state) => {
      state.getEmailAccountTagsRequest.status = RequestStatus.Pending;
      state.getEmailAccountTagsRequest.error = null;
      state.getEmailAccountTagsRequest.message = null;
    });
    builder.addCase(getEmailAccountTagsRequest.fulfilled, (state, action) => {
      state.getEmailAccountTagsRequest.status = RequestStatus.Succeeded;
      state.emailAccountTagsList = action.payload.payload;
    });
    builder.addCase(getEmailAccountTagsRequest.rejected, (state, action) => {
      state.getEmailAccountTagsRequest.status = RequestStatus.Failed;
      state.getEmailAccountTagsRequest.message = action.payload.message;
      state.getEmailAccountTagsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    builder.addCase(emailAccountTagsAssignRequest.pending, (state) => {
      state.emailAccountTagsAssignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsAssignRequest.error = null;
      state.emailAccountTagsAssignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsAssignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsAssignRequest.status = RequestStatus.Succeeded;
        state.emailAccountTagsAssignRequest.message = action.payload.message;
      },
    );
    builder.addCase(emailAccountTagsAssignRequest.rejected, (state, action) => {
      state.emailAccountTagsAssignRequest.status = RequestStatus.Failed;
      state.emailAccountTagsAssignRequest.message = action.payload.message;
      state.emailAccountTagsAssignRequest.error =
        !action.payload.isHandled && action.payload;
    });

    builder.addCase(emailAccountTagsUnassignRequest.pending, (state) => {
      state.emailAccountTagsUnassignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsUnassignRequest.error = null;
      state.emailAccountTagsUnassignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsUnassignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsUnassignRequest.status = RequestStatus.Succeeded;
        state.emailAccountTagsUnassignRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      emailAccountTagsUnassignRequest.rejected,
      (state, action) => {
        state.emailAccountTagsUnassignRequest.status = RequestStatus.Failed;
        state.emailAccountTagsUnassignRequest.message = action.payload.message;
        state.emailAccountTagsUnassignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    builder.addCase(emailAccountTagsBulkAssignRequest.pending, (state) => {
      state.emailAccountTagsBulkAssignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsBulkAssignRequest.error = null;
      state.emailAccountTagsBulkAssignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsBulkAssignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsBulkAssignRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountTagsBulkAssignRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      emailAccountTagsBulkAssignRequest.rejected,
      (state, action) => {
        state.emailAccountTagsBulkAssignRequest.status = RequestStatus.Failed;
        state.emailAccountTagsBulkAssignRequest.message =
          action.payload.message;
        state.emailAccountTagsBulkAssignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    builder.addCase(emailAccountTagsBulkUnassignRequest.pending, (state) => {
      state.emailAccountTagsBulkUnassignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsBulkUnassignRequest.error = null;
      state.emailAccountTagsBulkUnassignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsBulkUnassignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsBulkUnassignRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountTagsBulkUnassignRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      emailAccountTagsBulkUnassignRequest.rejected,
      (state, action) => {
        state.emailAccountTagsBulkUnassignRequest.status = RequestStatus.Failed;
        state.emailAccountTagsBulkUnassignRequest.message =
          action.payload.message;
        state.emailAccountTagsBulkUnassignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Truly Inbox Token
    builder.addCase(getTrulyInboxTokenRequest.pending, (state) => {
      state.getTrulyInboxTokenRequest.status = RequestStatus.Pending;
      state.getTrulyInboxTokenRequest.error = null;
      state.getTrulyInboxTokenRequest.message = null;
    });
    builder.addCase(getTrulyInboxTokenRequest.fulfilled, (state, action) => {
      state.getTrulyInboxTokenRequest.status = RequestStatus.Succeeded;
      state.trulyInboxToken = action.payload.payload.accessToken;
    });
    builder.addCase(getTrulyInboxTokenRequest.rejected, (state, action) => {
      state.getTrulyInboxTokenRequest.status = RequestStatus.Failed;
      state.getTrulyInboxTokenRequest.message = action.payload.message;
      state.getTrulyInboxTokenRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  changeEmailAccountStatus,
  resetEmailAccountSetupState,
  resetEmailAccountRampUpSettingsRequestState,
  resetEmailAccountAndSettings,
  resetSmtpImapTestConnection,
  resetSmtpIMapConnectResponse,
  resetUpdateSmtpImapAccountDetails,
  resetUpdateEmailAgeRequest,

  onBulkSelectEmailAccounts,
  clearEmailAccountsSelection,
  selectAllEmailAccounts,
  selectSingleEmailAccount,
  resetSelectedEmailAccount,
  updateEmailAccountsFilters,
  resetEmailAccountTagsRequest,
  resetEmailAccountTagsList,
} = emailAccountSlice.actions;
export {
  connectEmailAccountRequest,
  verifyEmailAccountRequest,
  setDefaultEmailAccountRequest,
  disconnectEmailAccountRequest,
  updateEmailAccountStatusRequest,
  getEmailAccountsRequest,
  deleteEmailAccountRequest,
  updateEmailAccountRequest,
  emailTimeIntervalSettingsRequest,
  getEmailAccountTagsRequest,
  emailAccountTagsAssignRequest,
  emailAccountTagsUnassignRequest,
  emailAccountTagsBulkAssignRequest,
  emailAccountTagsBulkUnassignRequest,
};
export default emailAccountSlice.reducer;
