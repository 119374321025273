import api from '../../../api';
import { EmailAccountsFilters } from '../components/email-account/types';
import { EmailAccountMethod } from '../enums/email-account';
import { EmailAccountStatus } from '../types/email-account';
import {
  UpdateEmailAccountRampUpSettingsRequestPayload,
  UpdateEmailAccountRequestPayload,
  VerifyEmailAccountRequestPayload,
  EmailTimeIntervalSettingsRequest,
  EmailAccountTagsAssignPayload,
  EmailAccountTagsUnassignPayload,
  BulkActionPayload,
} from '../types/request-payload';
import {
  TestConnectionRequest,
  ConnectSmtpImapAccountRequest,
  UpdateSMTPIMAPAccountRequest,
} from '../types/smtp-imap';

export const connectEmailAccount = async (
  method: string,
  emailAccountId?: number,
) => {
  let requestUrl = `/email-account/connect/${method}`;
  if (emailAccountId) {
    requestUrl = `${requestUrl}/${emailAccountId}`;
  }
  return api.get(requestUrl);
};

export const verifyEmailAccount = async (
  method: EmailAccountMethod,
  payload: VerifyEmailAccountRequestPayload,
) => api.post(`/email-account/verify/${method}`, payload);

export const verifyWhitelabelEmailAccount = async (
  method: EmailAccountMethod,
  payload: VerifyEmailAccountRequestPayload,
) => api.post(`/email-account/whitelabel/verify/${method}`, payload);

export const setDefaultEmailAccount = async (emailAccountId: number) =>
  api.get(`/email-account/${emailAccountId}/make-default`);

export const disconnectEmailAccount = async (emailAccountId: number) =>
  api.get(`/email-account/${emailAccountId}/disconnect`);

export const getEmailAccounts = async ({
  order,
  status,
  ...params
}: EmailAccountsFilters) =>
  api.post(`/email-account`, {
    order: order === 1 ? 'ASC' : 'DESC',
    ...(status?.length > 0 && { status: status[0] }),
    ...params,
  });

export const getEmailAccountsList = async () => api.get(`/email-account/list`);

export const deleteEmailAccount = async (emailAccountId: number) =>
  api.delete(`/email-account/${emailAccountId}`);

export const updateEmailAccount = async (
  emailAccountId: number,
  payload: UpdateEmailAccountRequestPayload,
  // TODO: change endpoint in backend as well as frontend.
) => api.patch(`/email-account/${emailAccountId}`, payload);

export const getEmailAccount = async (hashId: string | number) =>
  api.get(`/email-account/${hashId}`);

export const getEmailAccountSettings = async (hashId: string | number) =>
  api.get(`/email-account/email-account-settings/${hashId}`);

type PayloadProps = Pick<
  UpdateEmailAccountRampUpSettingsRequestPayload,
  'rampUpInitialSendingLimit' | 'rampUpPercent' | 'rampUpStatus'
>;
export const updateEmailAccountRampUpSettings = async (
  emailAccountId: number,
  payload: PayloadProps,
) =>
  api.patch(`/email-account/ramp-up-settings/${emailAccountId}`, {
    rampUpInitialSendingLimit: Number(payload.rampUpInitialSendingLimit),
    rampUpPercent: Number(payload.rampUpPercent),
    rampUpStatus: payload.rampUpStatus,
  });

export const emailTimeIntervalSettings = async (
  emailAccountId: number,
  payload: EmailTimeIntervalSettingsRequest,
) => api.patch(`/email-account/interval-settings/${emailAccountId}`, payload);

export const getEmailAccountSetupScore = async (
  emailAccountId: number,
  refresh: boolean,
) =>
  api.get(`/email-account/email-setup-score/${emailAccountId}`, {
    params: { ...(refresh && { refresh }) },
  });

export const uploadImage = async (
  formData: FormData,
  onUploadProgress: (e: any) => void,
) => api.post('/attachment-broker/upload', formData, { onUploadProgress });

export const testSmtpImapConnection = async (payload: TestConnectionRequest) =>
  api.post('/email-account/smtp-imap/test-connection', payload);

export const connectSmtpImapAccount = async (
  payload: ConnectSmtpImapAccountRequest,
) => api.post('/email-account/smtp-imap/connect', payload);

export const getSmtpImapAccountDetails = async (emailAccountId: number) =>
  api.get(`/email-account/smtp-imap/${emailAccountId}`);

export const updateSmtpImapAccountDetails = async (
  emailAccountId: number,
  payload: UpdateSMTPIMAPAccountRequest,
) => api.patch(`/email-account/smtp-imap/${emailAccountId}`, payload);

export const updateEmailAge = async (
  emailAccountId: number,
  ageInMonths: number,
) => api.patch(`/email-account/${emailAccountId}/email-age`, { ageInMonths });

export const importEmailAccountCsv = (file: File) => {
  const data = new FormData();
  data.append('file', file);

  return api.post('/email-account/bulk-connect', data);
};

export const bulkEmailUpdate = (payload: any) =>
  api.post('/email-account/bulk-operation', payload);

export const trulyinboxEmailAccountConsent = () =>
  api.patch('/email-account/ti-sync/consent');

export const trulyinboxEmailAccountVerifyShApiKey = () =>
  api.get('/email-account/ti-sync/verify-api-key');

export const getTagsToEmailAccount = async () => api.get(`/email-account/tag`);

export const emailAccountTagsAssign = async (
  payload: EmailAccountTagsAssignPayload,
) => api.post('/email-account/tag/assign', payload);

export const emailAccountTagsUnassign = async (
  payload: EmailAccountTagsUnassignPayload,
) => api.post('/email-account/tag/un-assign', payload);

export const emailAccountTagsBulkAssign = async (payload: BulkActionPayload) =>
  api.post('/email-account/tag/bulk-assign', payload);

export const emailAccountTagsBulkUnassign = async (
  payload: BulkActionPayload,
) => api.post('/email-account/tag/bulk-un-assign', payload);

export const getTrulyInboxToken = async (tiUserId: number, email: string) =>
  api.post(`user/${tiUserId}/login-as-truly-inbox-user`, {
    email,
  });

export const updateEmailAccountStatus = async (
  emailAccountId: number,
  status: EmailAccountStatus,
) => api.patch(`/email-account/status/${emailAccountId}`, { status });
