import { EmailServiceProvider } from '../../components/settings/enums/email-account';

const EmailAccountsESPLabel: Record<EmailServiceProvider, string> = {
  [EmailServiceProvider.Gmail]: 'Google',
  [EmailServiceProvider.Gsuite]: 'Google',
  [EmailServiceProvider.Microsoft]: 'Microsoft',
  [EmailServiceProvider.O365]: 'Microsoft',
  [EmailServiceProvider.Yahoo]: 'Yahoo',
  [EmailServiceProvider.Zoho]: 'Zoho',
  [EmailServiceProvider.Godaddy]: 'Godaddy',
  [EmailServiceProvider.Yandex]: 'Yandex',
  [EmailServiceProvider.Sendgrid]: 'Sendgrid',
  [EmailServiceProvider.Other]: 'Other',
};

export const getEmailServiceProviderGroups = () =>
  Object.entries(EmailAccountsESPLabel).reduce((acc, [provider, label]) => {
    const group = acc.find((g) => g.name === label);
    if (group) {
      group.value.push(provider);
    } else {
      acc.push({ key: label, name: label, value: [provider] });
    }
    return acc;
  }, []);

export const getLabelForEmailServiceProvider = (
  provider: EmailServiceProvider,
) => EmailAccountsESPLabel[provider] || 'Other';

export const getLabelsForProviders = (providers: EmailServiceProvider[]) => {
  const labels = new Set(
    providers.map((provider) => EmailAccountsESPLabel[provider]),
  );

  return Array.from(labels);
};
