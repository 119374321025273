export enum ShAccountSettingsCode {
  IsVerificationActive = 'is-verification-active',
  ProspectDuplicationAllowed = 'prospect-duplication-allowed',
  IntegrationSetting = 'integration-setting',
  ProspectSharing = 'prospect-sharing',
  AutoAddToProspectOnLeadReveal = 'auto-add-to-prospects-on-lead-reveal',
  AICategorization = 'AI-Categorization',
  TrackUniboxOpen = 'track-unibox-open',
  TrackUniboxClick = 'track-unibox-click',
  AddProspectFromBusinessDomain = 'track-replies-and-add-prospects-from-business-domain',
  TrackExternalMails = 'track-external-mails',
  EnableExclusionList = 'enable-exclusion-list',
  IsMultichannelActive = 'is-multichannel-active',
}
