/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import { SortOrder } from 'react-bootstrap-table-next';
import { Check, Cross, InfoCircle, Trulyinbox } from '@saleshandy/icons';

import { EmailAccount } from '../../../../../types/email-account';
import { EmailAccountsSortBy } from '../../../../../enums/email-account';
import { Column } from '../../../../../../../shared/design-system/components/organisms/table/types';

import EmailAccountSwitch from '../fields/email-account-switch';
import SenderNameField from '../fields/sender-name-field';
import SetupScoreField from '../fields/setup-score-field';
import ActiveSequencesField from '../fields/active-sequences-field';
import EmailSentField from '../fields/email-sent-field';
import AddedByField from '../fields/added-by-field';
import {
  OverlayPopover,
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { getClientName } from '../../../../../../agency-client-management/helpers/helpers';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { isAgency } from '../../../../../../../shared/utils/user-details';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import ProspectTagsRow from '../../../../../../../shared/design-system/components/molecules/prospect-tags-row';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import EmailWarmupStatusCell from '../components/email-warmup-status-cell';
import { supportUrls } from '../../../../../../../shared/utils/urls';

export const getEmailAccountsColumns = ({
  itemCount,
  onSort,
  onEmailAccountsSwitchChangeHandler,
  redirectToGeneralSettings,
  onSmtpImapModalShow,
  hideColumn,
}: {
  itemCount: number;
  onSort: (sortBy: EmailAccountsSortBy, order: SortOrder) => void;
  onEmailAccountsSwitchChangeHandler: (emailAccount: EmailAccount) => void;
  redirectToGeneralSettings: (emailAccount: EmailAccount) => void;
  onSmtpImapModalShow: (emailAccount: EmailAccount) => void;
  hideColumn: {
    isWarmupStatusVisible: boolean;
    isDeliverabilityStatusVisible: boolean;
  };
}): Column[] => {
  const canDisconnectEmailAccount = hasPermission(
    Permissions.EMAIL_ACCOUNT_DISCONNECT,
  );
  const isClientAssociatedVisible =
    hasPermission(Permissions.CLIENT_READ) && isAgency();

  const [spfPopover, setSpfPopover] = useState(false);
  const [dmarcPopover, setDmarcPopover] = useState(false);
  const [customDomainPopover, setCustomDomainPopover] = useState(false);

  const tooltipRef = useRef<{ [key: string]: HTMLElement | null }>({
    spf: null,
    dmarc: null,
    customDomain: null,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      if (spfPopover && !tooltipRef.current.spf?.contains(target)) {
        setSpfPopover(false);
      }
      if (dmarcPopover && !tooltipRef.current.dmarc?.contains(target)) {
        setDmarcPopover(false);
      }
      if (
        customDomainPopover &&
        !tooltipRef.current.customDomain?.contains(target)
      ) {
        setCustomDomainPopover(false);
      }
    };

    if (spfPopover || dmarcPopover || customDomainPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [spfPopover, dmarcPopover, customDomainPopover]);

  return [
    canDisconnectEmailAccount && {
      dataField: 'status',
      text: '',
      headerAlign: 'start',
      headerClasses: 'status-header',
      cellClasses: 'status-cell email-account-switch-cell',
      component: (_cell, row) => (
        <EmailAccountSwitch
          emailAccount={row}
          onChangeHandler={onEmailAccountsSwitchChangeHandler}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    {
      dataField: 'fromName',
      text: 'Sender Name',
      headerAlign: 'start',
      headerClasses: `name-header ${
        canDisconnectEmailAccount ? 'with-toggle' : ''
      }`,
      cellClasses: `sender-name-cell name-cell ${
        canDisconnectEmailAccount ? 'with-toggle' : ''
      }`,
      component: (_cell, row) => (
        <SenderNameField
          emailAccount={row}
          redirectToGeneralSettings={redirectToGeneralSettings}
          onSmtpImapModalShow={onSmtpImapModalShow}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    {
      dataField: 'activeSequences',
      text: 'Active Sequences',
      headerAlign: 'start',
      headerClasses: 'active-sequence-cell-header',
      cellClasses: 'active-sequences-cell active-sequence-cell-header',
      component: (_cell, row, rowIndex) => (
        <ActiveSequencesField
          emailAccount={row}
          rowNumber={rowIndex + 1}
          itemCount={itemCount}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    hideColumn?.isWarmupStatusVisible && {
      dataField: 'warmupStatus',
      text: 'Warm-up Status',
      headerComponent: () => (
        <div className="trulyinbox">
          <Trulyinbox className="icon-trulyinbox" />
          <span>Warm-up Status</span>
        </div>
      ),
      headerAlign: 'start',
      cellClasses: 'warmup-status-cell',
      component: (cell) => <EmailWarmupStatusCell warmupStatus={cell} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    hideColumn?.isDeliverabilityStatusVisible && {
      dataField: 'deliverabilityRate',
      text: 'Deliverability Score',
      headerComponent: () => (
        <div className="trulyinbox">
          <Trulyinbox className="icon-trulyinbox" />
          <span>Deliverability Score</span>
        </div>
      ),
      sort: true,
      onSort: (_field, order) =>
        onSort(EmailAccountsSortBy.DeliverabilityRate, order),
      headerAlign: 'start',
      cellClasses: 'deliverability-score-cell',
      component: (cell) => (
        <span className={cell && 'font-weight-medium'}>
          {cell ? `${cell}%` : '-'}
        </span>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'spf',
      text: 'SPF',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>SPF</span>
          <div ref={(el) => (tooltipRef.current.spf = el)}>
            <OverlayPopover
              className="permission-popover"
              show={spfPopover}
              onToggle={(showPopover: boolean) => setSpfPopover(showPopover)}
              placement={Placement.Bottom}
              content={
                <>
                  <div className="content">
                    Ensures that only authorized servers can send emails on
                    behalf of your domain. Prevents spoofing.{' '}
                    <a
                      href={supportUrls.emailAccountSpf}
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Learn More
                    </a>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    {...accessibleOnClick(() => setSpfPopover(false))}
                  >
                    <Cross className="cross-icon" />
                  </div>
                </>
              }
            >
              <InfoCircle
                className={`info-icon-popover-btn cursor-pointer ${
                  spfPopover ? 'isInfoIconVisible' : 'isInfoIconNotVisible'
                } `}
              />
            </OverlayPopover>
          </div>
        </div>
      ),
      headerAlign: 'start',
      component: (cell) => (
        <div>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'dmarc',
      text: 'DMARC',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>DMARC</span>
          <div ref={(el) => (tooltipRef.current.dmarc = el)}>
            <OverlayPopover
              className="permission-popover"
              show={dmarcPopover}
              onToggle={(showPopover: boolean) => setDmarcPopover(showPopover)}
              placement={Placement.Bottom}
              content={
                <>
                  <div className="content">
                    Aligns SPF and DKIM policies, instructing mail servers on
                    how to handle emails that fail authentication. Provides
                    reports for visibility.{' '}
                    <a
                      href={supportUrls.emailAccountDmarc}
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Learn More
                    </a>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    {...accessibleOnClick(() => setDmarcPopover(false))}
                  >
                    <Cross className="cross-icon" />
                  </div>
                </>
              }
            >
              <InfoCircle
                className={`info-icon-popover-btn cursor-pointer ${
                  dmarcPopover ? 'isInfoIconVisible' : 'isInfoIconNotVisible'
                } `}
              />
            </OverlayPopover>
          </div>
        </div>
      ),
      headerAlign: 'start',
      component: (cell) => (
        <div>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'customTrackingDomain',
      text: 'Custom Domain',
      headerComponent: () => (
        <div className="header-icon-component">
          <span>Custom Domain</span>
          <div ref={(el) => (tooltipRef.current.customDomain = el)}>
            <OverlayPopover
              className="permission-popover"
              show={customDomainPopover}
              onToggle={(showPopover: boolean) =>
                setCustomDomainPopover(showPopover)
              }
              placement={Placement.Bottom}
              content={
                <>
                  <div className="content">
                    A custom tracking domain ensures that tracking links match
                    your domain, preventing spam filters from flagging
                    mismatched domains.{' '}
                    <a
                      href={supportUrls.emailAccountCustomDomain}
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Learn More
                    </a>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    {...accessibleOnClick(() => setCustomDomainPopover(false))}
                  >
                    <Cross className="cross-icon" />
                  </div>
                </>
              }
            >
              <InfoCircle
                className={`info-icon-popover-btn cursor-pointer ${
                  customDomainPopover
                    ? 'isInfoIconVisible'
                    : 'isInfoIconNotVisible'
                } `}
              />
            </OverlayPopover>
          </div>
        </div>
      ),
      headerAlign: 'start',
      component: (cell) => (
        <div>
          {cell ? (
            <Check className="check-cross-icon" color="green" />
          ) : (
            <Cross className="check-cross-icon" color="red" />
          )}
        </div>
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'tags',
      text: 'Tags',
      headerAlign: 'left',
      cellClasses: 'tag-detail',
      style: () => ({ width: '5rem' }),
      component: (cell) => <ProspectTagsRow tags={cell} />,
      headerLoadingSkeleton: <SkeletonLoading width={29} height={14} />,
      cellLoadingSkeleton: (
        <div className="d-flex">
          <SkeletonLoading width={84} height={24} />
          <div className="ml-2">
            <SkeletonLoading width={64} height={24} />
          </div>
        </div>
      ),
    },
    hasPermission(
      Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
    ) && {
      dataField: 'emailSent',
      text: 'Email Sent',
      headerAlign: 'start',
      cellClasses: 'email-sent-cell',
      headerStyle: () => ({ minWidth: '10.5rem', width: '10.5rem' }),
      component: (_cell, row, rowIndex) => (
        <EmailSentField
          emailAccount={row}
          rowNumber={rowIndex + 1}
          itemCount={itemCount}
        />
      ),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && {
      dataField: 'healthScore',
      text: 'Setup Score',
      headerAlign: 'start',
      cellClasses: 'setup-score-cell',
      sort: true,
      onSort: (_field, order) => onSort(EmailAccountsSortBy.SetupScore, order),
      component: (_cell, row) => <SetupScoreField emailAccount={row} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    ...(isClientAssociatedVisible
      ? [
          {
            dataField: 'client',
            text: 'Client Associated',
            headerAlign: 'start',
            cellClasses: 'email-account-client-associated',
            headerStyle: () => ({ minWidth: '10.5rem', width: '10.5rem' }),
            component: (cell) => {
              if (cell) {
                const { firstName, companyName, email } = cell;
                if (firstName && companyName && email) {
                  return (
                    <div className="sequence-client-associated-name">
                      <OverlayTooltip
                        text={getClientName(cell, 60, {
                          email: true,
                          deleted: true,
                        })}
                        placement={Placement.BottomStart}
                        className="email-account-client-associated-tooltip"
                      >
                        <p>
                          {getClientName(cell, 23, {
                            company: true,
                          })}
                        </p>
                      </OverlayTooltip>
                    </div>
                  );
                }
              }
              return '-';
            },
            headerLoadingSkeleton: <SkeletonLoading width={68} height={14} />,
            cellLoadingSkeleton: <SkeletonLoading width={76} height={14} />,
          },
        ]
      : []),
    {
      dataField: 'user',
      text: 'Added By',
      headerAlign: 'start',
      cellClasses: 'added-by-cell',
      component: (_cell, row) => <AddedByField emailAccount={row} />,
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
  ].filter((col) => col);
};
