export const getSeparatedTextList = (value: string, separators: string[]) => {
  const regex = new RegExp(`[${separators.join('')}]`, 'g');
  return value
    .split(regex)
    .map((val) => val.trim())
    .filter((val) => val);
};

export const getTrimmedChipValue = (value: string, maxLength: number) => {
  if (value.length < maxLength) {
    return value;
  }
  return value.substring(0, maxLength).trim().concat('..');
};
