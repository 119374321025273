/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@saleshandy/icons';
import { Button } from 'react-bootstrap';
import Modal from '../../design-system/components/atoms/modal';
import SHTag from '../images/sh-tag';
import { RenderMode } from '../../design-system/components/molecules/tag-autosuggest/tag-autosuggest';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { OverlayTooltip } from '../../design-system/components/overlay';
import TagAutosuggest2 from '../../design-system/components/molecules/tag-autosuggest-2';
import { BulkSelectedEmailAccounts } from '../../../components/settings/components/email-account/types';

type TagType = { id: number; name: string }; // Example Tag Type

type AddRemoveTagProps = {
  handleAddTag: (tags: TagType[]) => void;
  handleRemoveTag: (tags: TagType[]) => void;
  selectedRecords: any[];
  tags?: TagType[];
  disabled?: boolean;
  isShowOnlyTagIcon?: boolean;
  isSubmitLoading: boolean;
  isAddTagModal?: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  bulkSelectedOptions?: BulkSelectedEmailAccounts;
  onValidate?: (callback: VoidFunction) => void;
  maxLimit?: number | null;
  tagIconHeight?: number;
  tagIconWidth?: number;
};

const AddRemoveTag = forwardRef(
  (
    {
      handleAddTag,
      handleRemoveTag,
      disabled = false,
      selectedRecords,
      tags,
      isShowOnlyTagIcon = true,
      isSubmitLoading,
      isAddTagModal,
      setShowModal,
      showModal,
      bulkSelectedOptions = null,
      onValidate = null,
      maxLimit = null,
      tagIconHeight = 24,
      tagIconWidth = 24,
    }: AddRemoveTagProps,
    ref,
  ) => {
    const limitExceededRef = useRef(false);
    const { t } = useTranslation();

    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [tagsToAdd, setTagsToAdd] = useState([]);
    const [tagsToRemove, setTagsToRemove] = useState([]);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

    const closeDropdown = () => setShowDropdown(false);
    const closeModal = () => {
      setTagsToAdd([]);
      setTagsToRemove([]);
      return setShowModal(false);
    };

    const isRemoveMode = modalTitle.toLowerCase() !== 'add tags';

    const toggleDropdown = () => {
      const toggle = () => setShowDropdown((prev) => !prev);
      if (!disabled) {
        if (onValidate) {
          onValidate(toggle);
        } else {
          toggle();
        }
      }
    };

    const openAddTagModal = () => {
      closeDropdown();
      setModalTitle('Add Tags');
      setShowModal(true);
      setTagsToAdd([]);
      setIsApplyButtonDisabled(true);
    };

    const openRemoveTagModal = () => {
      closeDropdown();
      setModalTitle('Remove Tags');
      setShowModal(true);
      setTagsToRemove([]);
      setIsApplyButtonDisabled(true);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          closeDropdown();
        }
      };

      // Add event listener to detect clicks outside the dropdown
      document.addEventListener('mousedown', handleClickOutside);

      // Cleanup the event listener
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      const newTagValue = tagsToAdd.some((tag) => tag.name.length > 64);
      setIsApplyButtonDisabled(tagsToAdd.length === 0 || newTagValue);
    }, [tagsToAdd]);

    useEffect(() => {
      if (
        !showModal &&
        (tagsToAdd?.length !== 0 || tagsToRemove?.length !== 0)
      ) {
        setTagsToAdd([]);
        setTagsToRemove([]);
      }
    }, [showModal]);

    // Use useImperativeHandle to expose closeModal to the parent component
    useImperativeHandle(ref, () => ({
      closeModal,
      openAddTagModal,
    }));

    // Submit the modal form
    const handleSubmitModal = useCallback(() => {
      isRemoveMode ? handleRemoveTag(tagsToAdd) : handleAddTag(tagsToAdd);
    }, [isRemoveMode, handleAddTag, handleRemoveTag, tagsToAdd, tagsToRemove]);

    const generateModalTitleContent = useCallback(
      () => (
        <div className="modal-header-title">
          <SHTag />
          <div className="pl-2">{modalTitle}</div>
        </div>
      ),
      [modalTitle],
    );

    const removableTags = useMemo(() => {
      if (!tags) {
        return [];
      }
      if (
        bulkSelectedOptions &&
        bulkSelectedOptions.isAllEmailAccountsSelected
      ) {
        return tags;
      }

      if (!selectedRecords || selectedRecords.length === 0) {
        return [];
      }

      const selectedRecordTags = selectedRecords.flatMap(
        (record) => record.tags || [],
      );

      return tags.filter((tag) =>
        selectedRecordTags.some((recordTag) => recordTag.id === tag.id),
      );
    }, [tags, selectedRecords]);

    return (
      <>
        {!isAddTagModal && (
          <div className="tag-button-container">
            <OverlayTooltip
              text="Add or remove tags"
              className={showDropdown ? 'tooltip-hidden' : 'tooltip-visible'}
            >
              {isShowOnlyTagIcon ? (
                <div
                  className={`tag-icon d-flex align-items-center justify-content-center ${
                    // eslint-disable-next-line no-nested-ternary
                    disabled ? 'disabled' : showDropdown ? 'selected' : ''
                  }`}
                  {...accessibleOnClick(toggleDropdown)}
                >
                  <Tag
                    width={tagIconWidth}
                    height={tagIconHeight}
                    className={disabled ? '' : ''}
                  />
                </div>
              ) : (
                <div className="d-flex align-items-center pointer">
                  <Button
                    className="tag-button"
                    onClick={toggleDropdown}
                    disabled={disabled}
                  >
                    <Tag width={tagIconWidth} height={tagIconHeight} />
                    <span>Tags</span>
                  </Button>
                </div>
              )}
            </OverlayTooltip>
            {showDropdown && !isAddTagModal && (
              <div className="add-btn-container" ref={dropdownRef}>
                <ul className="dropdown-container">
                  <li
                    className="menu-item"
                    {...accessibleOnClick(openAddTagModal)}
                  >
                    <div className="add-tag-btn dropdown">
                      <div className="p-text-tag">
                        <p className="tag-list-font menu-text">Add Tags</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className="menu-item"
                    {...accessibleOnClick(openRemoveTagModal)}
                  >
                    <div className="add-tag-btn dropdown">
                      <div className="p-text-tag remove-tag-modal">
                        <p className="tag-list-font menu-text">Remove Tags</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        <Modal
          show={showModal && !disabled}
          className="add-remove-tag-email-modal"
          titleContent={generateModalTitleContent()}
          onClose={closeModal}
          submitButtonText={t('labels.apply')}
          isSubmitDisabled={
            isApplyButtonDisabled || isSubmitLoading || limitExceededRef.current
          }
          isSubmitLoading={isSubmitLoading}
          onSubmit={handleSubmitModal}
          backdrop="static"
          submitButtonClassName="submit-btn"
          cancelButtonClassName="cancel-btn"
          extraModalProps={{
            enforceFocus: false,
            'aria-labelledby': 'contained-modal-title-vcenter',
          }}
        >
          <div
            className={`email-tab-tags ${
              isRemoveMode && 'remove-tag-modal'
            } modal-input ${isRemoveMode ? 'remove-tag-modal-color' : ''}`}
          >
            <TagAutosuggest2
              selectedProspects={[]}
              showCreateSuggestion={!isRemoveMode}
              defaultSuggestionOpened={true}
              restrictExistingOnly={isRemoveMode}
              tagsToAdd={tagsToAdd}
              tags={isRemoveMode ? removableTags : tags}
              setTagsToAdd={(data) => {
                setTagsToAdd(data);
              }}
              tagsToRemove={tagsToRemove}
              setTagsToRemove={setTagsToRemove}
              renderMode={RenderMode.Inline}
              showAddedTags={false}
              maxLimit={
                bulkSelectedOptions?.isAllEmailAccountsSelected
                  ? maxLimit
                  : null
              }
              limitExceededRef={limitExceededRef}
            />
          </div>
        </Modal>
      </>
    );
  },
);

export default AddRemoveTag;
