export enum SequenceScoreErrorType {
  FirstStepNotFound = 2014,
  MaximumEmailContentSizeExceeded = 2015,
  NoEmailAccountConnectedToSequence = 2016,
  FirstStepDataNotAvailable = 2017,
}

export enum SequenceScoreTab {
  EmailAccountSetupScore = 'emailAccountSetupScore',
  SequenceSetupScore = 'sequenceSetupScore',
  EmailWritingScore = 'emailWritingScore',
}

export enum SequenceScoreChartColor {
  poor = '#DC2626',
  belowAverage = '#F97316',
  average = '#F59E0B',
  good = '#34D399',
  veryGood = '#059669',
}
