import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';
import {
  getCustomDomains,
  createCustomDomain,
  deleteCustomDomain,
  updateCustomDomain,
  getCustomDomainsList,
  setDefaultCustomDomain,
  checkBulkEmailAccountSetupScore,
} from '../../helpers/custom-domain.api';
import {
  CreateCustomDomainPayload,
  DeleteCustomDomainPayload,
  UpdateCustomDomainPayload,
} from './types';

export const setDefaultCustomDomainRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/setDefaultCustomDomainRequest', async (args, thunkAPI) => {
  try {
    return await setDefaultCustomDomain(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getCustomDomainsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/getCustomDomainsRequest', async (args, thunkAPI) => {
  try {
    return await getCustomDomains();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getCustomDomainsListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/getCustomDomainListRequest', async (args, thunkAPI) => {
  try {
    return await getCustomDomainsList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createCustomDomainRequest = createAsyncThunk<
  ResponseSuccess,
  CreateCustomDomainPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'customDomain/createCustomDomainRequest',
  async ({ domain, emailAccountIds }, thunkAPI) => {
    try {
      return await createCustomDomain(domain, emailAccountIds);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
export const deleteCustomDomainRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteCustomDomainPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'customDomain/deleteCustomDomainRequest',
  async ({ userCustomDomainId }, thunkAPI) => {
    try {
      return await deleteCustomDomain(userCustomDomainId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
export const updateCustomDomainRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateCustomDomainPayload,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/updateCustomDomainRequest', async (payload, thunkAPI) => {
  try {
    return await updateCustomDomain(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const checkBulkEmailAccountSetupScoreRequest = createAsyncThunk<
  ResponseSuccess,
  number[],
  { rejectValue: ResponseErrorWithHandled }
>(
  'customDomain/checkBulkEmailAccountSetupScoreRequest',
  async (payload, thunkAPI) => {
    try {
      return await checkBulkEmailAccountSetupScore(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
