import classNames from 'classnames';
import {
  NotificationAction,
  NotificationReason,
  Notifications,
  NotificationType,
} from '../../../../../../components/home/types';
import { EmailAccountType } from '../../../../../../components/settings/enums/email-account';
import { Images } from '../../../../../app-constants';
import {
  getAgencyConfigDetails,
  isWhitelabelWithClientView,
} from '../../../../../utils/user-details';

const quoteRegex = /(“.*?”)/g;
const quoteRegexForEmail = /(”.*?”)/g;

export const getRedirectUrl = (notification: Notifications) => {
  const reactAppUrl = isWhitelabelWithClientView()
    ? getAgencyConfigDetails()?.clientBaseUrl || ''
    : process.env.REACT_APP_BASE_URL;
  const notificationRedirectionUrl = `${reactAppUrl}/settings/email-accounts`;
  const notificationUrlParams = `emailAccountId=${notification.emailAccountId}&emailAccountType=${notification.emailAccountType}`;

  switch (notification.notificationType) {
    case NotificationType.EmailAccountDisconnected:
    case NotificationType.EmailAccountPaused:
      return notification.emailAccountType === EmailAccountType.SmtpImap
        ? `${notificationRedirectionUrl}?${notificationUrlParams}`
        : notificationRedirectionUrl;
    case NotificationType.SequencePaused:
    case NotificationType.SequencePausedBounce:
      return `${reactAppUrl}/sequence/${notification.sequenceId}/steps`;
    case NotificationType.UniboxScheduleCanceled:
      return `${reactAppUrl}/conversations/${encodeURIComponent(
        notification.emailThreadHashId,
      )}?page=1&type=draft&search=${encodeURIComponent(
        notification.prospectEmail,
      )}`;
    case NotificationType.LeadRevealCompleted:
    case NotificationType.LeadRevealCompletedNoEmailOrPhone:
      return notification.reportUrl;
    default:
      return '';
  }
};

export const moreNotificationExist = (
  notificationsLength: number,
  notificationCount: number,
) => notificationsLength < notificationCount;

export const getNotificationType = (notification: Notifications) => {
  if (
    notification.notificationType === NotificationType.UniboxScheduleCanceled
  ) {
    return NotificationAction.ViewDraft;
  }
  if (notification.notificationType === NotificationType.WhitelabelDomainSet) {
    return NotificationAction.Login;
  }
  if (
    notification.notificationType === NotificationType.LeadRevealCompleted ||
    notification.notificationType ===
      NotificationType.LeadRevealCompletedNoEmailOrPhone
  ) {
    return NotificationAction.Leads;
  }

  if (notification?.reportUrl) {
    return NotificationAction.Download;
  }

  if (
    notification.notificationType === NotificationType.SequenceProspectImport
  ) {
    return '';
  }

  if (notification.notificationType === NotificationType.EmailAccountPaused) {
    return NotificationAction.Activate;
  }

  return notification.notificationType ===
    NotificationType.EmailAccountDisconnected
    ? NotificationAction.Reconnect
    : NotificationAction.Resume;
};

export const getRegexForEmail = (notification: Notifications) =>
  notification.notificationType === NotificationType.EmailAccountDisconnected
    ? quoteRegexForEmail
    : quoteRegex;

export const getNotificationReason = (notification: Notifications) => {
  if (
    notification.notificationType === NotificationType.UniboxScheduleCanceled
  ) {
    return NotificationReason.UniboxScheduleCanceled;
  }
  if (
    notification.notificationType ===
    NotificationType.SequenceProspectExportForClient
  ) {
    return NotificationReason.SequenceProspectExportForClient;
  }
  if (notification.notificationType === NotificationType.SequenceReportClient) {
    return NotificationReason.SequenceReportClient;
  }
  if (
    notification.notificationType ===
    NotificationType.UniboxConversationForClient
  ) {
    return NotificationReason.UniboxConversationForClient;
  }
  if (
    notification.notificationType ===
    NotificationType.EmailAccountBulkConnectClient
  ) {
    return NotificationReason.EmailAccountBulkConnectClient;
  }
  if (
    notification.notificationType ===
    NotificationType.SequenceBulkProspectClient
  ) {
    return NotificationReason.SequenceBulkProspectClient;
  }
  if (notification.notificationType === NotificationType.WhitelabelDomainSet) {
    return NotificationReason.WhitelabelDomainSet;
  }
  if (notification.notificationType === NotificationType.DnsImport) {
    return NotificationReason.DnsImport;
  }
  if (notification.notificationType === NotificationType.DnsExport) {
    return NotificationReason.DnsExport;
  }
  if (
    notification.notificationType === NotificationType.SequenceProspectImport
  ) {
    return NotificationReason.SequenceProspectImport;
  }
  if (notification.notificationType === NotificationType.LeadRevealCompleted) {
    return NotificationReason.LeadRevealCompleted;
  }
  if (
    notification.notificationType ===
    NotificationType.LeadRevealCompletedNoEmailOrPhone
  ) {
    return NotificationReason.LeadRevealCompletedNoEmailOrPhone;
  }
  if (
    notification.notificationType === NotificationType.SequenceProspectVerify ||
    notification.notificationType === NotificationType.ProspectVerifyReport
  ) {
    return NotificationReason.ProspectVerifyReport;
  }
  if (
    notification.notificationType ===
    NotificationType.SequenceProspectBulkAction
  ) {
    return NotificationReason.SequenceProspectBulkAction;
  }

  if (notification.notificationType === NotificationType.ProspectBulkAction) {
    return NotificationReason.ProspectBulkAction;
  }

  if (notification.notificationType === NotificationType.EmailAccountPaused) {
    return NotificationReason.EmailAccountPaused;
  }

  return notification.notificationType ===
    NotificationType.EmailAccountDisconnected
    ? NotificationReason.EmailDisconneted
    : NotificationReason.SequencePaused;
};

export const getImageIcon = (notification: Notifications) => {
  if (
    notification.notificationType === NotificationType.LeadRevealCompleted ||
    notification.notificationType ===
      NotificationType.LeadRevealCompletedNoEmailOrPhone
  ) {
    return Images.NotificationLeadBulkReveal;
  }

  return [
    NotificationType.EmailAccountDisconnected,
    NotificationType.UniboxScheduleCanceled,
  ].includes(notification.notificationType)
    ? Images.NotificationAlert
    : Images.NotificationPause;
};

export const getImageIconForBulkActionCompleted = () => Images.CheckGreen;

export const getNotificationClassName = (showNotifications: boolean) =>
  classNames([
    'notification-circle d-flex bs-badge hover-on-header',
    {
      'active-notification': showNotifications,
    },
  ]);

export const getNotificationTypeClass = (notification) => {
  if (
    notification?.reportUrl ||
    notification.notificationType === NotificationType.ProspectBulkAction ||
    notification.notificationType ===
      NotificationType.SequenceProspectBulkAction ||
    notification.notificationType === NotificationType.SequenceProspectImport
  ) {
    return 'green'; // or 'green' if you prefer
  }

  if (
    [
      NotificationType.LeadRevealCompleted,
      NotificationType.LeadRevealCompletedNoEmailOrPhone,
    ].includes(notification.notificationType)
  ) {
    return 'purple';
  }

  return 'default';
};

export const getNotificationClass = (notification) => {
  const notificationType = getNotificationTypeClass(notification);

  if (notificationType === 'green') {
    return 'bs-badge-status bs-badge-status-success bs-badge-dot';
  }

  if (notificationType === 'purple') {
    return 'bs-badge-purple-dot';
  }

  return 'bs-badge-dot';
};

export const getNotificationClassNameFordotColor = (notification) => {
  const notificationType = getNotificationTypeClass(notification);

  if (notificationType === 'green') {
    return 'notification-green';
  }

  if (notificationType === 'purple') {
    return 'notification-purple';
  }

  return 'notification-red';
};
