import React, { useMemo } from 'react';
import Pagination from '../../pagination';
import { constants } from '../../../../enums/constants';

const PaginationWrapper: React.FC<any> = (props) => {
  const {
    dataSize,
    page,
    showRowsPerPage,
    isNewPagination,
    onPageChange,
    sequenceFixedPageSize,
    fixedPageSize,
  } = props;

  let defaultSize = sequenceFixedPageSize
    ? constants.DEFAULT_SEQUENCE_PAGE_SIZE
    : constants.DEFAULT_PAGE_SIZE;
  if (fixedPageSize) {
    defaultSize = fixedPageSize || defaultSize;
  }
  const perPageLimit = isNewPagination ? showRowsPerPage : defaultSize;

  const currentPage = useMemo(() => {
    const pageNumber = Number(page);
    if (isNaN(pageNumber)) {
      console.log('Page is not a number. Defaulting to page 1.');
      return 1;
    }
    return pageNumber;
  }, [page]);

  const totalPages = useMemo(() => {
    const totalPagesCount = Math.ceil(dataSize / perPageLimit);

    if (currentPage > totalPagesCount) {
      console.log("Case: Current page shouldn't be greater than total pages");
      onPageChange({ page: 1, perPageLimit });
    }

    return totalPagesCount;
  }, [dataSize, perPageLimit]);

  if (currentPage === null || totalPages === 0) {
    return null;
  }

  return (
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onChange={onPageChange}
    />
  );
};

export default PaginationWrapper;
