import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Tabs, { TabPane } from 'rc-tabs';
import classNames from 'classnames';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import * as Icons from '../../../../shared/svg';
import PeopleTabComponent from '../people-tab';
import {
  FilterComponentType,
  LeadFinderTabKey,
  SavedTabFilters,
} from '../../type';
import SavedTab from '../saved-tab/saved-tab';
import { useSavedLeadFilter } from '../hooks/useLeadSavedFilter';
import LeadsFilterBulkSearch from '../leads-filter-bulk-search/leads-filter-bulk-search';
import { BulkFilters } from '../leads-filter-bulk-search/type';
import { useLeadFilter } from '../hooks/useLeadFilter';

interface LeadsSidebarProps {
  isSidebarExpanded: any;
  setIsSidebarExpanded: any;
  handleRecentSearch: any;
  handleGeneratePayload: any;
  activeTabKey: string;
  setActiveTabKey: (d: string) => void;
  handlePagination: (d?: any) => void;
  // eslint-disable-next-line react/require-default-props
  setLeadsData?: any;
  setSelectedLeads: (d?: any) => void;
  handleRouteChange: (d?: any) => void;
  leadTags: any[];
  isLeadTagsLoading: boolean;
  fetchLeadTags: (applyLoading?: boolean) => void;
  handleResetStateAfterAnyAction: () => void;
  savedFilters: SavedTabFilters;
  setSavedFilters: React.Dispatch<React.SetStateAction<SavedTabFilters>>;
}

const LeadsSidebar = (props: LeadsSidebarProps) => {
  const {
    isSidebarExpanded,
    setIsSidebarExpanded,
    handleRecentSearch,
    handleGeneratePayload,
    activeTabKey,
    setActiveTabKey,
    setLeadsData,
    setSelectedLeads,
    handleRouteChange,
    fetchLeadTags,
    leadTags,
    isLeadTagsLoading,
    handleResetStateAfterAnyAction,
    savedFilters,
    setSavedFilters,
  } = props;

  const history = useHistory();

  const [showBulkSearch, setShowBulkSearch] = useState(false);
  const [bulkFilterSyncKey, setBulkFilterSyncKey] = useState<
    Record<string, boolean>
  >({});
  const { handleDefaultValues } = useSavedLeadFilter(activeTabKey);

  const {
    handleDeleteFilter,
    handleGetAllKeysByFilterFromQuery,
    handleIndividualTag,
    handleFoundValueExceptFromList,
    handleQuery,
    handleBulkQuery,
  } = useLeadFilter(activeTabKey);

  const onSidebarExpansionChange = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleTabChange = (key) => {
    if (key !== activeTabKey) {
      setLeadsData({
        profiles: [],
        pagination: null,
      });
      handleResetStateAfterAnyAction();
      setActiveTabKey(key);
      handleRouteChange();
      setSavedFilters(handleDefaultValues(true));
      const queryParams = new URLSearchParams();
      if (key === LeadFinderTabKey.SAVED_TAB) {
        queryParams.set('start', '1');
        queryParams.set('take', '25');
      }
      if (key === LeadFinderTabKey.PEOPLE_TAB) {
        queryParams.delete('start');
        queryParams.delete('take');
      }
      history.push({ search: queryParams.toString(), hash: key });
    }
  };

  const onBulkSearchShow = () => {
    setShowBulkSearch(true);
  };
  const onBulkSearch = (bulkFilters: BulkFilters) => {
    setShowBulkSearch(false);

    const bulkQuery = [];
    const newBulkFilterSyncKeys: Record<string, boolean> = {};
    Object.keys(bulkFilters).forEach((bulkFilterKey) => {
      const bulkFilter = bulkFilters[bulkFilterKey];
      bulkQuery.push({
        filterKey: bulkFilterKey,
        filterValue: {
          includedKeys: bulkFilter.values,
        },
        filterType: FilterComponentType.MULTILEVEL_CHECKBOX, // This is static since only this type needs bulk search
        showExactMatch: bulkFilter.showExactMatch,
      });
      if(bulkFilter?.values?.length) {
        newBulkFilterSyncKeys[bulkFilterKey] = true;
      }
    });
    handleBulkQuery(bulkQuery);

    setTimeout(() => setBulkFilterSyncKey(newBulkFilterSyncKeys), 0);
  };

  const sideNavClassName = classNames([
    'leads-sidebar',
    'leadsSideNav',
    {
      'sidenav--collapse': !isSidebarExpanded,
    },
  ]);

  return (
    <div className={`leads-navigation ${sideNavClassName}`}>
      <LeadsFilterBulkSearch
        show={showBulkSearch}
        onClose={() => setShowBulkSearch(false)}
        onSearch={onBulkSearch}
      />
      <div
        className="expand-btn"
        {...accessibleOnClick(onSidebarExpansionChange)}
      >
        {isSidebarExpanded ? (
          <Icons.ChevronLeft width={17} height={17} className="gray-txt-15" />
        ) : (
          <Icons.ChevronRight width={17} height={17} className="gray-txt-15" />
        )}
      </div>
      <div className="leads-tabbing">
        {isSidebarExpanded && (
          <Tabs
            defaultActiveKey={LeadFinderTabKey.PEOPLE_TAB}
            activeKey={activeTabKey}
            onChange={(key) => handleTabChange(key)}
            style={{ zIndex: 1000 }}
            prefixCls="bs-tabs"
            className="bs-tabs-small"
          >
            <TabPane tab="People" key={LeadFinderTabKey.PEOPLE_TAB}>
              <PeopleTabComponent
                {...{
                  handleRecentSearch,
                  handleGeneratePayload,
                  setSelectedLeads,
                  onBulkSearchShow,
                  handleDeleteFilter,
                  handleGetAllKeysByFilterFromQuery,
                  handleIndividualTag,
                  handleFoundValueExceptFromList,
                  handleQuery,
                  bulkFilterSyncKey,
                }}
              />
            </TabPane>
            <TabPane tab="Saved" key={LeadFinderTabKey.SAVED_TAB}>
              <SavedTab
                {...{
                  leadTags,
                  isLeadTagsLoading,
                  fetchLeadTags,
                  activeTabKey,
                  savedFilters,
                  setSavedFilters,
                }}
              />
            </TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default LeadsSidebar;
