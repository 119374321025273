import { CallPurpose, SequenceStepType, StepType } from '../../../../enums';

export enum LinkedInTaskType {
  ConnectionRequest = 'Connection Request',
  Message = 'Message',
  InMail = 'InMail',
  ViewProfile = 'View Profile',
  PostInteraction = 'Post Interaction',
}

export enum PurposeOfCall {
  Introduction = 'Introduction',
  Demo = 'Demo',
  FollowUp = 'Follow-up',
  Reminder = 'Reminder',
  Other = 'Other',
}

export const contentMaxLength = {
  [LinkedInTaskType.ConnectionRequest]: 300,
  [LinkedInTaskType.Message]: 8000,
  [LinkedInTaskType.InMail]: 1900,
  [LinkedInTaskType.ViewProfile]: 3000,
  [LinkedInTaskType.PostInteraction]: 3000,
  [StepType.Call]: 3000,
  [StepType.WhatsApp]: 4096,
  [StepType.Task]: 3000,
};

export const subjectInputMaxLength = (stepType) =>
  stepType === StepType.Task ? 100 : 200;

export const isSubTypeSectionVisible = (stepType: StepType) =>
  stepType === StepType.LinkedIn || stepType === StepType.Call;

export const getStepSubType = (stepType: StepType) => {
  if (stepType === StepType.Call) {
    return PurposeOfCall;
  }

  return LinkedInTaskType;
};

export const getCallPurposeValue = (value: number): string | undefined => {
  const keys = Object.keys(CallPurpose).filter(
    (key) => CallPurpose[key as keyof typeof CallPurpose] === value,
  );
  return keys.length > 0 ? keys[0] : undefined;
};

export const getCallPurposeId = (purpose): number => {
  if (CallPurpose[purpose]) {
    return Number(CallPurpose[purpose]);
  }

  return CallPurpose.FollowUp;
};

export const getCallPurposeTxt = (purposeId) => {
  const keys = Object.keys(CallPurpose).filter(
    (key) => (CallPurpose as any)[key] === purposeId,
  );

  if (keys[0] === 'FollowUp') {
    return 'Follow-up';
  }

  return keys.length > 0 ? keys[0] : undefined;
};

export const getInitialSubType = (initialSubType: SequenceStepType) => {
  if (initialSubType === SequenceStepType.LinkedInConnectionRequest) {
    return LinkedInTaskType.ConnectionRequest;
  }
  if (initialSubType === SequenceStepType.LinkedInMessage) {
    return LinkedInTaskType.Message;
  }
  if (initialSubType === SequenceStepType.LinkedInInMail) {
    return LinkedInTaskType.InMail;
  }
  if (initialSubType === SequenceStepType.LinkedInPostInteraction) {
    return LinkedInTaskType.PostInteraction;
  }
  if (initialSubType === SequenceStepType.LinkedInViewProfile) {
    return LinkedInTaskType.ViewProfile;
  }

  return PurposeOfCall.Introduction;
};

export const getSubTypeSectionLabel = (stepType: StepType) => {
  if (stepType === StepType.Call) {
    return 'Purpose of the Call';
  }

  return 'LinkedIn Task Type';
};

export const getStepContentSectionLabel = (type: SequenceStepType) => {
  if (type === SequenceStepType.LinkedInConnectionRequest) {
    return 'LinkedIn Connection Note';
  }
  if (type === SequenceStepType.LinkedInMessage) {
    return 'LinkedIn Message';
  }
  if (type === SequenceStepType.LinkedInInMail) {
    return 'InMail Message';
  }
  if (type === SequenceStepType.Call) {
    return 'Call Task Note';
  }
  if (type === SequenceStepType.Whatsapp) {
    return 'WhatsApp Message';
  }

  return 'Task Note';
};

export const getLinkedInType = (type: LinkedInTaskType) => {
  if (type === LinkedInTaskType.ConnectionRequest) {
    return SequenceStepType.LinkedInConnectionRequest;
  }
  if (type === LinkedInTaskType.Message) {
    return SequenceStepType.LinkedInMessage;
  }
  if (type === LinkedInTaskType.InMail) {
    return SequenceStepType.LinkedInInMail;
  }
  if (type === LinkedInTaskType.ViewProfile) {
    return SequenceStepType.LinkedInViewProfile;
  }

  return SequenceStepType.LinkedInPostInteraction;
};

export const getStepType = (type: SequenceStepType) => {
  if (
    type === SequenceStepType.LinkedInConnectionRequest ||
    type === SequenceStepType.LinkedInMessage ||
    type === SequenceStepType.LinkedInInMail ||
    type === SequenceStepType.LinkedInViewProfile ||
    type === SequenceStepType.LinkedInPostInteraction
  ) {
    return StepType.LinkedIn;
  }
  if (type === SequenceStepType.Whatsapp) {
    return StepType.WhatsApp;
  }
  if (type === SequenceStepType.Call) {
    return StepType.Call;
  }
  if (type === SequenceStepType.Custom) {
    return StepType.Task;
  }

  return StepType.Email;
};

export const getDaysAndStepsObj = (steps) => {
  let absoluteExecutionDay = 1;
  const resultObject = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const item of steps) {
    absoluteExecutionDay += item.relativeDays;
    resultObject[absoluteExecutionDay] = item.number;
  }

  return resultObject;
};

export const getSequenceTypeTooltipText = (type: SequenceStepType) => {
  if (type === SequenceStepType.LinkedInConnectionRequest) {
    return 'Personalized message to send with LinkedIn connection request for introduction and networking.';
  }
  if (type === SequenceStepType.LinkedInMessage) {
    return 'Personalized message you can send to the people in your network.';
  }
  if (type === SequenceStepType.LinkedInInMail) {
    return 'Write personalized InMail message';
  }

  if (type === SequenceStepType.Call) {
    return 'Add instructions/tips for task assignee to execute this task successfully.';
  }
  if (type === SequenceStepType.Whatsapp) {
    return 'Personalized whatsapp message.';
  }

  return 'Add instructions/tips for task assignee to execute this task successfully.';
};

export const isFormValid = (errors) =>
  !Object.keys(errors).some((k) => errors[k]);

export const isSubmitDisabled = (type, values) => {
  if (type === SequenceStepType.LinkedInMessage) {
    return values?.content.length < 2;
  }
  if (type === SequenceStepType.LinkedInInMail) {
    return values?.content.length < 2 || values?.subject?.length < 2;
  }

  return false;
};

export const getErrorString = (isError, value, maxLength, minLength = 2) => {
  if (!isError) {
    return '';
  }

  if (value?.trim()?.length === 0) {
    return 'Please enter a valid input. Blank spaces are not allowed.';
  }

  if (value.length < minLength) {
    return `Mandatory Field: At least ${minLength} characters needed`;
  }

  if(value.length > maxLength) {
    return `Character limit exceeds ${maxLength}`;
  }
  return '';
};

export const getStepInitialTaskNote = (steps, actionStep) => {
  const currentStep = steps.filter((step) => step.id === actionStep?.id);

  if (currentStep?.length) {
    const [{ variants }] = currentStep;
    const [{ taskNote }] = variants;

    return taskNote;
  }

  return '';
};

export const getIsAnyFieldDirty = (obj) =>
  Object.values(obj).some((value) => value === true);

export const getConfirmationModalContent = () => [
  {
    id: 1,
    text: 'confirmation_modal_content_2',
  },
];

export const getMinLength = (stepType, selectedStepType) => {
  if (
    selectedStepType === SequenceStepType.LinkedInConnectionRequest ||
    stepType === StepType.Task ||
    stepType === StepType.Call
  ) {
    return 0;
  }

  return 2;
};

export const getPlaceHolderText = (selectedStepSubType, stepType) => {
  if (
    selectedStepSubType === LinkedInTaskType.Message ||
    selectedStepSubType === LinkedInTaskType.InMail
  ) {
    return 'Add a concise purpose to your connection.';
  }
  if (
    selectedStepSubType === LinkedInTaskType.ViewProfile ||
    selectedStepSubType === LinkedInTaskType.PostInteraction
  ) {
    return 'Give details to a person who will perform this task, so as not to forget anything.';
  }

  if (stepType === StepType.Call || stepType === StepType.Task) {
    return 'Give details to a person who will perform this task, so as not to forget anything.';
  }

  return 'Briefly explain your reason for connecting.';
};

export const getIsExpandOptionVisible = (stepType, selectedStepSubType) => {
  if (
    stepType === StepType.Task ||
    selectedStepSubType === SequenceStepType.LinkedInInMail
  ) {
    return true;
  }

  return false;
};

export const getContentAreaSize = (stepType, selectedStepSubType) => {
  if (selectedStepSubType === SequenceStepType.LinkedInInMail) {
    return 'sm';
  }
  if (
    stepType === StepType.Call ||
    selectedStepSubType === SequenceStepType.LinkedInPostInteraction ||
    selectedStepSubType === SequenceStepType.LinkedInViewProfile
  ) {
    return 'md';
  }
  if (stepType === StepType.Task) {
    return 'lg';
  }

  return 'xm';
};

export const getIsTaskNoteContent = (selectedStepType) => {
  if (
    selectedStepType === SequenceStepType.LinkedInPostInteraction ||
    selectedStepType === SequenceStepType.LinkedInViewProfile ||
    selectedStepType === SequenceStepType.Call ||
    selectedStepType === SequenceStepType.Custom
  ) {
    return true;
  }

  return false;
};

export const getTaskNoteModalTitle = (selectedStepType) => {
  if (selectedStepType === SequenceStepType.LinkedInInMail) {
    return 'InMail Message';
  }

  return 'Task Note';
};

export const getTaskNoteModalMaxLength = (selectedStepType) => {
  if (selectedStepType === SequenceStepType.LinkedInInMail) {
    return 1900;
  }

  return 3000;
};
