/* eslint-disable @typescript-eslint/no-throw-literal */
import classNames from 'classnames';
import { ProspectType } from '../../../../settings/enums/prospect-type';
import {
  SubjectEmailSuggestion,
  EmailSuggestionStatus,
  PersonalizationEmailSuggestion,
  LinkEmailSuggestion,
  SpammyEmailSuggestion,
  WordEmailSuggestion,
} from './types';
import { Sequence } from '../../../types';
import SpammyWords from '../../../../../shared/utils/spammy-word-list';
// eslint-disable-next-line import/no-cycle
import { EmailContentSizeAllowed } from '../../../../../shared/utils';
import {
  AttachmentType,
  AttachmentUploadStatus,
} from '../../../../../shared/editor/types';
import { SequenceStepType } from '../../../enums';

export const getTestEmailAddressFromLocalStorage = () =>
  localStorage.getItem('test_email_address');

export const saveTestEmailAddressInLocalStorage = (value: string) =>
  localStorage.setItem('test_email_address', value);

export const clearTestEmailAddressFromLocalStorage = () =>
  localStorage.removeItem('test_email_address');

export const getTestEmaiContent = () => [
  {
    id: 1,
    text: 'test_email_content',
  },
];

export const getEmailAccountDisconnectedContent = () => [
  {
    id: 1,
    text: 'email_account_disconnected_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getEmailAccountNotConnectedContent = () => [
  {
    id: 1,
    text: 'email_account_not_connected_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getEmailNotAddedModalContent = () => [
  {
    id: 1,
    text: 'email_not_added_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getConfirmationModalContent = () => [
  {
    id: 1,
    text: 'confirmation_modal_content',
  },
];

export const prepareMergeTagOptions = (orderedFields) =>
  orderedFields.map((item) => {
    let tag = '';
    let fallbackText = '';
    let label = `{{${item.label}}}`;

    if (item.fallbackText !== null) {
      fallbackText =
        item.fallbackText.length > 0 ? `|'${item.fallbackText}'` : '';
    }

    if (item.prospectType === ProspectType.Account) {
      label = `{{account.${item.label}}}`;
      tag = `{{account.${item.label}${fallbackText}}}`;
    } else {
      tag = `{{${item.label}${fallbackText}}}`;
    }

    return {
      key: `${item.prospectType}-${item.id}`,
      type: item.prospectType,
      tag,
      label,
    };
  });

export const isButtonDisabled = (errors, attachments: AttachmentType[]) =>
  errors.content !== '' ||
  errors.subject !== '' ||
  errors.preheader !== '' ||
  attachments.some(
    (item) =>
      item.status === AttachmentUploadStatus.Uploading ||
      item.status === AttachmentUploadStatus.UploadFailed ||
      item.status === AttachmentUploadStatus.Deleting,
  );

export const isSubmitDisabled = (
  isRequestPending,
  isSaveDisabled,
  errors,
  attachments: AttachmentType[],
) =>
  isRequestPending || isSaveDisabled || isButtonDisabled(errors, attachments);

export const footerContent = (footerPart) => footerPart && footerPart();
export const getPrevStepSubject = (
  sequence: Sequence,
  currentStepNumber: number,
) => {
  let prevStepSubject = '';
  let subjectToSelect: string[];
  let stepIndex = currentStepNumber - 2;
  while (!prevStepSubject && stepIndex >= 0) {
    const prevStep = sequence.steps?.filter(
      (step) => step.type === SequenceStepType.Email,
    )?.[stepIndex];
    if (prevStep && prevStep.variants) {
      subjectToSelect = prevStep.variants
        .filter(
          (variant) =>
            variant.status === 1 &&
            variant.payload?.subject !== undefined &&
            variant.payload?.subject !== '',
        )
        .map((variant) => variant.payload?.subject);
      prevStepSubject = subjectToSelect?.[0];
    }
    stepIndex -= 1;
  }
  return prevStepSubject;
};

/**
 *
 * @param text string text
 * @returns normalized string value removing unnecessary space between \n
 */
const normalizeTextWithNewlines = (text: string): string => {
  const parseText = text || '';
  return parseText
    .replace(/[ ]*\n[ ]*/g, '\n') // Remove spaces around each newline
    .replace(/[ ]+/g, ' ') // Collapse multiple spaces into one
    .trim(); // Trim leading/trailing spaces
};

/**
 * @param text - text to check if it contains spintax
 * @returns boolean flag
 */
export const textContainsSpinTag = (text = ''): boolean => {
  const paseText = text || '';
  return paseText.includes('{spin}') && paseText.includes('{endspin}');
};

/**
 * @param text - text to split by | value
 * @returns Array of Strings
 */
export const splitSpinContainingTextByPipe = (text = ''): string[] => {
  // Regular expression to match "parent level" | (pipe) characters, ignoring ones inside {spin}...{endspin}
  const regex = /\|(?=(?:[^{}]*{spin}[^{}]*{endspin})*[^{}]*$)/g;

  // Split the string using the above regular expression
  const result = text.split(regex);

  return result;
};

/**
 * @param text - text split into words and get their length
 * @returns Length of array
 */
export const getWordCountFromString = (text = ''): number => {
  // Match the text between {{ }} as a single "word" Considering merge & variable tag a single word count
  const modifiedText =
    text?.trim()?.replace(/{{.*?}}/g, (match) => `{{${match.slice(2, -2)}}}`) ||
    '';

  return modifiedText?.split(' ')?.filter((i) => i.trim())?.length || 0;
};

/**
 * @param text - spintax string to process
 * @returns Array of objects where object contains type, value, valueContainsSpinText and options
 */
export const processSpinText = (
  text = '',
): {
  type: string;
  value: string;
  valueContainsSpinText: boolean;
  valueOptions?: string[];
}[] => {
  let i = 0;
  const result = [];
  const stack = [];
  let buffer = '';

  while (i < text.length) {
    if (text.slice(i, i + 6) === '{spin}') {
      if (buffer) {
        // Adding any buffer text before spin text
        const isBufferContainesSpinText = textContainsSpinTag(buffer);

        result.push({
          type: 'text',
          value: buffer,
          valueContainsSpinText: isBufferContainesSpinText,
        });
        buffer = '';
      }

      stack.push(i); // Store the index of found spin tag
      i += 6;
    } else if (text.slice(i, i + 9) === '{endspin}') {
      if (stack.length === 0) {
        const message = new Error('Unbalanced Spin Text');
        const status = 'error';
        const errObj = { status, message };
        throw errObj;
      }
      // ** Popping till last element in stack
      const start = stack.pop();
      if (stack.length === 0) {
        const spinContent = text.slice(start + 6, i);
        result.push({
          type: 'spin',
          value: spinContent,
          valueContainsSpinText: textContainsSpinTag(spinContent),
          valueOptions: splitSpinContainingTextByPipe(spinContent),
        });
        // clearing middle buffer values
        buffer = '';
      }

      i += 9;
    } else {
      // Adding any buffer text after spin text end (after {endspin})
      if (stack.length === 0) {
        buffer += text[i];
      }
      i += 1;
    }
  }

  if (stack.length > 0) {
    const message = new Error('Unbalanced {spin}/{endspin} blocks!');
    const status = 'error';
    const errObj = { status, message };
    throw errObj;
  }

  if (buffer) {
    result.push({
      type: 'text',
      value: buffer,
      valueContainsSpinText: textContainsSpinTag(buffer),
    });
  }

  return result;
};

/**
 * @param spinText - String value containing spintext
 * @returns Max word count of spin text
 */
export const getMaximumWordCountFromSpinText = (spinText = '') => {
  let maximumWordCount = 0;
  if (spinText) {
    const spinTextOptions = processSpinText(spinText || '');

    spinTextOptions.forEach((option) => {
      if (option?.type === 'spin') {
        if (option?.valueContainsSpinText) {
          let maxChFromSubOptions = 0;
          const allWordCount = option?.valueOptions.map((i) =>
            getMaximumWordCountFromSpinText(i || ''),
          );
          maxChFromSubOptions =
            allWordCount?.reduce((max, current) => Math.max(max, current), 0) ||
            0;
          maximumWordCount += maxChFromSubOptions;
        } else {
          const maxLengthFromOptions =
            option?.valueOptions?.reduce(
              (max, current) =>
                Math.max(max, getMaximumWordCountFromSpinText(current || '')),
              0,
            ) || 0;
          maximumWordCount += maxLengthFromOptions;
        }
      }

      if (option?.type === 'text') {
        if (option?.valueContainsSpinText) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const maxWordCountFromSpinText = getMaximumWordCountFromSpinText(
            option?.value || '',
          );
          maximumWordCount += maxWordCountFromSpinText;
        } else {
          maximumWordCount += getWordCountFromString(option?.value || '');
        }
      }
    });
  }
  return maximumWordCount;
};

/**
 * @param spinText - String value containing spintext
 * @returns Max Character count of spin text
 */
export const getMaximumCharacterCountFromSpinText = (spinText = '') => {
  let maximumCharacterCount = 0;
  if (spinText) {
    const spinTextOptions = processSpinText(spinText || '');
    spinTextOptions.forEach((option) => {
      if (option?.type === 'spin') {
        if (option?.valueContainsSpinText) {
          let maxChFromSubOptions = 0;
          const allChCount = option?.valueOptions.map((i) =>
            getMaximumCharacterCountFromSpinText(i),
          );
          maxChFromSubOptions =
            allChCount?.reduce((max, current) => Math.max(max, current), 0) ||
            0;
          maximumCharacterCount += maxChFromSubOptions;
        } else {
          const maxLengthFromOptions =
            option?.valueOptions?.reduce(
              (max, current) => Math.max(max, current?.length || 0),
              0,
            ) || 0;
          maximumCharacterCount += maxLengthFromOptions;
        }
      }

      if (option?.type === 'text') {
        if (option?.valueContainsSpinText) {
          maximumCharacterCount += getMaximumCharacterCountFromSpinText(
            option?.value || '',
          );
        } else {
          maximumCharacterCount += option?.value?.length || 0;
        }
      }
    });
  }
  return maximumCharacterCount;
};

export const checkSubjectEmailSuggestions = (
  subject: string,
): SubjectEmailSuggestion => {
  let length = 0;
  // Remove Emoji from subject string
  const subjectWithoutEmojis = subject.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    '',
  );
  try {
    // Try block : Get max character length

    // Below sanitizing tags with $---$ to avoid regex errors & fixing the tag character count to 5
    const tagsSanitizedSubject =
      subjectWithoutEmojis?.replace(/{{(.*?)}}/g, '$---$') || '';

    const maxLength = getMaximumCharacterCountFromSpinText(
      tagsSanitizedSubject,
    );
    length = maxLength;
  } catch (error) {
    // Catch block : Get max character length fallback incase of invalid spintext
    const { length: newLength } = subjectWithoutEmojis;
    length = newLength;
  }

  if (length >= 1 && length <= 10) {
    return {
      subjectPercentage: 25,
      subjectStatus: EmailSuggestionStatus.TOO_SHORT,
      subjectLength: length,
    };
  }
  if (length >= 11 && length <= 30) {
    return {
      subjectPercentage: 50,
      subjectStatus: EmailSuggestionStatus.SHORT,
      subjectLength: length,
    };
  }
  if (length >= 31 && length <= 60) {
    return {
      subjectPercentage: 100,
      subjectStatus: EmailSuggestionStatus.IDEAL,
      subjectLength: length,
    };
  }
  if (length >= 61 && length <= 70) {
    return {
      subjectPercentage: 50,
      subjectStatus: EmailSuggestionStatus.LENGTHY,
      subjectLength: length,
    };
  }
  if (length >= 71) {
    return {
      subjectPercentage: 25,
      subjectStatus: EmailSuggestionStatus.TOO_LENGTHY,
      subjectLength: length,
    };
  }
  return {
    subjectPercentage: 0,
    subjectStatus: '',
    subjectLength: length,
  };
};

export const checkWordCountSuggestions = (
  content: string,
  stepNumber: number,
): WordEmailSuggestion => {
  // Count the number of words in the expressions
  let wordCount = 0;

  // Regular expression to match expressions like {{ merge tag }} or {spin} some text 1 | some text 2 {endspin}
  const expressionRegex = /\{\{[^{}]*\}\}|\{spin\}[^{}]*\{endspin\}/g;

  // Remove HTML tags from the content
  const stringWithoutHTML = content.replace(/<\/?[^>]+(>|$)/g, '');

  try {
    const normalizedStringWithoutHTML = normalizeTextWithNewlines(
      stringWithoutHTML?.trim() || '',
    );

    wordCount = getMaximumWordCountFromSpinText(normalizedStringWithoutHTML);
  } catch (error) {
    // Extract expressions from the string
    const expressions = stringWithoutHTML.match(expressionRegex);

    if (expressions) {
      expressions.forEach((expression) => {
        // Treat the entire expression as a single word
        wordCount += expression ? 1 : 0;
      });
    }

    // Count the number of words in the remaining text
    const remainingText = stringWithoutHTML.replace(expressionRegex, '');
    const remainingWords = remainingText
      .split(/\s+/)
      .filter((word) => word !== '');

    // Add the count of words in the remaining text to the total word count
    wordCount += remainingWords.length;
  }

  if (stepNumber === 1) {
    if (wordCount >= 1 && wordCount < 50) {
      return {
        wordPercentage: 25,
        wordStatus: EmailSuggestionStatus.TOO_SHORT,
        wordLength: wordCount,
      };
    }
    if (wordCount >= 50 && wordCount <= 200) {
      return {
        wordPercentage: 100,
        wordStatus: EmailSuggestionStatus.IDEAL,
        wordLength: wordCount,
      };
    }
    if (wordCount >= 201 && wordCount <= 300) {
      return {
        wordPercentage: 50,
        wordStatus: EmailSuggestionStatus.LENGTHY,
        wordLength: wordCount,
      };
    }
    if (wordCount >= 301) {
      return {
        wordPercentage: 25,
        wordStatus: EmailSuggestionStatus.TOO_LENGTHY,
        wordLength: wordCount,
      };
    }
  }

  if (wordCount >= 1 && wordCount < 25) {
    return {
      wordPercentage: 25,
      wordStatus: EmailSuggestionStatus.TOO_SHORT,
      wordLength: wordCount,
    };
  }
  if (wordCount >= 25 && wordCount <= 149) {
    return {
      wordPercentage: 100,
      wordStatus: EmailSuggestionStatus.IDEAL,
      wordLength: wordCount,
    };
  }
  if (wordCount >= 150 && wordCount <= 200) {
    return {
      wordPercentage: 50,
      wordStatus: EmailSuggestionStatus.LENGTHY,
      wordLength: wordCount,
    };
  }
  if (wordCount >= 201) {
    return {
      wordPercentage: 25,
      wordStatus: EmailSuggestionStatus.TOO_LENGTHY,
      wordLength: wordCount,
    };
  }

  return {
    wordPercentage: 0,
    wordStatus: '',
    wordLength: wordCount,
  };
};

export const checkPersonalizationEmailSuggestions = (
  content: string,
  mergeTagOptions,
): PersonalizationEmailSuggestion => {
  // This will return all matching merge tags which has opened with {{ and closed with }}
  const ptrn = /{{([^}}]+)}}/g;
  const mergeTags = content.match(ptrn);

  if (mergeTags === null || mergeTags.length === 0) {
    return {
      personalizationPercentage: 25,
      personalizationStatus: EmailSuggestionStatus.POOR,
      personalizationLength: 0,
    };
  }

  const tags = [];
  mergeTagOptions.forEach((field) => {
    tags.push(field.tag);
  });

  const filteredMergeTags = [];
  mergeTags.forEach((tag) => {
    if (tags.includes(tag)) {
      filteredMergeTags.push(tag);
    }
  });

  const { length } = filteredMergeTags;

  if (length === 1) {
    return {
      personalizationPercentage: 50,
      personalizationStatus: EmailSuggestionStatus.AVERAGE,
      personalizationLength: length,
    };
  }
  if (length === 2) {
    return {
      personalizationPercentage: 100,
      personalizationStatus: EmailSuggestionStatus.GOOD,
      personalizationLength: length,
    };
  }
  if (length >= 3) {
    return {
      personalizationPercentage: 100,
      personalizationStatus: EmailSuggestionStatus.EXCELLENT,
      personalizationLength: length,
    };
  }

  return {
    personalizationPercentage: 0,
    personalizationStatus: '',
    personalizationLength: 0,
  };
};

export const checkLinksEmailSuggestions = (
  content: string,
): LinkEmailSuggestion => {
  const container = document.createElement('p');
  container.innerHTML = content;

  const anchors = container.getElementsByTagName('a');
  const links = [];
  const keys = Object.keys(anchors);
  keys.forEach((key) => {
    links.push(anchors[key].href);
  });
  container.remove();

  const { length } = links;

  if (length === 0) {
    return {
      linkPercentage: 100,
      linkStatus: EmailSuggestionStatus.EXCELLENT,
      linkLength: 0,
    };
  }

  if (length === 1) {
    return {
      linkPercentage: 100,
      linkStatus: EmailSuggestionStatus.GOOD,
      linkLength: 1,
    };
  }
  if (length >= 2 && length <= 3) {
    return {
      linkPercentage: 50,
      linkStatus: EmailSuggestionStatus.AVERAGE,
      linkLength: length,
    };
  }
  if (length >= 4) {
    return {
      linkPercentage: 25,
      linkStatus: EmailSuggestionStatus.POOR,
      linkLength: length,
    };
  }

  return {
    linkPercentage: 0,
    linkStatus: '',
    linkLength: 0,
  };
};

export const checkSpammyEmailSuggestions = (
  content: string,
): SpammyEmailSuggestion => {
  const spammyWordsInContent = [];

  SpammyWords.forEach((word) => {
    const regx = new RegExp(`\\b${word}\\b`, 'gi');
    if (regx.test(content)) {
      spammyWordsInContent.push(word);
    }
  });

  const { length } = spammyWordsInContent;

  if (length === 0) {
    return {
      spammyPercentage: 100,
      spammyStatus: EmailSuggestionStatus.EXCELLENT,
      spammyLength: 0,
      spammyWords: [],
    };
  }

  if (length === 1) {
    return {
      spammyPercentage: 100,
      spammyStatus: EmailSuggestionStatus.GOOD,
      spammyLength: 1,
      spammyWords: spammyWordsInContent,
    };
  }

  if (length >= 2 && length <= 5) {
    return {
      spammyPercentage: 50,
      spammyStatus: EmailSuggestionStatus.AVERAGE,
      spammyLength: length,
      spammyWords: spammyWordsInContent,
    };
  }

  if (length >= 6) {
    return {
      spammyPercentage: 25,
      spammyStatus: EmailSuggestionStatus.POOR,
      spammyLength: length,
      spammyWords: spammyWordsInContent,
    };
  }

  return {
    spammyPercentage: 0,
    spammyStatus: '',
    spammyLength: 0,
    spammyWords: [],
  };
};

export const checkContentEmailSuggestions = ({
  content,
  doNotCheckLink = false,
  mergeTagOptions = [],
  stepNumber,
}: {
  content: string;
  doNotCheckLink?: boolean;
  mergeTagOptions: any;
  stepNumber: number;
}): {
  wordCount: WordEmailSuggestion;
  personalization: PersonalizationEmailSuggestion;
  link: LinkEmailSuggestion;
  spammy: SpammyEmailSuggestion;
} => {
  // Word Count Suggestion
  const { wordPercentage, wordStatus, wordLength } = checkWordCountSuggestions(
    content,
    stepNumber,
  );

  // Personalization Email Suggestion
  const {
    personalizationPercentage,
    personalizationStatus,
    personalizationLength,
  } = checkPersonalizationEmailSuggestions(content, mergeTagOptions);

  // Links Email Suggestion
  let linkObj = {
    linkPercentage: 0,
    linkStatus: '',
    linkLength: 0,
  };

  if (!doNotCheckLink) {
    const {
      linkPercentage,
      linkStatus,
      linkLength,
    } = checkLinksEmailSuggestions(content);
    linkObj = {
      linkPercentage,
      linkStatus,
      linkLength,
    };
  }

  const {
    spammyPercentage,
    spammyStatus,
    spammyLength,
    spammyWords,
  } = checkSpammyEmailSuggestions(content);

  return {
    wordCount: {
      wordPercentage,
      wordStatus,
      wordLength,
    },
    personalization: {
      personalizationPercentage,
      personalizationStatus,
      personalizationLength,
    },
    link: linkObj,
    spammy: {
      spammyPercentage,
      spammyStatus,
      spammyLength,
      spammyWords,
    },
  };
};

export const getEditorClassNames = ({
  showTitle,
  showPreheaderInput,
  values,
  errors,
}): string =>
  classNames([
    'email-modal--editor',
    {
      'with-two-inputs': showTitle || showPreheaderInput,
      'with-one-inputs-one-error':
        !showTitle &&
        !showPreheaderInput &&
        (errors.title ||
          errors.preheader ||
          values.preheader.length > 0 ||
          errors.subject),
      'with-two-inputs-one-error':
        (errors.title ||
          errors.preheader ||
          values.preheader.length > 0 ||
          errors.subject) &&
        (showTitle || showPreheaderInput),
      'with-two-errors':
        (errors.title || errors.preheader || values.preheader.length > 0) &&
        errors.subject,
    },
  ]);

export const getStringSize = (content: string) =>
  Buffer.byteLength(content, 'utf-8');

export const checkTotalEmailContentSizeValidator = ({
  content,
  attachmentsSizeInBytes,
}): boolean => {
  const contentSizeInBytes = getStringSize(content);

  const totalEmailSize = contentSizeInBytes + attachmentsSizeInBytes;

  return totalEmailSize > EmailContentSizeAllowed;
};

export const getAttachmentsSize = (attachments) => {
  let attachmentsSizeInBytes = 0;

  if (attachments && attachments.length) {
    attachments.forEach((item) => {
      attachmentsSizeInBytes += parseInt(item.file.size, 10);
    });
  }

  return attachmentsSizeInBytes;
};

export const attachmentStatusChangeHandler = (
  attachment: AttachmentType,
  attachments: AttachmentType[],
) => {
  let oldAttachment = null;

  if (attachment.attachmentIdentifier) {
    oldAttachment = attachments.find(
      (item) => item.attachmentIdentifier === attachment.attachmentIdentifier,
    );
  } else if (attachment.attachmentId) {
    oldAttachment = attachments.find(
      (item) => item.attachmentId === attachment.attachmentId,
    );
  }

  if (oldAttachment) {
    if (attachment.attachmentIdentifier) {
      return attachments.map((item) =>
        item.attachmentIdentifier === attachment.attachmentIdentifier
          ? {
              ...oldAttachment,
              ...attachment,
            }
          : item,
      );
    }

    if (attachment.attachmentId) {
      return attachments.map((item) =>
        item.attachmentId === attachment.attachmentId
          ? {
              ...oldAttachment,
              ...attachment,
            }
          : item,
      );
    }
  }
  return attachments;
};

export const getIsHTMLContentDetected = (content: string): boolean => {
  const imageRegex = /<img\b[^>]*alt=['"]([^'"]*)['"][^>]*>/g;
  const restrictedTagRegex = /<(img|span|iframe)[^>]*>/g;
  const styleTagRegex = /style="[^"]*"/g;
  const headerTagsRegex = /<h[1-6]>/g;
  const underlineBoldItalicTagsRegex = /<(u|b|strong|i|em)>/g;
  const closeTagsRegex = /<\/(h[1-6]|u|b|strong|i|em)>/g;
  const strikethroughTagRegex = /<s>/g;
  const linkTagRegex = /<a\s+href="([^"]+)"[^>]*>(.*?)<\/a>/g;

  const regexArray = [
    imageRegex,
    restrictedTagRegex,
    styleTagRegex,
    headerTagsRegex,
    underlineBoldItalicTagsRegex,
    closeTagsRegex,
    strikethroughTagRegex,
    linkTagRegex,
  ];

  return regexArray.some((regex) => regex.test(content));
};

// eslint-disable-next-line no-useless-escape
export const regexForSenderSignatureExistenceCheck = /\{\{\s*SenderSignature\s*([\+\-\*\/]\s*\w+)?\s*\}\}/i;

// eslint-disable-next-line no-useless-escape
export const regexForSenderSignatureCountCheck = /\{\{\s*SenderSignature\s*([\+\-\*\/]\s*\w+)?\s*\}\}/gi;
