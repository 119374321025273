import React from 'react';
import {
  EmailAccount,
  EmailAccountStatus,
} from '../../../../../types/email-account';
import Switch, {
  Size,
} from '../../../../../../../shared/design-system/components/atoms/switch';
import { Placement } from '../../../../../../../shared/design-system/components/overlay';

type IProps = {
  emailAccount: EmailAccount;
  onChangeHandler: (emailAccount: EmailAccount) => void;
};

const EmailAccountSwitch: React.FC<IProps> = ({
  emailAccount,
  onChangeHandler,
}) => {
  const getSwitchValues = () => {
    let x = 'disconnected-status';
    let y = 'Connect';
    let z = false;

    if (emailAccount?.status === EmailAccountStatus.Active) {
      x = 'connected-status';
      y = 'Pause';
      z = true;
    } else if (emailAccount?.status === EmailAccountStatus.Pause) {
      x = 'disconnected-status';
      y = 'Activate';
      z = false;
    }

    return {
      className: x,
      tooltip: y,
      checked: z,
    };
  };

  const { className, tooltip, checked } = getSwitchValues();

  return (
    <Switch
      checked={checked}
      onChange={() => onChangeHandler(emailAccount)}
      size={Size.Small}
      className={className}
      tooltip={tooltip}
      placement={Placement.Bottom}
    />
  );
};

export default EmailAccountSwitch;
