export type SentEmailAttachment = {
  id: number;
  name: string;
  type: string;
  size: string;
};

export type FailedEmailAttachment = {
  id: number;
  name: string;
  type: AttachmentFailedType;
  mimeType: string;
  size: string;
};

export enum AttachmentFailedType {
  FileSizeExceed = '1',
  FileFormatNotSupported = '2',
  InvalidFile = '3',
}

export enum AttachmentFailedTypeReason {
  FileSizeExceed = 'files size is greater than 25MB',
  FileFormatNotSupported = 'files format not supported',
  InvalidFile = 'invalid files',
}

export type SentEmailPreview = {
  senderName: string;
  fromEmail: string;
  to: string[];
  subject?: string;
  sentDate: string;
  preHeader?: string;
  content: string;
  attachments?: SentEmailAttachment[];
  failedAttachments?: FailedEmailAttachment[];
  cc?: string[];
  bcc?: string[];
};

export type IProps = {
  show: boolean;
  onClose: VoidFunction;
  sentEmailPreview: SentEmailPreview;
  userTimezone: string;
};
