import React, { memo } from 'react';
import TagAutoSuggest from '../tag-autosuggest';
import { RenderMode } from '../tag-autosuggest/tag-autosuggest';
import { Images } from '../../../../app-constants';

const TagProspectsContent = ({
  tags,
  tagsToAdd,
  setTagsToAdd,
  placeholder = '',
}) => (
  <div className="review-section-container mt-2">
    <div className="review-section-title-container">
      <img src={Images.Tag} alt="Tag" />
      <span className="review-section-title">Tag prospects</span>
    </div>
    <div className="review-section-tags-container mt-n2">
      <TagAutoSuggest
        renderMode={RenderMode.Inline}
        tags={tags}
        setTagsToAdd={setTagsToAdd}
        tagsToAdd={tagsToAdd}
        placeholder={placeholder}
        checkInputCharacterLength={true}
      />
    </div>
  </div>
);

export default memo(TagProspectsContent);
