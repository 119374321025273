import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Modal from '../../../../shared/design-system/components/atoms/modal';
import { BULK_SEARCH_FILTERS } from './config';
import Checkbox from '../../../../shared/design-system/components/atoms/checkbox';
import { BulkFilters } from './type';
import MultiChipInput from '../../../../shared/components/multi-chip-input';
import {
  getIsErrorFromErrorMapping,
  getMappedKeyFromValue,
  getMappedValueFromKey,
  replaceParentValueIfAllChildExists,
} from './helper/bulk-search-helper';

type IProps = {
  show: boolean;
  onClose: () => void;
  onSearch: (bulkFilters: BulkFilters) => void;
};

const LeadsFilterBulkSearch: React.FC<IProps> = ({
  show,
  onClose,
  onSearch,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);

  const [bulkFilters, setBulkFilters] = useState<BulkFilters>({});
  const [bulkFilterError, setBulkFilterError] = useState<
    Record<string, string>
  >({});

  const isError = getIsErrorFromErrorMapping(bulkFilterError);

  const updateBulkFiltersFromQueryParams = () => {
    const updatedBulkFilters: BulkFilters = {};
    BULK_SEARCH_FILTERS.forEach(({ key, excludeKey, checkboxDataList }) => {
      let queryValues = queryParams.getAll(key);
      let excludeQueryValues: string[];

      if (excludeKey) {
        excludeQueryValues = queryParams.getAll(excludeKey);
      }
      const showExactMatch =
        (queryValues?.length && queryValues[0].includes(`"`)) ||
        (excludeQueryValues?.length && excludeQueryValues[0].includes(`"`));

      queryValues = queryValues.map((value) => value.replace(/"/g, ''));

      if (checkboxDataList) {
        // Considering values with checkboxDataList won't have show exact match
        queryValues = queryValues.map(
          (value) => getMappedValueFromKey(checkboxDataList, value) ?? value,
        );
      }

      updatedBulkFilters[key] = {
        values: queryValues,
        showExactMatch,
      };
    });
    setBulkFilters(updatedBulkFilters);
  };

  useEffect(() => {
    if (show) {
      updateBulkFiltersFromQueryParams();
    }
  }, [show]);

  const onCheckExactMatch = (key: string, checked: boolean) => {
    setBulkFilters({
      ...bulkFilters,
      [key]: {
        ...(bulkFilters[key] ? bulkFilters[key] : { values: [] }),
        showExactMatch: checked,
      },
    });
  };
  const onValueChange = (key: string, value: string[]) => {
    setBulkFilters({
      ...bulkFilters,
      [key]: {
        ...(bulkFilters[key] ? bulkFilters[key] : { showExactMatch: false }),
        values: value,
      },
    });
  };

  const onSubmit = () => {
    const filters = bulkFilters;
    BULK_SEARCH_FILTERS.forEach(({ key, checkboxDataList }) => {
      if (bulkFilters[key] && checkboxDataList) {
        const { values } = filters[key];
        let updatedValues = values.map(
          (value) => getMappedKeyFromValue(checkboxDataList, value) ?? value,
        );
        updatedValues = replaceParentValueIfAllChildExists(
          checkboxDataList,
          updatedValues,
        );
        filters[key] = {
          ...filters[key],
          values: updatedValues,
        };
      }
    });
    onSearch(filters);
  };

  return (
    <Modal
      show={show}
      titleContent="Bulk Search"
      showCloseIcon={true}
      submitButtonText="Search"
      onHide={onClose}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitDisabled={isError}
      className="leads-filter-bulk-search"
    >
      {BULK_SEARCH_FILTERS.map(
        ({
          key,
          icon,
          labelText,
          placeholder,
          showExactMatchCheckbox,
          maxInputCount,
        }) => (
          <div key={key}>
            <div className="search-input-label-container">
              <div className="search-input-label">
                <div className="label-icon">{icon}</div>
                <div className="label">{labelText}</div>
              </div>
              {showExactMatchCheckbox ? (
                <Checkbox
                  value="filterKey"
                  className="show-exact-match-check"
                  onChange={(checked) => onCheckExactMatch(key, checked)}
                  checked={bulkFilters[key]?.showExactMatch}
                  label={
                    <div className="label-container d-inline-block">
                      <p className="checkbox-label-text m-0">
                        Show Exact Match
                      </p>
                    </div>
                  }
                />
              ) : null}
            </div>
            <MultiChipInput
              values={bulkFilters[key]?.values ?? []}
              onChange={(value) => onValueChange(key, value)}
              placeholder={placeholder}
              showInputCount={true}
              maxInputCount={maxInputCount}
              error={bulkFilterError?.[key]}
              onError={(error) => {
                setBulkFilterError((prevError) => ({
                  ...prevError,
                  [key]: error,
                }));
              }}
            />
          </div>
        ),
      )}
    </Modal>
  );
};

export default LeadsFilterBulkSearch;
