import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Pills, SkeletonLoading } from '@saleshandy/design-system';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { DEFAULT_SEPARATORS, ERRORS } from './constants/constants';
import { getSeparatedTextList, getTrimmedChipValue } from './helpers/helpers';
import {
  Placement,
  OverlayTooltip,
} from '../../design-system/components/overlay';

type IProps = {
  values: string[];
  onChange: (values: string[]) => void;
  error: string;
  onError: (error: string) => void;
  separators?: string[];
  placeholder?: string;
  hasMore?: boolean;
  onLoadMore?: () => void;
  loadMoreLabel?: string;
  isLoading?: boolean;
  showInputCount?: boolean;
  maxInputCount?: number;
  maxChipLength?: number;
};
const MultiChipInput: React.FC<IProps> = ({
  values,
  onChange,
  error,
  onError,
  separators = [',', ';', '\n', '\t'],
  placeholder = 'Add entries with comma, semicolon, tabs or line breaks',
  hasMore = false,
  onLoadMore,
  loadMoreLabel = '',
  isLoading = false,
  showInputCount = false,
  maxInputCount,
  maxChipLength = 70,
}) => {
  const multiChipInputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>('');

  const onContainerClicked = () => {
    if (multiChipInputRef?.current) {
      multiChipInputRef.current.focus();
    }
  };

  const onEmailAndDomainValueChange = (value: string) => {
    if (value !== ',' && value !== ' ' && value !== ';') {
      setInputValue(value);
    }
  };

  const checkInputValidation = (newValueList) => {
    if (maxInputCount && newValueList.length > maxInputCount) {
      onError(ERRORS.MaxValues);
    } else if (error === ERRORS.MaxValues) {
      onError(null);
    }
  };

  useEffect(() => {
    checkInputValidation(values);
  }, [values]);

  const onValuesRemove = (value: string) => {
    onChange(values.filter((email) => email !== value));
  };

  const onAddValues = (isBlur = false, pasteData?: string) => {
    const addedValues = getSeparatedTextList(
      pasteData ?? inputValue,
      separators,
    );
    const valueList = [];
    let newValue = '';

    addedValues.forEach((value, index) => {
      if (!values.includes(value) && !valueList.includes(value)) {
        valueList.push(value);
      } else if (index === addedValues.length - 1 && isBlur) {
        newValue = newValue.concat(`${value}`);
      }
    });

    const newValueList = [...values, ...valueList];
    setInputValue(newValue);
    onChange(newValueList);
  };

  const onAction = (action: string, isBlur = false, pasteData?: string) => {
    if (DEFAULT_SEPARATORS.includes(action) || separators.includes(action)) {
      onAddValues(isBlur, pasteData);
    }
  };

  const onKeyPress = (e) => {
    onAction(e.key);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Backspace' && inputValue === '' && values.length > 0) {
      onValuesRemove(values[values.length - 1]);
    }
  };

  const onPaste = (event) => {
    const pasteData = event.clipboardData.getData('Text');
    event.preventDefault();
    onAction('Paste', false, pasteData);
  };
  const onBlur = () => {
    onAction('Blur', true);
  };

  const classes = classNames([
    'multi-chip-input--container',
    {
      error: !!error,
    },
  ]);

  return (
    <>
      <div
        className={classes}
        {...accessibleOnClick(onContainerClicked)}
        tabIndex={undefined}
      >
        <div className="multi-chip-input--pills">
          {values?.map(
            (value) =>
              value && (
                <OverlayTooltip
                  placement={Placement.Bottom}
                  className="multi-chip-input-tooltip"
                  text={value}
                  show={value.length > maxChipLength ? undefined : false}
                  key={value}
                >
                  <Pills
                    theme="outline"
                    size="md"
                    label={getTrimmedChipValue(value, maxChipLength)}
                    showCloseIcon={true}
                    onClose={() => onValuesRemove(value)}
                  />
                </OverlayTooltip>
              ),
          )}

          {hasMore &&
            (isLoading ? null : (
              <Pills
                theme="outline"
                size="md"
                label={loadMoreLabel}
                onClick={() => {
                  onLoadMore?.();
                }}
                showCloseIcon={false}
                key="hasMore"
                className="cursor-pointer"
              />
            ))}

          {isLoading && (
            <div className="multi-chip-input--loader">
              <SkeletonLoading width={100} height={24} />
              <SkeletonLoading width={110} height={24} />
              <SkeletonLoading width={105} height={24} />
              <SkeletonLoading width={115} height={24} />
              <SkeletonLoading width={120} height={24} />
            </div>
          )}

          <input
            className="multi-chip-input--input"
            value={inputValue}
            placeholder={placeholder}
            onChange={(e) => onEmailAndDomainValueChange(e?.target?.value)}
            ref={multiChipInputRef}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        </div>
        {showInputCount ? (
          <span className="multi-chip-input--count">
            {values.length}/{maxInputCount ?? 0}
          </span>
        ) : null}
      </div>
      <div className="multi-chip-input--helper-wrapper">
        <span className="multi-chip-input--error-msg">{error || null}</span>
      </div>
    </>
  );
};

export default MultiChipInput;
