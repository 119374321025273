import { RequestStatus } from '../../../shared/enums/request-status';
import { showGeneralErrorNotification } from '../../../shared/utils/errors';
import hasResource from '../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../shared/utils/access-control/enums/resource-identifiers';
import { starterPlanRestriction } from '../../../shared/utils/plan-restriction';
import {
  AttachmentFailedType,
  AttachmentFailedTypeReason,
  FailedEmailAttachment,
} from '../../../shared/components/email-preview-modal/type';

export const showGeneralErrorMessage = (requestError) => {
  if (requestError) {
    showGeneralErrorNotification(requestError.message);
  }
};

export const getIsInitialRequestPending = ({
  initialFilters,
  filters,
  templates,
  isFilterDirty,
  getTemplatesRequestStatus,
}): boolean =>
  JSON.stringify(initialFilters) === JSON.stringify(filters) &&
  templates === null &&
  !isFilterDirty.current &&
  (getTemplatesRequestStatus === RequestStatus.Pending ||
    getTemplatesRequestStatus === RequestStatus.Ideal);

export const getIsRequestPending = (status) => status === RequestStatus.Pending;

export const getIsRequestPendingForEmailComposeModal = ({
  createTemplateRequestStatus,
  updateTemplateRequestStatus,
}) =>
  getIsRequestPending(createTemplateRequestStatus) ||
  getIsRequestPending(updateTemplateRequestStatus);

export const getIsDisabledForEmailComposeModal = ({
  filters,
  selectedTemplateId,
}) => filters.shared && selectedTemplateId;

export const getShouldRemountTable = ({ payload, filters }) =>
  payload.shared !== undefined && payload.shared !== filters.shared;

export const onCreateTemplate = ({ onShowUpgradePlanModal, onAddTemplate }) => {
  if (starterPlanRestriction()) {
    onShowUpgradePlanModal();
    return;
  }

  if (hasResource(ResourceIdentifiers.TEMPLATES_CREATE)) {
    onAddTemplate();
  }
};

export const getTotalAttachmentLabel = (attachments: any[]) => {
  if (!attachments?.length) {
    return [];
  }
  const label = attachments?.length === 1 ? 'file' : 'files';
  return [`${attachments?.length} ${label} attached`];
};

export const getFailedAttachmentByType = (
  failedAttachments: FailedEmailAttachment[],
): string[] => {
  if (!failedAttachments?.length) {
    return [];
  }

  const typeCounts: Record<AttachmentFailedType, number> = {
    [AttachmentFailedType.FileSizeExceed]: 0,
    [AttachmentFailedType.FileFormatNotSupported]: 0,
    [AttachmentFailedType.InvalidFile]: 0,
  };

  const typeToReasonMap: Record<AttachmentFailedType, string> = {
    [AttachmentFailedType.FileSizeExceed]:
      AttachmentFailedTypeReason.FileSizeExceed,
    [AttachmentFailedType.FileFormatNotSupported]:
      AttachmentFailedTypeReason.FileFormatNotSupported,
    [AttachmentFailedType.InvalidFile]: AttachmentFailedTypeReason.InvalidFile,
  };

  failedAttachments.forEach((attachment) => {
    if (typeCounts[attachment.type] !== undefined) {
      typeCounts[attachment.type as AttachmentFailedType]++;
    }
  });

  const result: string[] = Object.entries(typeCounts)
    .filter(([, count]) => count > 0)
    .map(([type, count]) => {
      const reason = typeToReasonMap[type as AttachmentFailedType];
      const fileWord = count === 1 ? 'file' : 'files';
      const dynamicReason = reason.replace('files', fileWord);

      return `${count} ${dynamicReason}`;
    });

  return result;
};
