import React from 'react';
import { Button, SkeletonLoading } from '@saleshandy/design-system';
import { UserAndTeamsTabs } from '../../../enums/users-and-teams';
import SearchInput from '../../../../../shared/design-system/components/atoms/search-input';
import { UsersFilter } from '../../../types/users-and-teams';
import { IconPlace } from '../../../../../shared/design-system/components/input/enums';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import PremiumFeatureIndicator from '../../../../../shared/components/premium-feature-indicator';

type IProps = {
  activeTab: UserAndTeamsTabs;
  onSearch: VoidFunction;
  filters: UsersFilter;
  onSearchChange: (search: string) => void;
  onClearSearch: VoidFunction;
  showInviteUserModal: VoidFunction;
  showAddTeamModal: VoidFunction;
  isInviteUserModalBtnLoading: boolean;
  isLoading: boolean;
};

const UsersAndTeamsExtraContent: React.FC<IProps> = ({
  activeTab,
  filters,
  onSearch,
  onSearchChange,
  onClearSearch,
  showAddTeamModal,
  showInviteUserModal,
  isInviteUserModalBtnLoading,
  isLoading,
}) => (
  <div className="users-and-teams--actions-wrapper">
    {isLoading && activeTab === UserAndTeamsTabs.Users && (
      <SkeletonLoading width={200} height={32} />
    )}

    {isLoading && <SkeletonLoading width={100} height={32} />}

    {!isLoading && activeTab === UserAndTeamsTabs.Users && (
      <>
        <SearchInput
          onSearch={onSearch}
          searchValue={filters.search}
          setSearchValue={onSearchChange}
          onClearSearch={onClearSearch}
          searchIconPlace={IconPlace.Left}
          className="users-and-teams--search"
          placeholder="Search..."
          initialValue={filters.search}
        />

        <PremiumFeatureIndicator />

        <Button
          onClick={showInviteUserModal}
          isLoading={isInviteUserModalBtnLoading}
          disabled={isInviteUserModalBtnLoading}
        >
          Invite Users
        </Button>
      </>
    )}

    {!isLoading && activeTab === UserAndTeamsTabs.Teams && (
      <>
        <Button onClick={showAddTeamModal}>Create Team</Button>
      </>
    )}
  </div>
);

export default UsersAndTeamsExtraContent;
