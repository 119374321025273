/* eslint-disable no-restricted-syntax */
import { CheckboxDataList } from '../type';

export const getMappedKeyFromValue = (
  checkboxDataList: CheckboxDataList,
  value: string,
) => {
  for (const dataList of checkboxDataList) {
    if (value === dataList.value) {
      return dataList.key;
    }
    if (dataList.children) {
      const childValue = getMappedKeyFromValue(dataList.children, value);
      if (childValue) return childValue;
    }
  }
  return undefined;
};

export const getMappedValueFromKey = (
  checkboxDataList: CheckboxDataList,
  key: string,
) => {
  for (const dataList of checkboxDataList) {
    if (key === dataList.key) {
      return dataList.value;
    }
    if (dataList.children) {
      const childKey = getMappedValueFromKey(dataList.children, key);
      if (childKey) return childKey;
    }
  }
  return undefined;
};

export const replaceParentValueIfAllChildExists = (
  checkboxDataList: CheckboxDataList,
  filterList: string[],
) => {
  for (const dataList of checkboxDataList) {
    if (dataList.children) {
      let shouldReplaceParent = true;
      const childValueList = [];
      for (const childList of dataList.children) {
        childValueList.push(childList.key);
        if (!filterList.includes(childList.key)) {
          shouldReplaceParent = false;
          break;
        }
      }
      if (shouldReplaceParent) {
        filterList = filterList.filter((val) => !childValueList.includes(val));
        filterList.push(dataList.key);
      }
    }
  }
  return filterList;
};

export const getIsErrorFromErrorMapping = (
  errorMapping: Record<string, string>,
) => Object.values(errorMapping).some((error) => error);
