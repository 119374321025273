import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { ChevronDown, ChevronUp, Cross } from '@saleshandy/icons';
import type { IProps } from './agency-client-dropdown-container';
import Transfer from '../../../../shared/design-system/components/transfer';
import { AgencyClientFilter } from '../../types/types';
import { getClientName } from '../../helpers/helpers';

const AgencyClientDropdown: React.FC<IProps> = ({
  options,
  optionRenderer,
  selectedOptionRenderer,
  selectedOptionClassName,
  onChange,
  initialSelectedOption,
  placement,
  isLoading,
  className,
  disabled,
  onRemoveAssociation,
  oldSelectedOption,
  onToggle: onToggleProp,
}) => {
  const [show, setShow] = React.useState(false);

  const [
    selectedOption,
    setSelectedOption,
  ] = React.useState<AgencyClientFilter>(initialSelectedOption);

  const onClientChange = (option) => {
    if (option) {
      setSelectedOption(option);
      setShow(false);
      onChange?.(option);
    }
  };

  const agencyClientWrapperClassName = classNames([
    'agency-client-wrapper',
    {
      'agency-client-disabled': disabled,
      'agency-client-show': show,
    },
  ]);

  return (
    <Transfer
      className={`agency-client-dropdown ${className}`}
      autoFocusSearch={true}
      isLoading={isLoading}
      options={options}
      customFilterOption={(_options, searchValue) =>
        _options.filter(
          (option) =>
            option.companyName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            option.firstName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            option.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
            option.email.toLowerCase().includes(searchValue.toLowerCase()),
        )
      }
      selectedOptionKey={selectedOption.id}
      placement={placement}
      showSearch
      optionFilterProp="email"
      searchPlaceholder="Search"
      show={show}
      onToggle={(value) => {
        if (disabled) {
          setShow(false);
        } else {
          setShow(value);
          onToggleProp?.(value);
        }
      }}
      showOptionsSeparator={true}
      optionRenderer={optionRenderer}
      onChange={([option]) => onClientChange(option)}
    >
      {!selectedOptionRenderer ? (
        <div className={agencyClientWrapperClassName}>
          <>
            {initialSelectedOption?.id >= 0 ? (
              <div className="agency-client-main">
                <div>
                  <span>
                    {getClientName(selectedOption, 45, {
                      company: true,
                      email: true,
                      deleted: true,
                    })}
                  </span>
                </div>
                <div className="bs-select-box-icon-wrapper agency-client-content-wrapper">
                  {onRemoveAssociation &&
                    oldSelectedOption?.id === selectedOption?.id && (
                      <>
                        <Cross
                          onClick={(e) => {
                            if (!disabled) {
                              e.preventDefault();
                              e.stopPropagation();
                              onRemoveAssociation?.();
                            }
                          }}
                          className={
                            disabled ? 'agency-cross-disabled' : 'agency-cross'
                          }
                        />
                        <span />
                      </>
                    )}
                  {show ? <ChevronUp /> : <ChevronDown />}
                </div>
              </div>
            ) : (
              <div className="agency-client-main">
                <div>
                  <span className="ml-2">Select Client </span>
                </div>
                <div className="bs-select-box-icon-wrapper">
                  {show ? <ChevronUp /> : <ChevronDown />}
                </div>
              </div>
            )}
          </>
        </div>
      ) : (
        <div className={selectedOptionClassName}>
          {selectedOptionRenderer?.([selectedOption])}
          <div className="bs-select-box-icon-wrapper">
            {show ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
      )}
    </Transfer>
  );
};

export default withRouter(AgencyClientDropdown);
