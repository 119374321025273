/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-globals */
import { Accordion, Button } from '@saleshandy/design-system';
import { Filter, Minus, Plus } from '@saleshandy/icons';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tag from '../../../../shared/design-system/components/tag';
import { FilterComponentType } from '../../type';
import { FILTER_LIST } from '../hooks/useLeadFilter';
import Autocomplete from '../search-auto-complete';
import DropDownFilterComponent from './dropdown-filter';
import FilterCheckbox from './filter-checkbox';
import KeywordInputComponent from '../keyword-search-input/keyword-search-input';
import SavedFilter from '../saved-filter/saved-filter';
import { Placement } from '../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';

export default function PeopleTabComponent({
  handleRecentSearch,
  handleGeneratePayload,
  setSelectedLeads,
  onBulkSearchShow,
  handleDeleteFilter,
  handleGetAllKeysByFilterFromQuery,
  handleIndividualTag,
  handleFoundValueExceptFromList,
  handleQuery,
  bulkFilterSyncKey,
}) {
  const [selectedFilter, setSelectedFilter] = useState<any>([]);
  const overlayRef = useRef(null);
  const accordionKey = 3830;

  const location = useLocation();
  const keywordInputRef: any = useRef();

  const [showTagTooltip, setShowTagTooltip] = useState<any>(null);

  const [initialize] = useOverlayScrollbars({
    options: {
      overflow: {
        x: 'hidden',
      },
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 500,
      },
    },
    defer: true,
  });

  const onSelectFilter = (id, filter) => {
    setSelectedFilter((prevSelectedFilter) => {
      const index = prevSelectedFilter.findIndex((filter) => filter.id === id);
      if (index === -1) {
        return [
          ...prevSelectedFilter,
          {
            id,
            filter,
          },
        ];
      }
      return prevSelectedFilter.filter((filter) => filter.id !== id);
    });
  };

  const isSelectedFilter = useMemo(() => {
    return (filter) => selectedFilter.some((f) => f.id === filter.id);
  }, [selectedFilter]);

  const handleClearAll = () => {
    handleRecentSearch();
    setSelectedFilter([]);
    setSelectedLeads([]);
  };

  useEffect(() => {
    initialize(overlayRef.current);
  }, [initialize]);

  useEffect(() => {
    if (keywordInputRef.current) {
      keywordInputRef.current.focus();
    }
    return () => {
      setSelectedFilter([]);
    };
  }, []);
  const getLebelTooltip = (text: string) => (
    <Tooltip id="popover-basic" className="bs-tooltip-inner">
      {text}
    </Tooltip>
  );

  return (
    <>
      <KeywordInputComponent
        handleQuery={handleQuery}
        keywordInputRef={keywordInputRef}
      />
      <div
        className="leads-bulk-search"
        {...accessibleOnClick(onBulkSearchShow)}
      >
        <Plus height={16} width={16} />
        Bulk Search
      </div>
      <h3 className="leads-filter">
        <div>
          <Filter className="sh-filter" />
          Filter
        </div>
        {location?.search && (
          <Button
            variant="link"
            onClick={handleClearAll}
            className="mb-0 p-0 blue-txt-11 regular-1 bs-mr-12"
          >
            Clear All
          </Button>
        )}
      </h3>
      <div>
        <div
          key={accordionKey}
          ref={overlayRef}
          className="people-tab-accordian"
        >
          <Accordion
            allowMultipleExpanded
            key={accordionKey}
            allowZeroExpanded
            // onChange={(uuid) => onAccordionChange(uuid)}
          >
            {FILTER_LIST &&
              FILTER_LIST.map((filter: any) => {
                const allTags = handleGetAllKeysByFilterFromQuery(filter);
                return (
                  <Accordion.Item
                    key={filter.id}
                    uuid={filter.id}
                    dangerouslySetExpanded={selectedFilter?.some(
                      (f) => f?.id === filter?.id,
                    )}
                    className={`${
                      isSelectedFilter(filter)
                        ? 'accordion__item_selected'
                        : 'accordion__item'
                    }`}
                  >
                    <Accordion.ItemHeading
                      onClick={() => onSelectFilter(filter.id, filter)}
                      className={`${
                        allTags.size > 0 && !isSelectedFilter(filter)
                          ? 'accordion-item-heading-tag'
                          : 'accordion-item-heading'
                      } ${allTags.size ? 'accordion-item-selected-tag' : ''}`}
                    >
                      <Accordion.ItemButton className="::before:none w-100">
                        <div className="w-100 accordian-label">
                          <div className="d-flex">
                            <div className="icon">{filter.icon}</div>
                            <div className="label">{filter?.label}</div>
                          </div>
                          <div className="d-flex align-items-center">
                            {allTags.size > 0 && (
                              <Tag
                                value={allTags.size}
                                variant={Tag.Variant.Remove}
                                className="bg-white rounded-pill"
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  handleDeleteFilter(filter);
                                }}
                              />
                            )}
                            <div className="d-flex align-items-center justify-content-end">
                              <Accordion.ItemState>
                                {({ expanded }) =>
                                  expanded ? (
                                    <Minus className="accordion-minus-icon" />
                                  ) : (
                                    <Plus className="accordion-plus-icon" />
                                  )
                                }
                              </Accordion.ItemState>
                            </div>
                          </div>
                        </div>
                        {allTags.size > 0 && !isSelectedFilter(filter) && (
                          <div className="filter-applied-collapse">
                            {Array.from(allTags)
                              .slice(0, 5)
                              .map((tag: any, index) => (
                                <span
                                  key={`${tag?.label}-${index}`}
                                  onMouseEnter={() =>
                                    setShowTagTooltip(
                                      `tag-${tag?.key}-${
                                        tag?.label || ''
                                      }-${index}`,
                                    )
                                  }
                                  onMouseLeave={() => setShowTagTooltip(null)}
                                >
                                  <OverlayTrigger
                                    placement={Placement.BottomStart}
                                    overlay={getLebelTooltip(tag?.label)}
                                    show={
                                      showTagTooltip ===
                                        `tag-${tag?.key}-${
                                          tag?.label || ''
                                        }-${index}` && tag?.label?.length > 15
                                    }
                                  >
                                    <Tag
                                      key={tag?.label}
                                      value={
                                        tag?.label.length > 15
                                          ? `${tag?.label.substring(0, 15)}..`
                                          : tag?.label
                                      }
                                      variant={Tag.Variant.Remove}
                                      className="filter-tag"
                                      onClick={(e) => {
                                        e?.stopPropagation();
                                        handleIndividualTag(tag);
                                      }}
                                    />
                                  </OverlayTrigger>
                                </span>
                              ))}
                            {Array.from(allTags)?.length > 5 && (
                              <Tag
                                value={
                                  <div className="d-flex align-items-center">
                                    <Plus
                                      className="gray-txt-15 mr-1"
                                      width={13}
                                      height={13}
                                    />
                                    <p className="mb-0">
                                      {Array.from(allTags)?.length - 5}
                                    </p>
                                  </div>
                                }
                                className="filter-tag more-tag"
                              />
                            )}
                          </div>
                        )}
                      </Accordion.ItemButton>
                    </Accordion.ItemHeading>

                    <Accordion.ItemPanel>
                      {filter?.type?.map((subFilter) => (
                        <React.Fragment
                          key={`subfilter-${filter?.id}-${subFilter.id}`}
                        >
                          {subFilter?.value ===
                            FilterComponentType.SEARCH_INPUT && (
                            <Autocomplete
                              isExpand={isSelectedFilter(filter)}
                              suggestions={[]}
                              filterKey={subFilter?.key}
                              placeholder={`Enter ${subFilter?.placeholder}`}
                              name={subFilter?.name}
                              className={subFilter?.className}
                              handleFoundValueExceptFromList={
                                handleFoundValueExceptFromList
                              }
                              handleQuery={handleQuery}
                            />
                          )}
                          {subFilter?.value ===
                            FilterComponentType.MULTILEVEL_CHECKBOX && (
                            <FilterCheckbox
                              key={`filter-subfilter-${filter?.id}-${subFilter.id}`}
                              list={subFilter?.list}
                              filterKey={subFilter?.key}
                              excludeKey={subFilter?.excludeKey}
                              inputClassName={subFilter?.inputClassName}
                              inputPlaceHolder={subFilter?.inputPlaceHolder}
                              inputLabel={subFilter?.inputLabel}
                              hideSearchBox={subFilter?.hideSearchBox}
                              removeTopMargin={subFilter?.removeTopMargin}
                              infoCircleToolTipMapping={
                                subFilter?.infoCircleToolTipMapping
                              }
                              hideIncludeExclude={subFilter?.hideIncludeExclude}
                              handleQuery={handleQuery}
                              isExpand={isSelectedFilter(filter)}
                              parentKey={filter?.key}
                              includeMaxLimit={subFilter?.includeMaxLimit}
                              bulkFilterSyncKey={bulkFilterSyncKey}
                              expandedMaxFilterTags={
                                subFilter?.expandedMaxFilterTags
                              }
                              handleExpandFilterTags={() => {
                                /**
                                 * Since expandedMaxFilterTags is introduced for bulk search
                                 * upon clicking on [+N] tag it will open up bulk search modal
                                 * by default for now
                                 */
                                onBulkSearchShow();
                              }}
                            />
                          )}
                          {subFilter?.value ===
                            FilterComponentType.DROP_DOWN && (
                            <DropDownFilterComponent
                              list={subFilter?.list}
                              isPrefix={subFilter?.isPrefix}
                              filterKey={subFilter?.key}
                              name={subFilter?.name}
                              className={subFilter?.className}
                              handleQuery={handleQuery}
                              placeholder={subFilter?.placeholder}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </Accordion.ItemPanel>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </div>
      </div>
      <SavedFilter {...{ handleGeneratePayload }} />
    </>
  );
}
