import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  CustomDomainType,
  CustomDomainWithEmailAccountType,
} from '../../types/custom-domain';
import {
  getCustomDomainsRequest,
  createCustomDomainRequest,
  deleteCustomDomainRequest,
  updateCustomDomainRequest,
  getCustomDomainsListRequest,
  setDefaultCustomDomainRequest,
  checkBulkEmailAccountSetupScoreRequest,
} from './extra-actions';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  // getEmailAccountsListRequest: RequestState;
  setDefaultCustomDomainRequest: RequestState;
  getCustomDomainsRequest: RequestState;
  getCustomDomainsListRequest: RequestState;
  createCustomDomainRequest: RequestState;
  deleteCustomDomainRequest: RequestState;
  updateCustomDomainRequest: RequestState;
  checkBulkEmailAccountSetupScoreRequest: RequestState;
  customDomains: CustomDomainWithEmailAccountType[];
  customDomainsList: CustomDomainType[];
  isLoading: boolean;
}

const initialState: State = {
  setDefaultCustomDomainRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getCustomDomainsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getCustomDomainsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createCustomDomainRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteCustomDomainRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateCustomDomainRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  checkBulkEmailAccountSetupScoreRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },

  customDomains: [],
  customDomainsList: [],
  isLoading: false,
};

const customDomainSlice = createSlice({
  name: 'customDomain',
  initialState,
  reducers: {
    resetCustomDomainState: (state: State) => {
      state.setDefaultCustomDomainRequest =
        initialState.setDefaultCustomDomainRequest;
    },
    resetCustomTrackingDomainState: (state) => {
      state.getCustomDomainsRequest = initialState.getCustomDomainsRequest;
      state.getCustomDomainsListRequest =
        initialState.getCustomDomainsListRequest;
      state.createCustomDomainRequest = initialState.createCustomDomainRequest;
      state.updateCustomDomainRequest = initialState.updateCustomDomainRequest;
      state.deleteCustomDomainRequest = initialState.deleteCustomDomainRequest;
      state.customDomains = initialState.customDomains;
      state.customDomainsList = initialState.customDomainsList;
      state.isLoading = initialState.isLoading;
    },
    resetCreateCustomTrackingDomain: (state) => {
      state.createCustomDomainRequest = initialState.createCustomDomainRequest;
    },
    resetUpdateCustomTrackingDomain: (state) => {
      state.updateCustomDomainRequest = initialState.updateCustomDomainRequest;
    },
    resetDeleteCustomTrackingDomain: (state) => {
      state.deleteCustomDomainRequest = initialState.deleteCustomDomainRequest;
    },
    resetCheckBulkEmailAccountSetupScoreRequest: (state) => {
      state.checkBulkEmailAccountSetupScoreRequest =
        initialState.checkBulkEmailAccountSetupScoreRequest;
    },
  },
  extraReducers: (builder) => {
    // Set default custom domain
    builder.addCase(setDefaultCustomDomainRequest.pending, (state) => {
      state.setDefaultCustomDomainRequest.status = RequestStatus.Pending;
      state.setDefaultCustomDomainRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      setDefaultCustomDomainRequest.fulfilled,
      (state, action) => {
        state.setDefaultCustomDomainRequest.status = RequestStatus.Succeeded;
        state.setDefaultCustomDomainRequest.message = action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(setDefaultCustomDomainRequest.rejected, (state, action) => {
      state.setDefaultCustomDomainRequest.status = RequestStatus.Failed;
      state.setDefaultCustomDomainRequest.error = action.payload;
      state.isLoading = false;
    });

    // get custom domains.
    builder.addCase(getCustomDomainsRequest.pending, (state) => {
      state.getCustomDomainsRequest.status = RequestStatus.Pending;
      state.getCustomDomainsRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getCustomDomainsRequest.fulfilled, (state, action) => {
      state.getCustomDomainsRequest.status = RequestStatus.Succeeded;
      state.getCustomDomainsRequest.message = action.payload.message;
      state.customDomains = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getCustomDomainsRequest.rejected, (state, action) => {
      state.getCustomDomainsRequest.status = RequestStatus.Failed;
      state.getCustomDomainsRequest.error = action.payload;
      state.isLoading = false;
    });

    // get custom domains list.
    builder.addCase(getCustomDomainsListRequest.pending, (state) => {
      state.getCustomDomainsListRequest.status = RequestStatus.Pending;
      state.getCustomDomainsListRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getCustomDomainsListRequest.fulfilled, (state, action) => {
      state.getCustomDomainsListRequest.status = RequestStatus.Succeeded;
      state.getCustomDomainsListRequest.message = action.payload.message;
      state.customDomainsList = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getCustomDomainsListRequest.rejected, (state, action) => {
      state.getCustomDomainsListRequest.status = RequestStatus.Failed;
      state.getCustomDomainsListRequest.error = action.payload;
      state.isLoading = false;
    });

    // create custom domain.
    builder.addCase(createCustomDomainRequest.pending, (state) => {
      state.createCustomDomainRequest.status = RequestStatus.Pending;
      state.createCustomDomainRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(createCustomDomainRequest.fulfilled, (state, action) => {
      state.createCustomDomainRequest.status = RequestStatus.Succeeded;
      state.createCustomDomainRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(createCustomDomainRequest.rejected, (state, action) => {
      state.createCustomDomainRequest.status = RequestStatus.Failed;
      state.createCustomDomainRequest.error = action.payload;
      state.isLoading = false;
    });

    // delete custom domain.
    builder.addCase(deleteCustomDomainRequest.pending, (state) => {
      state.deleteCustomDomainRequest.status = RequestStatus.Pending;
      state.deleteCustomDomainRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(deleteCustomDomainRequest.fulfilled, (state, action) => {
      state.deleteCustomDomainRequest.status = RequestStatus.Succeeded;
      state.deleteCustomDomainRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(deleteCustomDomainRequest.rejected, (state, action) => {
      state.deleteCustomDomainRequest.status = RequestStatus.Failed;
      state.deleteCustomDomainRequest.error = action.payload;
      state.isLoading = false;
    });

    // patch custom domain.
    builder.addCase(updateCustomDomainRequest.pending, (state) => {
      state.updateCustomDomainRequest.status = RequestStatus.Pending;
      state.updateCustomDomainRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(updateCustomDomainRequest.fulfilled, (state, action) => {
      state.updateCustomDomainRequest.status = RequestStatus.Succeeded;
      state.updateCustomDomainRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(updateCustomDomainRequest.rejected, (state, action) => {
      state.updateCustomDomainRequest.status = RequestStatus.Failed;
      state.updateCustomDomainRequest.error = action.payload;
      state.isLoading = false;
    });

    // check bulk email account setup score
    builder.addCase(checkBulkEmailAccountSetupScoreRequest.pending, (state) => {
      state.checkBulkEmailAccountSetupScoreRequest.status =
        RequestStatus.Pending;
      state.checkBulkEmailAccountSetupScoreRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      checkBulkEmailAccountSetupScoreRequest.fulfilled,
      (state, action) => {
        state.checkBulkEmailAccountSetupScoreRequest.status =
          RequestStatus.Succeeded;
        state.checkBulkEmailAccountSetupScoreRequest.message =
          action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      checkBulkEmailAccountSetupScoreRequest.rejected,
      (state, action) => {
        state.checkBulkEmailAccountSetupScoreRequest.status =
          RequestStatus.Failed;
        state.checkBulkEmailAccountSetupScoreRequest.error = action.payload;
        state.isLoading = false;
      },
    );
  },
});

export const {
  resetCustomTrackingDomainState,
  resetCreateCustomTrackingDomain,
  resetUpdateCustomTrackingDomain,
  resetDeleteCustomTrackingDomain,
  resetCheckBulkEmailAccountSetupScoreRequest,
} = customDomainSlice.actions;
export { getCustomDomainsRequest };
export const { resetCustomDomainState } = customDomainSlice.actions;
export default customDomainSlice.reducer;
