export enum AnalyticsEvents {
  SignupCompleted = 'Sign-up Completed',
  OnboardingCompleted = 'Onboarding Completed',
  SignedOut = 'Signed Out',
  NotificationClicked = 'Notification Clicked',
  ExtensionIconClicked = 'Extension Icon Clicked',
  FeatureRequestClicked = 'Feature Request Clicked',
  BeaconOpened = 'Beacon Opened',
  TemplateInserted = 'Template Inserted',
  ExtraVariantAdded = 'Extra Variant Added',
  TrialExtended = 'Trial Extended',
  TrippedFeature = 'Tripped Feature',
  LoadedAPage = 'Loaded a Page',
  ClickedGetStartedOnAgencyTab = 'Clicked Get Started on Agency Tab',
  ClickedOnPayNow = 'Clicked On Pay Now',
  IntegrationConnected = 'Integration Connected',
  IntegrationDisconnected = 'Integration Disconnected',
  IntegrationConfigured = 'Integration Configured',
  PreSendPreviewLoaded = 'Pre Send Preview Loaded',
  SpintaxCreated = 'Spintax created',
  ReportsImageDownloaded = 'Reports Image Downloaded',
  FiltersApplied = 'Filters applied',
  AiVariantGenerate = 'AI Variant Generate',
  AiVariantSaved = 'AI Variant Saved',
  AiVariantDiscard = 'AI Variant Discard',
}
