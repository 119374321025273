import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { AlertTriangle, Braces2 } from '@saleshandy/icons';
import toaster, { Theme } from '../toaster';
import DragAndDrop from './drag-and-drop';
import Attachment from '../components/attachment';
import Alert from '../design-system/ui/alert';
import SpintaxDropdown from '../components/spintax-dropdown/spintax-dropdown';
import TagsDropdown from '../components/tags-dropdown';

import { Images } from '../app-constants';
import { OverlayTooltip, Placement } from '../design-system/components/overlay';
import { executeOnRequestStatus } from '../utils/execute-on-request-status';
import { ProductTour } from '../enums/product-tour';

import {
  addAlignmentSplitButton,
  generateEditorId,
  addIcon,
  getAcceptedExtensionsForAttachment,
  addFileToTheLocalAttachments,
  getTagsOptionsForInlineDropdown,
} from './helpers';
import {
  AttachmentMetaDataType,
  AttachmentType,
  AttachmentUploadStatus,
  EditorProps,
  TagsOptionsForInlineDropdown,
} from './types';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/wordcount';

let editorInstance;

const Editor = React.forwardRef<HTMLDivElement, EditorProps>(
  (
    {
      value,
      onEditorChange,
      error,
      className,
      onEditorBlur,
      disabled = false,
      mergeTags,
      imageUploader,
      onEditorInit,
      getEditorInstance,
      shouldRenderTemplate,
      showTemplateModal,
      onKeyUp,
      addMergeTagInContent,
      addVariableTagInContent,
      addSpintaxInContent,
      showAddAttachments,
      attachments,
      onAttachmentStatusChange,
      onAttachmentsChange,
      sendAttachmentUploadRequest,
      uploadAttachmentRequestStatus,
      uploadAttachmentRequestError,
      uploadedAttachment,
      resetUploadAttachmentRequest,
      errorMessage,
      sendDeleteAttachmentRequest,
      deleteAttachmentRequestStatus,
      deleteAttachmentRequestError,
      deletedAttachmentId,
      resetDeleteAttachmentRequest,
      onAttachmentsMetaChange,
      isTemplateEditorModal,
      sequenceStepVariantId,
      showSpintaxDropdown = false,
      disabledVariableTags,
      showMergeAndVariableTags = false,
      tagsDropdownClassName,
      uniqueId, // This id is used to handle multiple concurrent API calls in multiple editors
      action,
      attachmentsClassname = '',
      disableRenderTemplateBtn = false,
    },
    ref,
  ) => {
    const editorClass = classNames([
      'editor',
      className,
      {
        error,
      },
    ]);

    const { t } = useTranslation();
    const [id] = useState(generateEditorId());

    const dropRef = useRef<HTMLDivElement>();
    const attachmentInput = useRef<HTMLInputElement>(null);

    const [dragging, setDragging] = useState(false);
    const [attachmentsMeta, setAttachmentsMeta] = useState<
      AttachmentMetaDataType[]
    >([]);
    const [showSpintax, setShowSpintax] = useState(false);

    const [
      tagsOptionsForInlineDropdown,
      setTagsOptionsForInlineDropdown,
    ] = useState<TagsOptionsForInlineDropdown>([]);

    const handleOnSpintaxToggle = (v: boolean) => {
      if (!disabled) {
        setShowSpintax(v);
      }
    };

    const handleAttachment = () => {
      if (attachmentInput && attachmentInput.current) {
        attachmentInput.current.click();
      }
    };

    const onUploadProgress = (
      e,
      updatedAttachmentsMeta: AttachmentMetaDataType[],
      attachmentIdentifier: string,
    ) => {
      let attachmentMeta = updatedAttachmentsMeta.find(
        (item) => item.attachmentIdentifier === attachmentIdentifier,
      );

      if (attachmentMeta) {
        attachmentMeta = {
          ...attachmentMeta,
          percentage: Math.round((100 * e.loaded) / e.total),
        };

        setAttachmentsMeta(
          updatedAttachmentsMeta.map((item) =>
            item.attachmentIdentifier === attachmentIdentifier
              ? attachmentMeta
              : item,
          ),
        );
      }
    };

    const handleAttachments = (files) => {
      const localAttachments = attachments?.length > 0 ? [...attachments] : [];
      const onLocalAttachmentChange = (obj) => {
        localAttachments.push(obj);
      };

      const localAttachmentsMeta = [...attachmentsMeta];
      const onLocalAttachmentMetaChange = (obj) => {
        localAttachmentsMeta.push(obj);
      };

      let shouldBreakLoop = false;
      const breakLoop = () => {
        shouldBreakLoop = true;
      };

      if (files && files.length > 0) {
        // Looping files array to validate each file and to upload it simultaneously
        files?.forEach(async (file) => {
          if (shouldBreakLoop) {
            return;
          }

          addFileToTheLocalAttachments({
            file,
            localAttachments,
            onLocalAttachmentChange,
            onLocalAttachmentMetaChange,
            breakLoop,
            t,
            content: value,
          });
        });

        setAttachmentsMeta(localAttachmentsMeta);

        localAttachments.forEach((attachment, indx) => {
          if (attachment.status === AttachmentUploadStatus.Upload) {
            // making api call from here to upload the file
            const formData = new FormData();
            formData.append('file', attachment.file);
            formData.append(
              'attachmentIdentifier',
              attachment.attachmentIdentifier,
            );
            formData.append('attachmentSize', attachment.file.size);

            const attachmentMeta = localAttachmentsMeta.find(
              (item) =>
                item.attachmentIdentifier === attachment.attachmentIdentifier,
            );

            sendAttachmentUploadRequest(
              {
                formData,
                onUploadProgress: (e) =>
                  onUploadProgress(
                    e,
                    localAttachmentsMeta,
                    attachment.attachmentIdentifier,
                  ),
                cancelToken: attachmentMeta.source.token,
              },
              uniqueId,
            );

            localAttachments[indx].status = AttachmentUploadStatus.Uploading;
          }
        });

        onAttachmentsChange(localAttachments);
      }
    };

    const handleFileInput = (evnt) => {
      evnt.preventDefault();
      evnt.stopPropagation();

      if (!showAddAttachments) {
        return;
      }

      const { files } = evnt.target;

      handleAttachments(files);

      attachmentInput.current.value = '';
    };

    const handleDroppedFiles = (evnt) => {
      evnt.preventDefault();
      evnt.stopPropagation();

      if (!showAddAttachments) {
        return;
      }

      setDragging(false);

      const { files } = evnt.dataTransfer;

      handleAttachments(files);

      evnt.dataTransfer.clearData();
    };

    const handleAttachmentDelete = (
      attachmentIdentifier: string,
      attachmentId: number,
    ) => {
      let attachment = null;

      if (attachmentIdentifier) {
        const foundAttachment = attachments.find(
          (item) => item.attachmentIdentifier === attachmentIdentifier,
        );

        attachment = foundAttachment && { ...foundAttachment };
      } else if (attachmentId) {
        const foundAttachment = attachments.find(
          (item) => item.attachmentId === attachmentId,
        );

        attachment = foundAttachment && { ...foundAttachment };
      }

      if (attachment) {
        if (action === 'forward') {
          onAttachmentsChange(
            attachments.filter(
              (item) => item.attachmentIdentifier !== attachmentIdentifier,
            ),
            true,
          );
          return;
        }
        if (attachment.status === AttachmentUploadStatus.Uploading) {
          const attachmentMeta = attachmentsMeta.find(
            (item) =>
              item.attachmentIdentifier === attachment.attachmentIdentifier,
          );

          attachmentMeta.source.cancel('Upload has been cancelled.');

          onAttachmentsChange(
            attachments.filter(
              (item) => item.attachmentIdentifier !== attachmentIdentifier,
            ),
            true,
          );

          toaster.success(t('messages.attachment_removed_successfully'), {
            theme: Theme.New,
          });
          return;
        }
        attachment.status = AttachmentUploadStatus.Deleting;
        onAttachmentStatusChange(attachment);
      }

      sendDeleteAttachmentRequest(
        {
          attachmentId,
          deleteFromTemplate: isTemplateEditorModal,
          ...(sequenceStepVariantId && { sequenceStepVariantId }),
        },
        uniqueId,
      );
    };

    const handleAttachmentReupload = (_attachment: AttachmentType) => {
      const attachment = { ..._attachment };

      if (attachment.status === AttachmentUploadStatus.UploadFailed) {
        // making api call from here to upload the file
        const formData = new FormData();
        formData.append('file', attachment.file);
        formData.append(
          'attachmentIdentifier',
          attachment.attachmentIdentifier,
        );
        formData.append('attachmentSize', attachment.file.size);

        const source = axios.CancelToken.source();

        sendAttachmentUploadRequest(
          {
            formData,
            onUploadProgress: (e) =>
              onUploadProgress(
                e,
                attachmentsMeta,
                attachment.attachmentIdentifier,
              ),
            cancelToken: source.token,
          },
          uniqueId,
        );

        setAttachmentsMeta(
          attachmentsMeta.map((item) =>
            item.attachmentIdentifier === attachment.attachmentIdentifier
              ? {
                  ...item,
                  percentage: 0,
                  source,
                }
              : item,
          ),
        );

        attachment.status = AttachmentUploadStatus.Uploading;
        onAttachmentStatusChange(attachment);
      }
    };

    const onTagOptionSelectedFromInlineDropdown = (
      autocompleteApi,
      rng,
      content: string,
    ) => {
      editorInstance.selection.setRng(rng);
      editorInstance.insertContent(content);
      autocompleteApi.hide();
    };

    const getFilteredTagsOptionsForInlineDropdown = (
      pattern: string,
      options: TagsOptionsForInlineDropdown,
    ) =>
      _.chain(options)
        .filter(
          (tag) =>
            tag.text.toLowerCase().includes(pattern.toLowerCase()) ||
            tag.type.toLowerCase().includes(pattern.toLowerCase()),
        )
        .sortBy((tag) => (tag.type === 'merge tag' ? 0 : 1))
        .groupBy('type')
        .flatMap((items, key) => {
          if (key === 'merge tag') {
            items[0] = { ...items[0], group: 'Merge Tags' };
          } else if (key === 'variable tag') {
            items[0] = { ...items[0], group: 'Variable Tags' };
          }
          return items;
        })
        .value();

    const shouldShowTagsInlineDropdown = (
      rng,
      text: string,
      pattern: string,
    ) => {
      if (text.slice(-2) === '{{' || pattern !== '') {
        return true;
      }
      return false;
    };

    useEffect(
      () => () => {
        if (showAddAttachments) {
          setDragging(false);
          setAttachmentsMeta([]);
          resetUploadAttachmentRequest(uniqueId);
          resetDeleteAttachmentRequest(uniqueId);
        }
      },
      [],
    );

    useEffect(() => {
      if (showMergeAndVariableTags) {
        setTagsOptionsForInlineDropdown(
          getTagsOptionsForInlineDropdown(mergeTags),
        );
      }
    }, [mergeTags]);

    useEffect(() => {
      if (showMergeAndVariableTags && tagsOptionsForInlineDropdown.length > 0) {
        // Autocomplete Code for Inline Tags Dropdown
        editorInstance.ui.registry.addAutocompleter('tags', {
          ch: '{',
          minChars: 0,
          columns: 1,
          highlightOn: ['char_name'],
          onAction: onTagOptionSelectedFromInlineDropdown,
          matches: shouldShowTagsInlineDropdown,
          fetch: (pattern: string) =>
            new Promise((resolve) => {
              const results = getFilteredTagsOptionsForInlineDropdown(
                pattern,
                tagsOptionsForInlineDropdown,
              ).map((tag) => ({
                type: 'cardmenuitem',
                value: tag.value,
                items: [
                  {
                    type: 'cardcontainer',
                    direction: 'vertical',
                    items: [
                      {
                        type: 'cardtext',
                        text: tag.group ?? '',
                        name: 'char_name',
                        classes: tag.group
                          ? ['group-header']
                          : ['group-header-hidden'],
                      },
                      {
                        type: 'cardtext',
                        text: tag.text,
                        classes: ['tag-item'],
                      },
                    ],
                  },
                ],
              }));
              resolve(results);
            }),
        });
      }
    }, [tagsOptionsForInlineDropdown]);

    useEffect(() => {
      if (showAddAttachments && attachments) {
        // scroll editor to bottom when attachment is added
        const editorRef = document.getElementById(
          'editor-editable-content-container',
        );
        editorRef.scrollTop = editorRef.scrollHeight;
      }
    }, [attachments]);

    useEffect(() => {
      if (showAddAttachments) {
        onAttachmentsMetaChange(attachmentsMeta);
      }
    }, [attachmentsMeta]);

    useEffect(() => {
      executeOnRequestStatus({
        status: uploadAttachmentRequestStatus,
        onSuccess: () => {
          const attachment = attachments.find(
            (item) =>
              item.attachmentIdentifier ===
              uploadedAttachment.attachmentIdentifier,
          );

          onAttachmentStatusChange({
            ...attachment,
            ...uploadedAttachment,
            status: AttachmentUploadStatus.UploadSuccess,
          });
          resetUploadAttachmentRequest(uniqueId);
        },
        onFailed: () => {
          if (uploadAttachmentRequestError) {
            toaster.error(uploadAttachmentRequestError.message, {
              theme: Theme.New,
            });
          }
        },
      });
    }, [uploadAttachmentRequestStatus]);

    useEffect(() => {
      executeOnRequestStatus({
        status: deleteAttachmentRequestStatus,
        onSuccess: () => {
          const updatedAttachments = attachments.filter(
            (item) => item.attachmentId !== deletedAttachmentId,
          );

          onAttachmentsChange(updatedAttachments, true);
          toaster.success(t('messages.attachment_removed_successfully'), {
            theme: Theme.New,
          });
          resetDeleteAttachmentRequest(uniqueId);
        },
        onFailed: () => {
          if (deleteAttachmentRequestError) {
            toaster.error(deleteAttachmentRequestError.message, {
              theme: Theme.New,
            });
          }
        },
      });
    }, [deleteAttachmentRequestStatus]);

    return (
      <div className={editorClass} ref={ref}>
        <div className="toolbar-container">
          <div className="d-flex align-items-center w-100">
            <div
              id={`toolbar-container-${id}`}
              className="fixed-toolbar-container"
            />
            <div className="toolbar-extra-btn">
              {showMergeAndVariableTags && (
                <TagsDropdown
                  mergeTags={mergeTags}
                  onAddMergeTag={addMergeTagInContent}
                  onAddVariableTag={addVariableTagInContent}
                  disabled={disabled}
                  disabledVariableTags={disabledVariableTags}
                  className={tagsDropdownClassName}
                >
                  <div>
                    <OverlayTooltip
                      text="Merge Tags / Variable Tags"
                      placement={Placement.Bottom}
                      className="sidenav__item-tooltip"
                    >
                      <div
                        className={`toolbar-btn ${
                          disabled ? 'disabled' : ''
                        }`.trim()}
                      >
                        <Braces2 height={16} width={16} />
                      </div>
                    </OverlayTooltip>
                  </div>
                </TagsDropdown>
              )}

              {showSpintaxDropdown && (
                <SpintaxDropdown
                  show={showSpintax}
                  handleOnSpintaxToggle={handleOnSpintaxToggle}
                  handleAddSpintax={addSpintaxInContent}
                  spintaxFor="body"
                  disabled={disabled}
                />
              )}
            </div>
          </div>

          {showAddAttachments && (
            <input
              ref={attachmentInput}
              type="file"
              hidden
              accept={getAcceptedExtensionsForAttachment()}
              onChange={handleFileInput}
            />
          )}

          {shouldRenderTemplate && (
            <button
              id={ProductTour.TemplateButton}
              type="button"
              className="template-button"
              onClick={showTemplateModal}
              disabled={disableRenderTemplateBtn}
            >
              <img src={Images.TemplateIcon} alt="Icon" />
              <p className="regular-1">Template</p>
            </button>
          )}
        </div>
        <div
          id="editor-editable-content-container"
          className="editor-editable-content-container"
          ref={dropRef}
          onDrop={handleDroppedFiles}
        >
          <DragAndDrop
            dropRef={dropRef}
            dragging={dragging}
            setDragging={setDragging}
            showAddAttachments={showAddAttachments}
          >
            <TinyMCEEditor
              key={`Editor-${disabled ? 'disabled' : 'enabled'}`}
              disabled={disabled}
              id={`editor-${id}`}
              value={value}
              onEditorChange={(v: string) => {
                onEditorChange(v);
              }}
              onKeyUp={onKeyUp}
              init={{
                forced_root_block: 'div',
                inline_styles: true,
                selector: `div#${id}`,
                menubar: false,
                file_picker_types: 'image',
                file_picker_callback: (cb) => {
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.onchange = (e: any) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      const fileId = file.name;
                      const { blobCache } = editorInstance.editorUpload;
                      const base64 = reader.result.toString().split(',')[1];
                      const blobInfo = blobCache.create(fileId, file, base64);
                      blobCache.add(blobInfo);
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };

                  input.click();
                },
                images_upload_handler: (
                  blobInfo,
                  success,
                  failure,
                  progress,
                ) => {
                  const formData = new FormData();
                  formData.append('file', blobInfo.blob(), blobInfo.filename());
                  imageUploader({
                    formData,
                    onUploadProgress: (e) => {
                      progress((e.loaded / e.total) * 100);
                    },
                  }).then((response: any) => {
                    if (response.payload.error) {
                      failure('Error occurred while uploading the image.', {
                        remove: true,
                      });
                    } else {
                      success(response.payload.payload.location);
                    }
                  });
                },
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help imagetools wordcount',
                ],
                toolbar:
                  'fontselect | fontsizeselect | ' +
                  'bold italic underline forecolor removeformat | alignment | ' +
                  `numlist bullist | link ${
                    showAddAttachments && 'attachment'
                  } image code | backcolor | unlink | outdent indent |`,
                fontsize_formats:
                  '10px 12px 13px 14px 16px 18px 24px 36px 48px 72px 78px',
                font_formats:
                  'Andale Mono=andale mono,times; ' +
                  'Arial=arial,helvetica,sans-serif; ' +
                  'Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; ' +
                  'Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Calibri=calibri; Georgia=georgia,palatino; ' +
                  'Helvetica=helvetica; Impact=impact,chicago; Sans Serif=sans-serif;  Symbol=symbol; ' +
                  'Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; ' +
                  'Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                toolbar_groups: {
                  alignmentgroup: {
                    icon: 'alignment-icon',
                    tooltip: 'Alignment',
                    items: 'alignleft aligncenter  alignright | alignjustify',
                  },
                },
                paste_enable_default_filters: false,
                skin_url: '/assets/libs/tinymce/skins/ui/sh',
                init_instance_callback: (editor) => {
                  editor.fire('focus');
                  onEditorInit?.();
                },
                setup: (editor) => {
                  editorInstance = editor;
                  editor.on('blur', () => {
                    onEditorBlur?.();
                    return false;
                  });
                  editor.on('init', () => {
                    getEditorInstance?.(editor);
                  });

                  addAlignmentSplitButton(editor);
                  addIcon(editor);

                  editor.ui.registry.addButton('attachment', {
                    icon: 'attachment',
                    tooltip: 'Insert Attachment',
                    onAction: handleAttachment,
                  });
                },
                inline: true,
                fixed_toolbar_container: `#toolbar-container-${id}`,
                toolbar_persist: true,
                toolbar_location: 'top',
                relative_urls: false,
                remove_script_host: false,
                convert_urls: false,
                browser_spellcheck: true,
                contextmenu: false,
                entity_encoding: 'raw',
              }}
            />

            <div id={id} className="editor-editable-content" />

            {showAddAttachments && attachments?.length > 0 && (
              <div
                id="attachments-list"
                className={`attachments-list ${attachmentsClassname}`}
              >
                {attachments.map((attachment) => (
                  <Attachment
                    key={attachment.attachmentIdentifier}
                    attachment={attachment}
                    attachmentMeta={attachmentsMeta.find(
                      (item) =>
                        item.attachmentIdentifier ===
                        attachment.attachmentIdentifier,
                    )}
                    handleAttachmentDelete={handleAttachmentDelete}
                    handleAttachmentReupload={handleAttachmentReupload}
                  />
                ))}
              </div>
            )}
          </DragAndDrop>
        </div>

        {error && (
          <Alert
            variant="warning"
            description={<span>{errorMessage}</span>}
            icon={AlertTriangle}
            className="email-content-error alert-width"
          />
        )}
      </div>
    );
  },
);

Editor.defaultProps = {
  error: false,
  disabled: false,
};

export default Editor;
