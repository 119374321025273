import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Headset as HeadsetIcon,
  GraduationHat,
  Chrome,
  Apps,
  Friends,
  Logout,
  Help,
  Gear,
} from '@saleshandy/icons';
import { isEmpty } from 'lodash';
import Avatar from '../initials-avatar';
import Icon from '../icon';
import { useOutClickHook } from '../../../../utils/hooks';
import { AuthHelper } from '../../../../utils';
import history from '../../../../history';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import { supportUrls } from '../../../../utils/urls';
import { AnalyticsEvents } from '../../../../enums/analytics';
import { AvatarEmail } from './helper/AvatarEmail';
import { openChatSupport } from '../../../../utils/open-chat-support';
import hasPermission from '../../../../utils/access-control/has-permission';
import { Permissions } from '../../../../utils/access-control/enums/permissions';
import CreditsBox from './helper/credits-box';
import { openUrl } from '../../../../utils/open-url';
import { isValueBasedPlan } from '../../../../../components/settings/components/billing-subscription/components/outreach-plans/utils/helper';
import { useCreditsSelectors } from './helper/credits-box-helper';
import CommonProfileDropdownItem from './helper/common-profile-dropdown-item';
import MultichannelBetaAccessSwitch from './helper/multichannel-beta-access-switch';

type UserProfile = {
  firstName: string;
  lastName: string;
  email: string;
};

type IProps = {
  userProfile: UserProfile;
  client: Record<string, any>;
  trackingId: string;

  isMultichannelActive: boolean;
  handleShowMultichannelBetaAccessConfirmationModal: VoidFunction;
};

const ProfileDropdown: React.FC<IProps> = ({
  userProfile,
  client,
  trackingId,

  isMultichannelActive,
  handleShowMultichannelBetaAccessConfirmationModal,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const { planType, planName } = useCreditsSelectors();

  const closeDropdown = () => setShowDropdown(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  useOutClickHook(dropdownRef, closeDropdown);

  const navigateToSettings = () => {
    closeDropdown();
    history.push('/settings/profile');
  };

  const navigateToFeatureRequest = () => {
    window.analytics?.track({
      userId: trackingId,
      event: AnalyticsEvents.FeatureRequestClicked,
      properties: {
        current_tab_url: document.location.href,
      },
    });
    closeDropdown();
    window.open(process.env.REACT_APP_SH_HELP, '_blank');
  };
  const navigateToBecomeOurAffiliate = () => {
    closeDropdown();
    window.open(supportUrls.becomeOurAffiliate, '_blank');
  };

  const redirectToScheduleDemo = () => {
    closeDropdown();
    window.open(process.env.REACT_APP_SCHEDULE_DEMO_URL, '_blank');
  };
  const redirectToChromeWebStore = () => {
    // Segment tracking
    window.analytics?.track({
      userId: trackingId,
      event: AnalyticsEvents.ExtensionIconClicked,
      properties: {
        current_tab_url: document.location.href,
        current_plan: planName,
      },
    });
    closeDropdown();
    window.open(process.env.REACT_APP_SALESHANDY_CONNECT_URL, '_blank');
  };

  const openChat = () => {
    closeDropdown();
    openChatSupport();
  };

  const onLogoutHandler = () => {
    closeDropdown();

    if (!isEmpty(client)) {
      AuthHelper.logout({
        redirect: true,
        pathName: '/agency/login',
      });
      return;
    }
    AuthHelper.logout();
  };

  const shouldShowDivider =
    hasPermission(Permissions.SCHEDULE_DEMO_SHOW) ||
    hasPermission(Permissions.MASTER_CLASS_SHOW) ||
    hasPermission(Permissions.CHROME_EXTENSION_READ);

  return (
    <>
      <div ref={dropdownRef} className="d-flex align-items-center pointer">
        <div
          className="d-flex align-items-center pointer"
          {...accessibleOnClick(toggleDropdown)}
        >
          <Avatar
            className="header-avatar"
            firstName={userProfile.firstName}
            lastName={userProfile.lastName}
          />
          <Icon
            identifier="chevron-down"
            className="ml-2 profile-dropdown-icon"
          />
        </div>
        {showDropdown && (
          <div className="profile-dropdown-view">
            <div className="avatar-name-email-dropdown">
              <AvatarEmail userProfile={userProfile} />
            </div>
            <div className="divider-dropdown-above-profile " />
            <div className="dropdown-without-avatar">
              <ul className="unordered-list-dropdown">
                <div className="dropdown-original-options">
                  <CommonProfileDropdownItem
                    icon={<Gear />}
                    label={t('labels.profile_settings')}
                    onClick={navigateToSettings}
                  />

                  {hasPermission(Permissions.FEATURE_REQUEST_SHOW) && (
                    <CommonProfileDropdownItem
                      icon={<Apps />}
                      label={t('labels.feature_request')}
                      onClick={navigateToFeatureRequest}
                    />
                  )}
                  {hasPermission(Permissions.BECOME_OUR_AFFILATE_SHOW) && (
                    <CommonProfileDropdownItem
                      icon={<Friends />}
                      label={t('labels.become_our_affiliate')}
                      onClick={navigateToBecomeOurAffiliate}
                    />
                  )}
                  {hasPermission(Permissions.HELP_SHOW) && (
                    <CommonProfileDropdownItem
                      icon={<Help />}
                      label={t('labels.help')}
                      onClick={openChat}
                    />
                  )}
                </div>

                {shouldShowDivider && <div className="divider-above-option" />}

                <div className="added-features-dropdown">
                  {hasPermission(Permissions.SCHEDULE_DEMO_SHOW) && (
                    <CommonProfileDropdownItem
                      icon={
                        <HeadsetIcon
                          height={16}
                          width={16}
                          className="headset"
                        />
                      }
                      label={t('labels.schedule_demo')}
                      onClick={redirectToScheduleDemo}
                    />
                  )}
                  {hasPermission(Permissions.MASTER_CLASS_SHOW) && (
                    <CommonProfileDropdownItem
                      icon={
                        <GraduationHat
                          className="graduation-hat"
                          height={16}
                          width={16}
                        />
                      }
                      label={t('labels.cold_email_masterclass')}
                      onClick={() => {
                        closeDropdown();
                        openUrl(supportUrls.coldEmailMasterClass);
                      }}
                    />
                  )}
                  {hasPermission(Permissions.CHROME_EXTENSION_READ) && (
                    <CommonProfileDropdownItem
                      icon={<Chrome height={16} width={16} />}
                      label={t('labels.download_chrome_plugin')}
                      onClick={redirectToChromeWebStore}
                    />
                  )}
                </div>

                <div className="divider-above-option" />

                <div className="class-for-logout">
                  <CommonProfileDropdownItem
                    icon={<Logout />}
                    label={t('labels.logout')}
                    onClick={onLogoutHandler}
                  />
                </div>

                {hasPermission(Permissions.BILLING_SHOW) &&
                  (isValueBasedPlan(planType) ||
                    hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) && (
                    <CreditsBox
                      dropdownIsOpen={showDropdown}
                      closeDropdown={closeDropdown}
                    />
                  )}

                {!isMultichannelActive &&
                  hasPermission(Permissions.ACCOUNT_USER_READ) && (
                    <MultichannelBetaAccessSwitch
                      handleShowMultichannelBetaAccessConfirmationModal={
                        handleShowMultichannelBetaAccessConfirmationModal
                      }
                    />
                  )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileDropdown;
