import { DateTime } from 'luxon';

export const convertSecondsToHourAndMinute = (
  seconds: number,
): [number, number] => {
  const hour = Math.floor(seconds / 60 / 60);
  const minute = Math.floor((seconds - hour * 60 * 60) / 60);
  return [hour, minute];
};

export const getRelative12HourFormatFromSeconds = (
  seconds: number,
): [string, string, string] => {
  const hourAndMinute = convertSecondsToHourAndMinute(seconds);

  let [hour] = hourAndMinute;
  const [, minute] = hourAndMinute;

  let amPm = 'AM';

  if (hour >= 12) {
    amPm = 'PM';
    if (hour >= 13) {
      hour -= 12;
    }
  }

  const hourStr = hour.toString().padStart(2, '0');
  const minuteStr = minute.toString().padStart(2, '0');

  return [hourStr, minuteStr, amPm];
};

export const convertHourAndMinuteToSeconds = (
  hour: number,
  minute: number,
): number => hour * 60 * 60 + minute * 60;

export const getDateIn12HourFormat = (
  date: string,
  timezone: string,
  showTime = true,
  isfullMonth = true,
  defaultFormatter: string = null,
) => {
  if (!date) {
    return '-';
  }

  let formatter = isfullMonth ? 'LLLL dd, y' : 'LLL dd, y';
  if (showTime) {
    formatter += ' hh:mm a';
  }
  return DateTime.fromISO(date, { zone: timezone }).toFormat(
    defaultFormatter || formatter,
  );
};

export const getOffset = (date: string, timezone: string) =>
  DateTime.fromISO(date, { zone: timezone }).toFormat('ZZ');

export const getDateIn12HourFormatNoTimeZone = (date) =>
  DateTime.fromISO(date).toFormat('LLLL dd, y hh:mm a');

export type RelativeDate = {
  isRelative: boolean;
  value: string;
};

export const getRelativeDate = (
  date: string,
  timezone: string,
  withTime = false,
): RelativeDate => {
  const targetDate = DateTime.fromISO(date).setZone(timezone);
  const time = DateTime.fromISO(date).setZone(timezone).toFormat('hh:mm a');
  const targetDateSeconds = targetDate.toSeconds();

  const currentDateMidnightSeconds = DateTime.now()
    .setZone(timezone)
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toSeconds();

  const diff = targetDateSeconds - currentDateMidnightSeconds;
  const secondsInDay = 24 * 60 * 60;

  let isRelative = false;
  let value = '';
  const dateFormate = 'EEE, LLL dd, y hh:mm a ';

  switch (true) {
    case -1 * secondsInDay <= diff && diff < 0:
      isRelative = true;
      value = withTime ? `Yesterday, ${time}` : 'Yesterday';
      break;
    case diff >= 0 && diff < secondsInDay:
      isRelative = true;
      value = withTime ? `Today, ${time}` : 'Today';
      break;
    case secondsInDay <= diff && diff < 2 * secondsInDay:
      isRelative = true;
      value = withTime ? `Tomorrow, ${time}` : 'Tomorrow';
      break;
    default:
      isRelative = false;
      value = getDateIn12HourFormat(
        date,
        timezone,
        undefined,
        false,
        dateFormate,
      );
      break;
  }

  return { isRelative, value };
};
