import { Images } from '../../app-constants';
import { AttachmentType } from '../../editor/types';
import {
  truncate,
  docExtensions,
  imageFileExtensions,
  videoFileExtensions,
  sheetFileExtensions,
  presentationFileExtensions,
  pdfExtensions,
  zipFileExtensions,
} from '../../utils';

const checkExtensionType = (fileExtension: string, extensions: string[]) =>
  extensions.some((ext) => ext === `.${fileExtension}`);

const getAttachmentIcon = (fileName: string): string => {
  if (fileName) {
    const splittedFileName = fileName?.split('.');
    const ext = splittedFileName[splittedFileName.length - 1];

    // Document Type Extensions
    if (checkExtensionType(ext, docExtensions)) {
      return Images.Docs;
    }

    // Image Type Extensions
    if (checkExtensionType(ext, imageFileExtensions)) {
      return Images.Image;
    }

    // Video Type Extensions
    if (checkExtensionType(ext, videoFileExtensions)) {
      return Images.Video;
    }

    // Sheet Type Extensions
    if (checkExtensionType(ext, sheetFileExtensions)) {
      return Images.Sheets;
    }

    // Presentation Type Extensions
    if (checkExtensionType(ext, presentationFileExtensions)) {
      return Images.Ppt;
    }

    // PDF Type Extensions
    if (checkExtensionType(ext, pdfExtensions)) {
      return Images.Pdf;
    }

    // Zip Type Extensions
    if (checkExtensionType(ext, zipFileExtensions)) {
      return Images.Zip;
    }
  }

  return Images.Default;
};

const getAttachmentName = (fileName: string) => {
  const splittedFileName = fileName?.split('.');
  const name = splittedFileName[0];
  const extension =
    splittedFileName?.length === 1
      ? ''
      : `.${splittedFileName?.[splittedFileName.length - 1]}`;

  if (name.length > 17) {
    const length = extension.length <= 3 ? 16 : 14;
    return {
      name: `${truncate(name, length, false)}${extension}`,
      showTooltip: true,
    };
  }

  return { name: `${name}${extension}`, showTooltip: false };
};

const getAttachmentSize = (size: number): string => {
  const sizeInMb = size / 1024 / 1000;

  if (sizeInMb >= 1) {
    return `${sizeInMb.toFixed(1)} MB`;
  }
  const sizeInKb = size / 1024;

  return `${sizeInKb.toFixed(1)} KB`;
};

export const getAttachmentMeta = (attachment: AttachmentType) => {
  const icon = getAttachmentIcon(attachment.file?.name);
  const size = getAttachmentSize(attachment.file?.size);
  const { name, showTooltip } = getAttachmentName(attachment.file?.name);

  return {
    icon,
    name,
    size,
    showTooltip,
  };
};

export const getFileName = (fileName: string): string => {
  const splittedFileName = fileName?.split('.');
  const name = splittedFileName[0];
  const extension =
    splittedFileName.length === 1
      ? ''
      : `.${splittedFileName?.[splittedFileName?.length - 1]}`;
  return `${name}${extension}`;
};
