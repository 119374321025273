/* eslint-disable @typescript-eslint/no-shadow */
import { DateTime, Duration } from 'luxon';
import { Granularity, ReportCountBy, ReportsBy } from '../../../enums/reports';

export const templateOptions = [
  {
    label: 'Prospect',
    key: ReportsBy.ByProspects,
  },
  {
    label: `Email`,
    key: ReportsBy.ByEmail,
  },
];
export const graphTypeOptions = [
  {
    label: 'Relative',
    key: ReportCountBy.Relative,
  },
  {
    label: `Absolute`,
    key: ReportCountBy.Absolute,
  },
];
export const timeFrameOptions = [
  {
    label: 'Daily',
    value: Granularity.Daily,
    applicable: (duration: Duration) =>
      duration.days >= 0 && duration.days < 180,
  },
  {
    label: 'Weekly',
    value: Granularity.Weekly,
    applicable: (duration: Duration) => duration.days > 7,
  },
  {
    label: 'Monthly',
    value: Granularity.Monthly,
    applicable: (duration: Duration) => duration.days > 30,
  },
  {
    label: 'Quarterly',
    value: Granularity.Quarterly,
    applicable: (duration: Duration) => duration.days > 90,
  },
];

export const transformGraphDataResponse = (data) => {
  if (!data) {
    return null;
  }

  const parseData = {
    dates: data?.map((item) => item.date),
    contacted: data?.map((item) => parseInt(item.prospectContacted, 10)),
    opened: data?.map((item) => parseInt(item.opened, 10)),
    replies: data?.map((item) => parseInt(item.replied, 10)),
    unsubscribed: data?.map((item) => parseInt(item.unSubscribed, 10)),
    positiveSentiment: data?.map((item) => parseInt(item.positive, 10)),
    negativeSentiment: data?.map((item) => parseInt(item.negative, 10)),
  };

  return parseData;
};

export const transformEmailGraphDataResponse = (data) => {
  if (!data) {
    return null;
  }

  const parseData = {
    dates: data?.map((item) => item.date),
    emailSent: data?.map((item) => parseInt(item.emailSent, 10)),
    opened: data?.map((item) => parseInt(item.opened, 10)),
    replies: data?.map((item) => parseInt(item.replied, 10)),
    bounced: data?.map((item) => parseInt(item.bounced, 10)),
  };

  return parseData;
};

export const aggregateData = (
  data,
  interval: Granularity,
  reportsBy: ReportsBy,
) => {
  const initialProspectData = {
    dates: [],
    contacted: [],
    opened: [],
    replies: [],
    positiveSentiment: [],
    negativeSentiment: [],
    unsubscribed: [],
  };
  const initialEmailData = {
    dates: [],
    emailSent: [],
    opened: [],
    replies: [],
    bounced: [],
  };

  const initialProspectPeriodData = {
    contacted: 0,
    opened: 0,
    replies: 0,
    positiveSentiment: 0,
    negativeSentiment: 0,
    unsubscribed: 0,
  };
  const initialEmailPeriodData = {
    emailSent: 0,
    opened: 0,
    replies: 0,
    bounced: 0,
  };

  if (!data) {
    return null;
  }

  if (interval === Granularity.Daily) {
    return data;
  }

  const aggregated: any =
    reportsBy === ReportsBy.ByProspects
      ? initialProspectData
      : initialEmailData;
  let currentPeriod = null;
  let periodData: any =
    reportsBy === ReportsBy.ByProspects
      ? initialProspectPeriodData
      : initialEmailPeriodData;

  data.dates.forEach((date, index) => {
    const currentDate = DateTime.fromJSDate(new Date(date));

    const transformationMap = {
      [Granularity.Weekly]: (currentDate) => currentDate.startOf('week'),
      [Granularity.Monthly]: (currentDate) => currentDate.startOf('month'),
      [Granularity.Quarterly]: (currentDate) => currentDate.startOf('quarter'),
    };

    let newPeriod;
    // eslint-disable-next-line prefer-const
    newPeriod = transformationMap[interval](currentDate).toString();

    // if (interval === Granularity.Weekly) {
    //   newPeriod = `${currentDate.year}-W${currentDate.weekNumber}`;
    // } else if (interval === Granularity.Monthly) {
    //   newPeriod = `${currentDate.year}-${currentDate.month
    //     .toString()
    //     .padStart(2, '0')}`;
    // } else if (interval === Granularity.Quarterly) {
    //   newPeriod = `${currentDate.year}-Q${currentDate.quarter}`;
    // }
    if (newPeriod !== currentPeriod) {
      if (currentPeriod) {
        aggregated.dates.push(currentPeriod);
        aggregated.opened.push(periodData.opened);
        aggregated.replies.push(periodData.replies);

        if (reportsBy === ReportsBy.ByProspects) {
          aggregated.contacted.push(periodData.contacted);
          aggregated.positiveSentiment.push(periodData.positiveSentiment);
          aggregated.negativeSentiment.push(periodData.negativeSentiment);
          aggregated.unsubscribed.push(periodData.unsubscribed);
        }

        if (reportsBy === ReportsBy.ByEmail) {
          aggregated.emailSent.push(periodData.emailSent);
          aggregated.bounced.push(periodData.bounced);
        }
      }
      currentPeriod = newPeriod;
      periodData =
        reportsBy === ReportsBy.ByProspects
          ? { ...initialProspectPeriodData }
          : { ...initialEmailPeriodData };
    }

    periodData.opened += data?.opened?.[index];
    periodData.replies += data?.replies?.[index];

    if (reportsBy === ReportsBy.ByProspects) {
      periodData.contacted += data?.contacted?.[index] || 0;
      periodData.positiveSentiment += data?.positiveSentiment?.[index] || 0;
      periodData.negativeSentiment += data?.negativeSentiment?.[index] || 0;
      periodData.unsubscribed += data?.unsubscribed?.[index] || 0;
    }

    if (reportsBy === ReportsBy.ByEmail) {
      periodData.emailSent += data?.emailSent?.[index] || 0;
      periodData.bounced += data?.bounced?.[index] || 0;
    }
  });
  // Add the last period
  if (currentPeriod) {
    aggregated.dates.push(currentPeriod);
    aggregated.opened.push(periodData.opened);
    aggregated.replies.push(periodData.replies);

    if (reportsBy === ReportsBy.ByProspects) {
      aggregated.contacted.push(periodData.contacted);
      aggregated.positiveSentiment.push(periodData.positiveSentiment);
      aggregated.negativeSentiment.push(periodData.negativeSentiment);
      aggregated.unsubscribed.push(periodData.unsubscribed);
    }

    if (reportsBy === ReportsBy.ByEmail) {
      aggregated.emailSent.push(periodData.emailSent);
      aggregated.bounced.push(periodData.bounced);
    }
  }

  return aggregated;
};

export const getGraphTooltips = (reportsBy: ReportsBy) =>
  reportsBy === ReportsBy.ByProspects
    ? [
        'Contacted',
        'Opened',
        'Replies',
        'Positive Sentiment',
        'Negative Sentiment',
        'Unsubscribed',
      ]
    : ['Email Sent', 'Opened', 'Replies', 'Bounced'];

const prospectGraphColors = [
  {
    name: 'Contacted',
    colorCode: '#60A5FA',
  },
  {
    name: 'Opened',
    colorCode: '#C67FFF',
  },
  {
    name: 'Replies',
    colorCode: '#9BFFB2',
  },
  {
    name: 'Positive Sentiment',
    colorCode: '#3FE28A',
  },
  {
    name: 'Negative Sentiment',
    colorCode: '#FF623F',
  },
  {
    name: 'Unsubscribed',
    colorCode: '#FFA800',
  },
];

const emailGraphColors = [
  {
    name: 'Email Sent',
    colorCode: '#60A5FA',
  },
  {
    name: 'Opened',
    colorCode: '#C67FFF',
  },
  {
    name: 'Replies',
    colorCode: '#9BFFB2',
  },
  {
    name: 'Bounced',
    colorCode: '#FFA800',
  },
];

export const selectedLegendsColor = (selectedLegends, arr) =>
  arr.filter((item) => selectedLegends[item.name]);

export const getShareGraphColorsArray = (
  reportsBy: ReportsBy,
  selectedLegends,
) =>
  reportsBy === ReportsBy.ByProspects
    ? selectedLegendsColor(selectedLegends, prospectGraphColors)?.map(
        ({ colorCode }) => colorCode,
      )
    : selectedLegendsColor(selectedLegends, emailGraphColors).map(
        ({ colorCode }) => colorCode,
      );

export const getGraphColorsArray = (reportsBy: ReportsBy) =>
  reportsBy === ReportsBy.ByProspects
    ? ['#60A5FA', '#C67FFF', '#9BFFB2', '#3FE28A', '#FF623F', '#FFA800']
    : ['#60A5FA', '#C67FFF', '#9BFFB2', '#FFA800'];

export const getGraphDataPercentageWise = (data, totalValue) => {
  totalValue = totalValue || [];
  return (
    data?.map((item, index) => (item / totalValue[index] || 0) * 100) || []
  );
};

export const getGraphSeries = (
  aggregateData,
  reportsBy: ReportsBy,
  showPercentage,
) =>
  reportsBy === ReportsBy.ByProspects
    ? [
        {
          name: 'Contacted',
          data: aggregateData.contacted,
          yAxisIndex: 0,
        },
        {
          name: 'Opened',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData.opened,
                aggregateData.contacted,
              )
            : aggregateData?.opened || [],
          yAxisIndex: 1,
        },
        {
          name: 'Replies',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData.replies,
                aggregateData.contacted,
              )
            : aggregateData?.replies || [],
          yAxisIndex: 1,
        },
        {
          name: 'Positive Sentiment',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData.positiveSentiment,
                aggregateData.contacted,
              )
            : aggregateData?.positiveSentiment || [],
          yAxisIndex: 1,
        },
        {
          name: 'Negative Sentiment',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData?.negativeSentiment,
                aggregateData.contacted,
              )
            : aggregateData?.negativeSentiment || [],
          yAxisIndex: 1,
        },
        {
          name: 'Unsubscribed',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData?.unsubscribed,
                aggregateData.contacted,
              )
            : aggregateData?.unsubscribed || [],
          yAxisIndex: 1,
        },
      ]
    : [
        {
          name: 'Email Sent',
          data: aggregateData.emailSent,
          yAxisIndex: 0,
        },
        {
          name: 'Opened',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData.opened,
                aggregateData.emailSent,
              )
            : aggregateData?.opened || [],
          yAxisIndex: 1,
        },
        {
          name: 'Replies',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData.replies,
                aggregateData.emailSent,
              )
            : aggregateData?.replies || [],
          yAxisIndex: 1,
        },
        {
          name: 'Bounced',
          data: showPercentage
            ? getGraphDataPercentageWise(
                aggregateData?.bounced,
                aggregateData.emailSent,
              )
            : aggregateData?.bounced || [],
          yAxisIndex: 1,
        },
        {
          name: '',
          data: [],
          yAxisIndex: 1,
        },
        {
          name: '',
          data: [],
          yAxisIndex: 1,
        },
      ];

export const getOriginalValue = (percentage, totalValue) =>
  ((percentage * totalValue) / 100)?.toFixed(2) || 0;
