import React from 'react';
import { Images } from '../../../../../../../shared/app-constants';
import { EmailAccountStatus } from '../../../../../types/email-account';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';

type IProps = {
  status: EmailAccountStatus;
};

const EmailAccountStatusIcon: React.FC<IProps> = ({ status }) => {
  let statusIconSrc = Images.CircleCheck;
  let tooltipText = '';

  if (status === EmailAccountStatus.Active) {
    tooltipText = 'Connected';
  }
  if (status === EmailAccountStatus.InActive) {
    statusIconSrc = Images.AlertTriangleRed;
    tooltipText = 'Disconnected';
  }
  if (status === EmailAccountStatus.InProgress) {
    statusIconSrc = Images.InProgress;
    tooltipText = 'Connection In Progress';
  }

  const statusIconJSX = (
    <img className="status" src={statusIconSrc} alt="Email Type" />
  );

  if (tooltipText) {
    return <OverlayTooltip text={tooltipText}>{statusIconJSX}</OverlayTooltip>;
  }

  return statusIconJSX;
};

export default EmailAccountStatusIcon;
