import React from 'react';
import {
  Briefcase,
  Building,
  BuildingArch,
  Mail,
  Search,
  Share,
  Tool,
} from '@saleshandy/icons';
import { BulkFiltersConfig } from '../type';
import { INDUSTRY_DATA } from '../../../helpers/filter-data';

// Use key value same as defined in useLeadFilter.tsx
export const BULK_SEARCH_FILTERS: BulkFiltersConfig = [
  {
    key: 'employer',
    excludeKey: 'excludeEmployer',
    icon: <Building height={16} width={16} />,
    labelText: 'Company Name / Domain',
    showExactMatchCheckbox: true,
    maxInputCount: 100,
    placeholder:
      'Enter list of Company Name/Domains separated by commas, semicolon or line breaks.',
  },
  {
    key: 'link',
    icon: <Share height={16} width={16} />,
    labelText: 'Social Link',
    maxInputCount: 100,
    placeholder:
      'Enter Social Link (e.g. LinkedIn profile URL) separated by commas, semicolon or line breaks.',
  },
  {
    key: 'industry',
    excludeKey: 'excludeIndustry',
    icon: <BuildingArch height={16} width={16} />,
    labelText: 'Industry',
    checkboxDataList: INDUSTRY_DATA,
    maxInputCount: 100,
    placeholder:
      'Enter Industry (e.g. IT & Software) separated by commas, semicolon or line breaks.',
  },
  {
    key: 'jobTitle',
    excludeKey: 'excludeJobTitle',
    icon: <Briefcase height={16} width={16} />,
    labelText: 'Job Title',
    showExactMatchCheckbox: true,
    maxInputCount: 100,
    placeholder:
      'Enter Job Title (e.g. CEO, Sales Head) separated by commas, semicolon or line breaks.',
  },
  {
    key: 'skills',
    excludeKey: 'excludeSkills',
    icon: <Tool height={16} width={16} />,
    labelText: 'Skills',
    showExactMatchCheckbox: true,
    maxInputCount: 100,
    placeholder:
      'Enter Skills (e.g. Lead Generation) separated by commas, semicolon or line breaks.',
  },
  {
    key: 'contactInfo',
    icon: <Mail height={16} width={16} />,
    labelText: 'Contact Info',
    maxInputCount: 100,
    placeholder:
      'Enter Contact Info (e.g. Email or Phone) separated by commas, semicolon or line breaks.',
  },
  {
    key: 'keyword',
    icon: <Search height={16} width={16} />,
    labelText: 'Keywords',
    showExactMatchCheckbox: true,
    maxInputCount: 100,
    placeholder:
      'Enter Keywords separated by commas, semicolon or line breaks.',
  },
];
