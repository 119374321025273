import React from 'react';
import { useTranslation } from 'react-i18next';
import { Images } from '../../../../../../../shared/app-constants';
import Button from '../../../../../../../shared/design-system/components/atoms/button';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import { checkIsLtdPlan } from '../../../../../../../shared/design-system/components/molecules/subscription-plan-details/helper/helper';
import { redirectWithoutRefresh } from '../../../../../../../shared/utils';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { contactAdmin } from '../../../../../../../shared/utils/contact-admin';
import { getUpgradePlanCTA } from '../../../../../../../shared/utils/get-upgrade-cta';
import { openChatSupport } from '../../../../../../../shared/utils/open-chat-support';
import store from '../../../../../../../store';
import type { IProps } from './types';
import { Routes } from '../../../../../../../shared/routes';
import { isWhiteLabel } from '../../../../../../../shared/utils/user-details';

const RestrictionErrorModal: React.FC<IProps> = ({
  show,
  modalTitle,
  cancelButtonText,
  submitButtonText,
  bodyContent,
  emailBody,
  onClose,
  onHide,
}) => {
  const { t } = useTranslation();

  const openHelpScoutBeacon = () => {
    openChatSupport();
  };

  const onSubmit = () => {
    const state = store.getState();
    const planType = state.home?.subscription?.planType;

    if (checkIsLtdPlan(planType)) {
      openHelpScoutBeacon();
      return;
    }

    const emailSubject = isWhiteLabel()
      ? 'Request to upgrade plan'
      : `${t('messages.upgrade_plan_error_email_subject')}`;

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      redirectWithoutRefresh(
        Routes.Setting_Billing_Upgrade_Email_Outreach_Plan,
      );
    } else {
      contactAdmin(emailSubject, emailBody);
    }
  };

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal no-seat-confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.UploadCloudBlue} alt="Alert" />
          <h2>{modalTitle || 'Upgrade to import more prospects'}</h2>
        </div>
      }
      showCloseIcon
      onSubmit={onSubmit}
      onClose={onHide || onClose}
      onCancel={onClose}
      submitButtonText={
        submitButtonText ?? t(`labels.${getUpgradePlanCTA(true)}`)
      }
      cancelButtonText={cancelButtonText || 'Cancel'}
      cancelButtonVarient={Button.Variant.Outlined}
      submitButtonClassName="header-btn"
      backdrop="static"
    >
      <div className="confirmation-modal-content">
        {bodyContent.map((text) => (
          <p className="confirmation-modal-text">{text}</p>
        ))}
      </div>
    </Modal>
  );
};

export default RestrictionErrorModal;
