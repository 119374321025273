import { RequestStatus } from '../../../shared/enums/request-status';

export const getIsRequestPending = (status): boolean =>
  status === RequestStatus.Pending;

export const truncateText = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }

  return text;
};
